import AesEncryptionServices from "./AesEncryptionServices";
class CookieService {
  // ls = window.localStorage
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie =
      cname +
      "=" +
      AesEncryptionServices.encrypt(cvalue) +
      ";" +
      expires +
      ";secure;SameSite=Lax;path=/";
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return AesEncryptionServices.decrypt(
          c.substring(name.length, c.length)
        );
      }
    }
    return "";
  }

  checkCookie(name) {
    var user = this.getCookie(name);
    if (user !== "") {
      return true;
    }
    return false;
  }
}

export default new CookieService();
