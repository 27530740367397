
const activeNavReducer = (state ={navMain:"Home", navSub:"", navContent:""} , action)=>{
     switch(action.type){
          case 'APPEND_ACTIVE_NAV':
               return action.activeNav;
          case 'UPDATE_ACTIVE_NAV':
               state.navMain=action.nav;
               return state;
          case 'UPDATE_ACTIVE_NAV_SUB':
               state.navSub=action.navSub;
               return state;
          case 'UPDATE_ACTIVE_NAV_CONTENT':
               state.navContent=action.navContent;
               return state;
          case 'REMOVE_NAV':
               return {}
          default:
               return state
     }
}
export default activeNavReducer;

