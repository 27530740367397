import React, { useState, useEffect } from "react";
import "./calender.css";
import { useSelector } from "react-redux";
import { colors1 } from "../../../../common/json";
// import CreateEvent from "../../../dashboard/learner/components/CreateEvent";

function Calender(props) {
  const [today, setToday] = useState(new Date());
  const dark = useSelector((state) => state.isDark);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentYear, setCurrentYear] = useState(new Date());
  const [months, setMonths] = useState(new Date());

  const lang = "en";
  useEffect(() => {
    const today1 = new Date();
    setToday(today1);
    setCurrentMonth(today1.getMonth());
    setCurrentYear(today1.getFullYear());
    if (
      !(props.name === "createEventMobile" || props.name === "createEventFront")
    ) {
      document.getElementById(props.name + "year").innerHTML =
        generate_year_range(1970, 2050);
      document.getElementById(props.name + "year").value = today1.getFullYear();
      document.getElementById(props.name + "month").value = today1.getMonth();
    }
    var monthDefault = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var dayDefault = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    if (lang === "tm") {
      monthDefault = [
        "தை",
        "மாசி",
        "பங்குனி",
        "சித்திரை",
        "வைகாசி",
        "ஆனி",
        "ஆடி",
        "ஆவணி",
        "புரட்டாசி",
        "ஐப்பசி",
        "கார்த்திகை",
        "மார்கழி",
      ];
      dayDefault = [
        "ஞாயிறு",
        "திங்கள்",
        "செவ்வாய்",
        "புதன்",
        "வியாழன்",
        "வெள்ளி",
        "சனி",
      ];
    } else if (lang === "id") {
      monthDefault = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      dayDefault = ["Ming", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"];
    } else if (lang === "fr") {
      monthDefault = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
      dayDefault = [
        "dimanche",
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi",
      ];
    }

    var $dataHead = "<tr>";
    for (var i in dayDefault) {
      $dataHead +=
        "<th class='weeks' data-days='" +
        dayDefault[i] +
        "'>" +
        dayDefault[i] +
        "</th>";
    }
    $dataHead += "</tr>";
    setMonths(monthDefault);
    document.getElementById(props.name + "thead-month").innerHTML = $dataHead;
    if (props.month) {
      showCalendar(props.month, props.year, monthDefault);
    } else if (props.year) {
      showCalendar(props.month, props.year, monthDefault);
    } else {
      showCalendar(today1.getMonth(), today1.getFullYear(), monthDefault);
    }

    // eslint-disable-next-line
  }, [props.eventType, props.month, props.year]);
  const generate_year_range = (start, end) => {
    var years = "";
    for (var year = start; year <= end; year++) {
      years += "<option value='" + year + "'>" + year + "</option>";
    }
    return years;
  };
  const next = () => {
    var currentMonthTest = (currentMonth + 1) % 12;
    var currentYearTest = currentMonth === 11 ? currentYear + 1 : currentYear;
    setCurrentYear(currentYearTest);
    setCurrentMonth(currentMonthTest);
    showCalendar(currentMonthTest, currentYearTest, months);
  };

  const previous = () => {
    var currentMonthTest = currentMonth === 0 ? 11 : currentMonth - 1;
    var currentYearTest = currentMonth === 0 ? currentYear - 1 : currentYear;
    setCurrentYear(currentYearTest);
    setCurrentMonth(currentMonthTest);
    showCalendar(currentMonthTest, currentYearTest, months);
  };

  const jump = () => {
    var currentMonthTest = parseInt(
      document.getElementById(props.name + "month").value
    );
    var currentYearTest = parseInt(
      document.getElementById(props.name + "year").value
    );
    setCurrentYear(currentYearTest);
    setCurrentMonth(currentMonthTest);
    showCalendar(currentMonthTest, currentYearTest, months);
  };

  function showCalendar(month, year, months) {
    if (props.event) {
      const thisYearEvents = props.event.filter(
        (message) =>
          parseInt(new Date(message.start).getFullYear()) === parseInt(year)
      );
      const thisMonthEvent = thisYearEvents.filter(
        (message) =>
          parseInt(new Date(message.start).getMonth()) === parseInt(month)
      );

      var firstDay = new Date(year, month).getDay();

      var tbl = document.getElementById(props.name + "calendar-body");

      tbl.innerHTML = "";

      document.getElementById(props.name + "monthAndYear").innerHTML =
        months[month] + " " + year;
      if (
        !(
          props.name === "createEventMobile" ||
          props.name === "createEventFront"
        )
      ) {
        document.getElementById(props.name + "year").value = year;
        document.getElementById(props.name + "month").value = month;
      }
      // creating all cells
      let date = 1;

      var cell = "";
      var cellText = "";
      for (var i = 0; i < 6; i++) {
        var row = document.createElement("tr");

        for (var j = 0; j < 7; j++) {
          if (i === 0 && j < firstDay) {
            cell = document.createElement("td");
            cellText = document.createTextNode("");
            cell.appendChild(cellText);
            row.appendChild(cell);
          } else if (date > daysInMonth(month, year)) {
            break;
          } else {
            let date1 = date;
            cell = document.createElement("td");
            cell.setAttribute("data-date", date1);
            cell.setAttribute("data-month", month + 1);
            cell.setAttribute("data-year", year);
            cell.setAttribute("data-month_name", months[month]);

            cell.className = "date-picker ";

            if (
              date1 === today.getDate() &&
              year === today.getFullYear() &&
              month === today.getMonth()
            ) {
              let x = false;
              let eventMessage = [];

              thisMonthEvent.forEach((element) => {
                if (
                  parseInt(new Date(element.start).getDate()) <=
                    parseInt(date1) &&
                  parseInt(date1) <= parseInt(new Date(element.end).getDate())
                ) {
                  if (props.eventType === "all") {
                    x = true;
                    eventMessage.push(element);
                  } else if (props.eventType === "zoom") {
                    if (element.type === "zoom") {
                      x = true;
                      eventMessage.push(element);
                    }
                  } else if (props.eventType === "exam") {
                    if (element.type === "exam") {
                      x = true;
                      eventMessage.push(element);
                    }
                  }else if (props.eventType === "submission") {
                    if (element.type === "submission") {
                      x = true;
                      eventMessage.push(element);
                    }
                  }
                }
              });
              if (x) {
                cell.className = "date-picker selectedEvent dropdown p-0";
                let message =
                  "<div id='dropdownMenuButton" +
                  date1 +
                  "' class='date-picker1 '  data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' ><span >" +
                  date1 +
                  "<p class='brake1'>&nbsp; </p>";

                eventMessage.forEach((element, index) => {
                  message +=
                    "</span><span class='removePaddings  pl-1 '><i class='fa fa-circle' style='color:" +
                    (eventMessage.length === 1
                      ? colors1[9].backgroundColor
                      : colors1[index % 10].backgroundColor) +
                    "' aria-hidden='true'></i></span>";
                });

                message +=
                  "</span></div>" +
                  "<div class='dropdown-menu p-0 m-0 dropdownMenuEvent' aria-labelledby='dropdownMenuButton" +
                  date1 +
                  "'>";

                eventMessage.forEach((element, index) => {
                  message +=
                    "<p class='calenderEventMessage eventList row p-0 m-0'><i class='fa fa-calendar  col-2 p-0 m-0 pt-1'  aria-hidden='true' style='color:" +
                    (eventMessage.length === 1
                      ? colors1[9].backgroundColor
                      : colors1[index % 10].backgroundColor) +
                    "'></i><label class='col-10 p-0 m-0 line-20'> &nbsp;" +
                    element.event +
                    " &nbsp;</label> <span class='badge badge-throo ml-4 '>" +
                    formatAMPM(new Date(element.start)) +
                    " - " +
                    formatAMPM(new Date(element.end)) +
                    "</span></p>";
                });

                message += "</div>";
                cell.innerHTML = message;
              } else {
                cell.className = "date-picker selected ";
                cell.innerHTML =
                  "<span>" + date1 + "<p class='brake'>&nbsp; </p></span>";
              }
            } else {
              let x2 = false;
              let eventMessage2 = [];
              thisMonthEvent.forEach((element) => {
                if (
                  parseInt(new Date(element.start).getDate()) <=
                    parseInt(date1) &&
                  parseInt(date1) <= parseInt(new Date(element.end).getDate())
                ) {
                  if (props.eventType === "all") {
                    x2 = true;
                    eventMessage2.push(element);
                  } else if (props.eventType === "zoom") {
                    if (element.type === "zoom") {
                      x2 = true;
                      eventMessage2.push(element);
                    }
                  }
                }
              });
              if (x2) {
                cell.className = "date-picker event dropdown  p-0 ";
                let message2 =
                  "<div id='dropdownMenuButton" +
                  date1 +
                  "' class='date-picker1 '  data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' ><span >" +
                  date1 +
                  "<p class='brake1'>&nbsp; </p>";

                eventMessage2.forEach((element, index) => {
                  message2 +=
                    "</span><span class='removePaddings " +
                    (index !== 0 && "pl-1") +
                    "'><i class='fa fa-circle' aria-hidden='true' style='color:" +
                    (eventMessage2.length === 1
                      ? colors1[9].backgroundColor
                      : colors1[index % 10].backgroundColor) +
                    "'></i></span>";
                });

                message2 +=
                  "</span></div>" +
                  "<div class='dropdown-menu p-0 m-0 dropdownMenuEvent' aria-labelledby='dropdownMenuButton" +
                  date1 +
                  "'>";

                eventMessage2.forEach((element, index) => {
                  message2 +=
                    "<p class='calenderEventMessage eventList row p-0 m-0'><i class='fa fa-calendar col-2 p-0 m-0 pt-1'  aria-hidden='true' style='color:" +
                    (eventMessage2.length === 1
                      ? colors1[9].backgroundColor
                      : colors1[index % 10].backgroundColor) +
                    "'></i><label class='col-10 p-0 m-0 line-20'> &nbsp;" +
                    element.event +
                    " &nbsp;</label> <span class='badge badge-throo ml-4 '>" +
                    formatAMPM(new Date(element.start)) +
                    " - " +
                    formatAMPM(new Date(element.end)) +
                    "</span></p>";
                });
                message2 += "</div>";
                cell.innerHTML = message2;
              } else {
                cell.className = "date-picker";
                cell.innerHTML =
                  "<span>" + date1 + "<p class='brake'>&nbsp; </p></span>";
              }
            }
            row.appendChild(cell);
            date++;
          }
        }

        tbl.appendChild(row);
      }
    }
  }

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  function daysInMonth(iMonth, iYear) {
    return 32 - new Date(iYear, iMonth, 32).getDate();
  }
  return (
    <div className="wrapper">
      <div
        className={
          !dark
            ? "container-calendar calenderLight"
            : "container-calendar calenderDark"
        }
      >
        <div className="row button-container-calendar">
          <div className="col">
            {!(
              props.name === "createEventMobile" ||
              props.name === "createEventFront"
            ) && (
              <button
                id={props.name + "previous"}
                onClick={previous}
                className="previous"
              >
                &#8249;
              </button>
            )}
          </div>
          <div className="col-7">
            <h3 id={props.name + "monthAndYear"} className="pt-1 monthAndYear">
              {" "}
            </h3>
          </div>
          <div className="col">
            {!(
              props.name === "createEventMobile" ||
              props.name === "createEventFront"
            ) && (
              <button id={props.name + "next"} onClick={next} className="next">
                &#8250;
              </button>
            )}
          </div>
        </div>
        <div className=""></div>

        <table
          className="table-calendar"
          id={props.name + "calendar"}
          data-lang="en"
        >
          <thead id={props.name + "thead-month"}></thead>
          <tbody id={props.name + "calendar-body"} className="dropleft"></tbody>
        </table>
        {!(
          props.name === "createEventMobile" ||
          props.name === "createEventFront"
        ) && (
          <div
            className={
              !dark
                ? "footer-container-calendar"
                : "footer-container-calendar-dark"
            }
          >
            <label htmlFor="month">Jump To &nbsp;: &nbsp;&nbsp; </label>
            <select id={props.name + "month"} onChange={jump}>
              <option value="0">Jan</option>
              <option value="1">Feb</option>
              <option value="2">Mar</option>
              <option value="3">Apr</option>
              <option value="4">May</option>
              <option value="5">Jun</option>
              <option value="6">Jul</option>
              <option value="7">Aug</option>
              <option value="8">Sep</option>
              <option value="9">Oct</option>
              <option value="10">Nov</option>
              <option value="11">Dec</option>
            </select>
            <select id={props.name + "year"} onChange={jump}></select>
          </div>
        )}
      </div>
    </div>
  );
}

export default Calender;
