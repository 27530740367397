import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./learnerDashboard.css";
import { withRouter } from "react-router-dom";
import image from "../../../images/classLogo.jpg";
import image1 from "../../../images/medal.png";
import image2 from "../../../images/folder.png";
import image3 from "../../../images/newDoc.jpg";
import image4 from "../../../images/pdf.png";
import image5 from "../../../images/img.png";
import imageGold from "../../../images/medalGold.png";
import imageSilver from "../../../images/medalSilver.png";
import imageBrows from "../../../images/medalBrows.png";
import Calender from "../../body/Components/Calender/Calender";
import Chat from "../../body/Components/Chat/Chat";
import NoticeBoard from "../../body/Components/NoticeBoard/NoticeBoard";

import { instructorList } from "../../../common/json";
import { cutText } from "../../../common/functions";
import CreateEvent from "./components/CreateEvent";

function LearnerDashboard(props) {
  const dark = useSelector((state) => state.isDark);
  const [navBottom, setNavBottom] = useState(true);
  const [nav, setNav] = useState("discussion");
  const newChat = ["hi"];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [instruct, setInstruct] = useState(instructorList[0]);
  const [chat, setChat] = useState([
    {
      message: "Really cool stuff!",
      published: new Date(Date.now() - 36000),
      sender: "Miro",
      receiver: "Martin",
    },
    {
      message: "Can you share a link for the tutorial?",
      published: new Date(Date.now() - 30000),
      sender: "Martin",
      receiver: "Miro",
    },
    {
      message: "Yeah, hold on",
      published: new Date(Date.now() - 30000),
      sender: "Miro",
      receiver: "Martin",
    },
    {
      message: "Yeah",
      published: new Date(Date.now() - 20000),
      sender: "Martin",
      receiver: "Miro",
    },
    {
      message: "www.throout.com/learning/345345",
      published: new Date(Date.now() - 10000),
      sender: "Miro",
      receiver: "Martin",
    },
    {
      message: "ok thanks",
      published: new Date(Date.now()),
      sender: "Martin",
      receiver: "Miro",
    },
  ]);
  const event = [
    {
      start: "Thu Dec 29 2021 11:00:00 GMT+0530 (India Standard Time)",
      end: "Fri Dec 31 2021 12:00:00 GMT+0530 (India Standard Time)",
      event: "Assignment Submission",
      type:"event"
    },
    {
      start: "Fri Dec 24 2021 11:00:00 GMT+0530 (India Standard Time)",
      end: "Fri Dec 24 2021 12:00:00 GMT+0530 (India Standard Time)",
      event: "Zoom Class",
      type:"zoom"
    },
    {
      start: "Sun Dec 26 2021 11:00:00 GMT+0530 (India Standard Time)",
      end: "Wed Dec 29 2021 12:00:00 GMT+0530 (India Standard Time)",
      event: "New class",
      type:"event"
    },
    {
      start: "Fri Dec 24 2021 09:00:00 GMT+0530 (India Standard Time)",
      end: "Fri Dec 24 2021 10:00:00 GMT+0530 (India Standard Time)",
      event: "New tutorial",
      type:"exam"
    },
    {
      start: "Fri Dec 24 2021 10:00:00 GMT+0530 (India Standard Time)",
      end: "Fri Dec 24 2021 11:00:00 GMT+0530 (India Standard Time)",
      event: "Zoom Class",
      type:"event"
    },
  ];
  const pushMessage = (message, sender, receiver) => {
    const newMessage = {
      message: message,
      published: new Date(Date.now()),
      sender: sender,
      receiver: receiver,
    };
    setChat((chat) => [...chat, newMessage]);
  };
  function changeSubject(id) {
    const filter = instructorList.filter((res) => res.id === id);
    setInstruct(filter[0]);
  }
  function bodyContentStyle() {
    let classes = "";
    classes += !dark ? "  learnerDashStyle " : "  learnerDashStyleDark ";
    return classes;
  }
  const navBottomChangeDiscussion = async () => {
    if (navBottom) {
      var element = document.getElementById("navBottom2");
      element.classList.remove("navBottom2Down");
      element.classList.add("navBottom2Up");
    } else {
      var element1 = document.getElementById("navBottom2");
      element1.classList.remove("navBottom2Up");
      element1.classList.add("navBottom2Down");
    }
    setNavBottom(!navBottom);
  };
  const navBottomChange = async () => {
    await setNav("discussion");

    if (nav === "discussion") {
      if (navBottom) {
        var element = document.getElementById("navBottom2");
        element.classList.remove("navBottom2Down");
        element.classList.add("navBottom2Up");
      } else {
        var element1 = document.getElementById("navBottom2");
        element1.classList.remove("navBottom2Up");
        element1.classList.add("navBottom2Down");
      }
      setNavBottom(!navBottom);
    }
  };
  const navBottomChangeCal = async () => {
    await setNav("calender");

    if (nav === "calender") {
      if (navBottom) {
        var element = document.getElementById("navBottom2");
        element.classList.remove("navBottom2Down");
        element.classList.add("navBottom2Up");
      } else {
        var element1 = document.getElementById("navBottom2");
        element1.classList.remove("navBottom2Up");
        element1.classList.add("navBottom2Down");
      }
      setNavBottom(!navBottom);
    }
  };

  return (
    <div className="row p-0 m-0">
      <div className={bodyContentStyle()}>
        <div className="row m-0 d-flex justify-content-center">
          <div className="rightDashContainer ">
            <NoticeBoard />
          </div>
          <div className="leftDashContainer">
            <div
              className={!dark ? "classBox  row m-0" : "classBoxDark  row m-0"}
              id="container-calender0"
            >
              <div className="col-2 p-0">
                <img src={image} alt="" className="classBoxImage" />
              </div>
              <div className="col-10 p-0 text-left pl-3">
                <p
                  className={
                    !dark ? "classSquareHead1" : "classSquareHead1Dark"
                  }
                >
                  New Science Hall
                </p>
                <div className="dropdown">
                  <span
                    className="badge InstructorName text-left mt-1 p-1  pr-2 pl-2 dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {instruct.subject}{" "}
                    <span className="badge badge-light mr-1 ml-1">
                      {cutText(instruct.name, 15)}
                    </span>
                  </span>
                  <div
                    className="dropdown-menu pt-0 pb-0 "
                    aria-labelledby="dropdownMenuButton"
                  >
                    {instructorList
                      .filter((res) => res.id !== instruct.id)
                      .map((option) => (
                        <span
                          className="dropdown-item InstructorName1 pointer"
                          onClick={() => changeSubject(option.id)}
                        >
                          {option.subject}{" "}
                          <span className="badge InstructorName authMenu mt-1 ml-1 pull-right ml-3">
                            {cutText(option.name, 15)}
                          </span>
                        </span>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 ">
                <div id="container-calender">
                  <Calender name="front" event={event} eventType="all"/>
                </div>
              </div>
            </div>

            <CreateEvent  name="createEventFront" event={event} />
            <div
              className={
                !dark
                  ? "classBox  row m-0 mt-4 pointer"
                  : "classBoxDark  row m-0 mt-4 pointer"
              }
              data-toggle="modal"
              data-target="#pointModal"
            >
              <div className="col-12">
                <p
                  className={
                    !dark ? "classSquareHead1 " : "classSquareHead1Dark "
                  }
                >
                  Leader Board
                </p>
                <hr style={{ height: "0" }}></hr>
              </div>

              <div className="col-3 p-0">
                <img src={image1} alt="" className="pointImage" />
              </div>
              <div className="col-8  text-left pr-0 pt-0 mt-0">
                <p
                  className={
                    !dark
                      ? "classSquareHead1 pb-2"
                      : "classSquareHead1Dark pb-2"
                  }
                >
                  My Points
                </p>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "25%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    254
                  </div>
                </div>
              </div>
              <div className="col-3 pl-3 ">
                <img src={imageGold} alt="" className="pointImage" />
              </div>
              <div className="col-6  text-left mt-3 ">
                <span>Bavaram</span>
                <br></br>
                <span>1259</span>
              </div>
              <div className="col-3 pl-3 ">
                <img
                  src={
                    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/1_copy.jpg"
                  }
                  alt=""
                  className="angleImage"
                />
              </div>
              <div className="col-3 pl-3 ">
                <img src={imageSilver} alt="" className="pointImage" />
              </div>
              <div className="col-6  text-left mt-3 ">
                <span>Thines</span>
                <br></br>
                <span>1259</span>
              </div>
              <div className="col-3 pl-3 ">
                <img
                  src={
                    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/2_copy.jpg"
                  }
                  alt=""
                  className="angleImage"
                />
              </div>
              <div className="col-3 pl-3 ">
                <img src={imageBrows} alt="" className="pointImage" />
              </div>
              <div className="col-6  text-left mt-3 ">
                <span>David</span>
                <br></br>
                <span>1259</span>
              </div>
              <div className="col-3 pl-3 ">
                <img
                  src={
                    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/4_copy.jpg"
                  }
                  alt=""
                  className="angleImage"
                />
              </div>
            </div>
            <div
              className={
                !dark
                  ? "classBox  row m-0 mt-4 pointer"
                  : "classBoxDark  row m-0 mt-4 pointer"
              }
              data-toggle="modal"
              data-target="#storageModal"
            >
              <div className="col-12">
                <p
                  className={
                    !dark ? "classSquareHead1 " : "classSquareHead1Dark "
                  }
                >
                  Cupboard
                </p>
                <hr style={{ height: "0" }}></hr>
              </div>
              <div className="col-3 pl-3 pt-0 mt-0">
                <img src={image2} alt="" className="pointImage" />
              </div>
              <div className="col-8  text-left mt-2 ">
                <div className="row">
                  <div className="col-6">
                    <p
                      className={
                        !dark
                          ? "classSquareHead1 pb-0"
                          : "classSquareHead1Dark pb-0"
                      }
                    >
                      Files
                    </p>
                  </div>
                  <div className="col-2">
                    <p
                      className={
                        !dark
                          ? "classSquareHead1 pb-0"
                          : "classSquareHead1Dark pb-0"
                      }
                    >
                      3
                    </p>
                  </div>
                  <div className="col-4 pt-0 mt-0">
                    <img src={image3} alt="" className="justImage" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    {" "}
                    <p
                      className={
                        !dark
                          ? "classSquareHead1 pb-0"
                          : "classSquareHead1Dark pb-0"
                      }
                    >
                      Folder
                    </p>
                  </div>
                  <div className="col-2">
                    <p
                      className={
                        !dark
                          ? "classSquareHead1 pb-0"
                          : "classSquareHead1Dark pb-0"
                      }
                    >
                      2
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <Chat /> */}
          </div>
          <nav className="mobile-bottom-nav">
            <div className="navBottom d-flex ">
              <div className="navBottom1">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/1_copy.jpg"
                  alt="profileImage"
                />
                {newChat.length > 0 && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "60px",
                      position: "absolute",
                      marginTop: "-10px",
                      marginLeft: "-12px",
                    }}
                  >
                    &middot;
                  </span>
                )}
                <p className="navBottomText pointer" onClick={navBottomChange}>
                  {" "}
                  Discussion
                </p>
                <i
                  className={
                    navBottom
                      ? "fa fa-angle-up navBottomIcon icon-rotate"
                      : "fa fa-angle-up navBottomIcon icon-rotate rotate"
                  }
                  onClick={navBottomChangeDiscussion}
                  aria-hidden="true"
                ></i>
                <i
                  className="fa fa-calendar navBottomIcon navCalender"
                  onClick={navBottomChangeCal}
                  aria-hidden="true"
                ></i>
              </div>
              <div id="navBottom2" className="navBottom2Down">
                <div className={dark ? "navBottom3Dark" : "navBottom3"}>
                  {nav === "discussion" && (
                    <Chat
                      chat={chat}
                      newChat={newChat}
                      name="nav"
                      pushMessage={pushMessage}
                    />
                  )}
                  {nav === "calender" && <Calender name="mobile" event={event} eventType="all"/>}
                  <div className="pr-3">
                  <button
        className="buttonPri pull-right mb-3 mt-3"
        data-toggle="modal"
        data-target={"#createEventMobile"}
      >
        Add Event
      </button>
                  
                    </div>
                  
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div
          className="modal fade"
          id="pointModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg mt-0 pt-0 "
            role="document"
          >
            <div
              className={
                !dark
                  ? "modal-content classModal p-0 m-0"
                  : " modal-content classModalDark p-0 m-0"
              }
            >
              <div className="row p-0 m-0">
                <div
                  className="col p-0 m-0"
                  style={{ height: "400px", overflowX: "scroll" }}
                >
                   <i class="fa fa-window-close  absolute pointer text-light" style={{ right:"15px",top:"15px" }} data-dismiss="modal" aria-label="Close" aria-hidden="true"></i>
                  <table className="table  p-0 m-0">
                    <thead className="bg-throout">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Percentage</th>
                        <th scope="col">Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Bavaram</td>
                        <td>75%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Rahuraj</td>
                        <td>80%</td>
                        <td>235</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Shinagan</td>
                        <td>70%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">1</th>
                        <td>Bavaram</td>
                        <td>75%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Rahuraj</td>
                        <td>80%</td>
                        <td>235</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Shinagan</td>
                        <td>70%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">1</th>
                        <td>Bavaram</td>
                        <td>75%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Rahuraj</td>
                        <td>80%</td>
                        <td>235</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Shinagan</td>
                        <td>70%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">1</th>
                        <td>Bavaram</td>
                        <td>75%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Rahuraj</td>
                        <td>80%</td>
                        <td>235</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Shinagan</td>
                        <td>70%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">1</th>
                        <td>Bavaram</td>
                        <td>75%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Rahuraj</td>
                        <td>80%</td>
                        <td>235</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Shinagan</td>
                        <td>70%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">1</th>
                        <td>Bavaram</td>
                        <td>75%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Rahuraj</td>
                        <td>80%</td>
                        <td>235</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Shinagan</td>
                        <td>70%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">1</th>
                        <td>Bavaram</td>
                        <td>75%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Rahuraj</td>
                        <td>80%</td>
                        <td>235</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Shinagan</td>
                        <td>70%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">1</th>
                        <td>Bavaram</td>
                        <td>75%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Rahuraj</td>
                        <td>80%</td>
                        <td>235</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Shinagan</td>
                        <td>70%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">1</th>
                        <td>Bavaram</td>
                        <td>75%</td>
                        <td>245</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Rahuraj</td>
                        <td>80%</td>
                        <td>235</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateEvent name="createEventMobile" event={event} />
        <div
          className="modal fade "
          id="storageModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg "
            role="document"
          >
            <div
              className={
                !dark
                  ? "modal-content classModal"
                  : " modal-content classModalDark"
              }
              style={{ borderRadius: "15px" }}
            >
              <div className="row  p-0 m-0 " style={{ borderRadius: "15px" }}>
                <div
                  className="col bg-throout p-0 m-0 pt-2 pb-2"
                  style={{
                    borderTopLeftRadius: "13px",
                    borderTopRightRadius: "13px",
                  }}
                >
                  <h2 className="">Cupboard</h2>
                  <i class="fa fa-window-close  absolute pointer" style={{ right:"15px",top:"15px" }} data-dismiss="modal" aria-label="Close" aria-hidden="true"></i>
                </div>
              </div>
              <p className="span7 pl-3 textPri">
                <i className="fa fa-home pr-2" aria-hidden="true"></i>home/
              </p>
              <div className="row  p-0 m-0 ">
                <div className="col-2 text-center pb-4">
                  <img src={image2} alt="" className="folderImage" />
                  <br></br>
                  <span className="span7"> Art </span>
                </div>
                <div className="col-2 text-center pb-4">
                  <img src={image2} alt="" className="folderImage" />
                  <br></br>
                  <span className="span7"> Art </span>
                </div>
                <div className="col-2 text-center pb-4">
                  <img src={image2} alt="" className="folderImage" />
                  <br></br>
                  <span className="span7"> Art </span>
                </div>
                <div className="col-2 text-center pb-4">
                  <img src={image2} alt="" className="folderImage" />
                  <br></br>
                  <span className="span7"> Art </span>
                </div>
                <div className="col-2 text-center pb-4">
                  <img src={image2} alt="" className="folderImage" />
                  <br></br>
                  <span className="span7"> Art </span>
                </div>
                <div className="col-2 text-center pb-4">
                  <img src={image2} alt="" className="folderImage" />
                  <br></br>
                  <span className="span7"> Art </span>
                </div>
                <div className="col-2 text-center pb-4">
                  <img src={image2} alt="" className="folderImage" />
                  <br></br>
                  <span className="span7"> Art </span>
                </div>
                <div className="col-2 text-center pb-4">
                  <img src={image4} alt="" className="folderImage" />
                  <br></br>
                  <span className="span7"> Art </span>
                </div>
                <div className="col-2 text-center pb-4">
                  <img src={image5} alt="" className="folderImage" />
                  <br></br>
                  <span className="span7"> Art </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(LearnerDashboard);
