import React from "react";
import "../quiz.css";
import { withRouter } from "react-router-dom";

function FillBlankQuestion(props) {

  async function onChange(e) {
    const {
      target: { name, value },
    } = e;
    props.updateFillBlanks(props.data.answerNo, name, value);
  }
  return (
    <div className="row" key={"q" + props.index + "a" + props.key}>
      <div className=" p-3 " style={{ width: "10px" }}>
        {props.fillBlanksLength > 1 && (
          <i
            className="fa fa-times pointer"
            aria-hidden="true"
            onClick={() => props.removeFillBlanks(props.data.answerNo)}
          ></i>
        )}
      </div>
      <div className="col">
        {props.data.type === "textArea" && (
          <textarea
            className="answer"
            rows="5"
            name="question"
            onChange={onChange}
            value={props.data.text}
            placeholder="write here . . "
          ></textarea>
        )}

        {props.data.type === "text" && (
          <div className="input-group pt-2 border-0">
            <input
              className="border-0 rounded pl-3 textInput1"
              onChange={onChange}
              name="answer"
              value={props.data.text}
            ></input>
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon2">
                Blank
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(FillBlankQuestion);
