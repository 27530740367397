import React, { Component } from "react";
import "../quiz.css";
// import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { randomArrayShuffle } from "../../../../common/functions";

class ReviewMatching extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ans: [],
    };
  }
  // const dark = useSelector((state) => state.isDark);
  // const [fillBlankFinal, setFillBlankFinal] = useState();
  getMatchText(answers) {
    let x = [];
    for (const obj of answers) {
      x.push({
        id: obj.answerNo,
        value: obj.text[1],
      });
    }
    return randomArrayShuffle(x);
  }
  componentDidMount() {
    this.setState({ ans: this.props.handle === "on"?this.props.finalAnswer:this.getMatchText(this.props.answers) });
  }
  fun() {
    // this.props.setFillBlankFinal(this.props.questionNo,"finalAnswer",this.props.answers);
  }
  render() {
    return (
      <div className="pt-3 p-2">
        <span
          className="absolute"
          style={{
            marginLeft: "-10px",
            marginTop: "8px",
            fontSize: "18px",
            fontWeight: "600",
            fontFamily: "sans-serif",
          }}
        >
          {this.props.index + 1}){" Arrange the words "}
        </span>
        <br></br>

        <div className="row">
          <div className="col " style={{ paddingTop: "1.5rem" }}>
            {this.props.value.answers.map((val, index) => (
              <div className="row reviewMatch" style={{ paddingTop: "0.1rem" }}>
                <div className=" ml-2 ">
                  <div className="input-group pt-2 border-0">
                    <input
                      className="border-0 rounded pl-3 reviewInput matchingInput"
                      name="text1"
                      value={val.text[0]}
                      style={{ pointerEvents: "none" }}
                    ></input>
                    <div className="input-group-append reviewInputNumber matchingConnecter">
                      <span className="input-group-text" id="basic-addon2">
                        {parseInt(index) + 1}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.props.dark
                      ? "col mt-0  align-self-center darkHR matchingConnecterHR"
                      : "col mt-0  align-self-center matchingConnecterHR"
                  }
                >
                  <hr></hr>
                </div>
              </div>
            ))}
          </div>

          <div className="col matchingWeb">
            <div>
              <DragDropContext
                onDragEnd={(param) => {
                  const srcI = param.source.index;
                  const desI = param.destination?.index;
                  if (this.props.handle !== "on") {
                  if (desI || desI === 0) {
                    this.state.ans.splice(
                      desI,
                      0,
                      this.state.ans.splice(srcI, 1)[0]
                    );
                    this.props.setFillBlankFinal(
                      this.props.questionNo,
                      "finalAnswer",
                      this.state.ans
                    );
                  }
                }
                }}
              >
                <div>
                  <Droppable
                    droppableId={"droppable-" + this.props.value.questionNo}
                  >
                    {(provided, snapshot) => (
                      <div
                        style={{
                          margin: "2rem auto",
                          marginLeft: "0rem",
                          // maxWidth: "30rem",
                          paddingRight: "2rem",
                          paddingLeft: "0rem",
                          paddingBottom: "2rem",
                          borderRadius: "0.2rem",
                          // boxShadow: "0.1rem 0.1rem 0.4rem #aaaaaa",
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {this.state.ans.map((item, i) => (
                          <Draggable
                            key={item.id}
                            draggableId={"draggable-" + item.id}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  color: "#444444",
                                  padding: "0.7557rem 0.3rem",
                                  borderBottom: "1px solid #dddddd",
                                  background: "white",
                                  boxShadow: snapshot.isDragging
                                    ? "0 0 .4rem #666"
                                    : "",
                                  cursor: "pointer",
                                  borderTopRightRadius: "15px",
                                  borderBottomRightRadius: "15px",
                                }}
                              >
                                <i
                                  className={
                                    this.props.dark
                                      ? "fa fa-caret-left text-light"
                                      : "fa fa-caret-left text-dark"
                                  }
                                  style={{
                                    marginLeft: "-13px",
                                    fontSize: "20px",
                                  }}
                                  aria-hidden="true"
                                ></i>
                                <span
                                  style={{
                                    paddingLeft: "1rem",
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {item.value}
                                </span>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-1 matchingMobile" style={{ marginTop: "17px" }}>
            {this.props.answers.map((item, i) => (
              <span
                className="badge badge-secondary"
                style={{ marginTop: "30px" }}
              >
                {i + 1}
              </span>
            ))}
          </div>

          <div className="col matchingMobile">
            <div>
              <DragDropContext
                onDragEnd={(param) => {
                  const srcI = param.source.index;
                  const desI = param.destination?.index;
                  if (this.props.handle !== "on") {
                    if (desI || desI === 0) {
                      this.props.answers.splice(
                        desI,
                        0,
                        this.props.answers.splice(srcI, 1)[0]
                      );
                      this.props.setFillBlankFinal(
                        this.props.questionNo,
                        "finalAnswer",
                        this.state.ans
                      );
                    }
                  }
                }}
              >
                <div>
                  <Droppable
                    droppableId={"droppable-" + this.props.value.questionNo}
                  >
                    {(provided, snapshot) => (
                      <div
                        style={{
                          margin: "2rem auto",
                          marginLeft: "0rem",
                          // maxWidth: "30rem",
                          paddingRight: "2rem",
                          paddingLeft: "0rem",
                          paddingBottom: "2rem",
                          borderRadius: "0.2rem",
                          // boxShadow: "0.1rem 0.1rem 0.4rem #aaaaaa",
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {this.props.handle === "on"
                          ? this.props.finalAnswer.map((item, i) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                // style={{
                                //   ...provided.draggableProps.style,
                                //   color: "#444444",
                                //   padding: "0.7557rem 0.3rem",
                                //   borderBottom: "1px solid #dddddd",
                                //   background: "white",
                                //   boxShadow: snapshot.isDragging
                                //     ? "0 0 .4rem #666"
                                //     : "",
                                //   cursor: "pointer",
                                //   borderTopRightRadius: "15px",
                                //   borderBottomRightRadius: "15px",
                                // }}
                              >
                                <i
                                  className={
                                    this.props.dark
                                      ? "fa fa-caret-left text-light"
                                      : "fa fa-caret-left text-dark"
                                  }
                                  style={{
                                    marginLeft: "-13px",
                                    fontSize: "20px",
                                  }}
                                  aria-hidden="true"
                                ></i>
                                <span
                                  style={{
                                    paddingLeft: "1rem",
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {item.value}
                                </span>
                              </div>
                            ))
                          : this.props.answers.map((item, i) => (
                              <Draggable
                                key={item.id}
                                draggableId={"draggable-" + item.id}
                                index={i}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      color: "#444444",
                                      padding: "0.7557rem 0.3rem",
                                      borderBottom: "1px solid #dddddd",
                                      background: "white",
                                      boxShadow: snapshot.isDragging
                                        ? "0 0 .4rem #666"
                                        : "",
                                      cursor: "pointer",
                                      borderTopRightRadius: "15px",
                                      borderBottomRightRadius: "15px",
                                    }}
                                  >
                                    <i
                                      className={
                                        this.props.dark
                                          ? "fa fa-caret-left text-light"
                                          : "fa fa-caret-left text-dark"
                                      }
                                      style={{
                                        marginLeft: "-13px",
                                        fontSize: "20px",
                                      }}
                                      aria-hidden="true"
                                    ></i>
                                    <span
                                      style={{
                                        paddingLeft: "1rem",
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      {item.value}
                                    </span>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>
          </div>
        </div>


        {this.props.correct==="correct"&&
        <>
        <div className="row">
          <div className="col " style={{ paddingTop: "1.5rem" }}>
            {this.props.value.answers.map((val, index) => (
              <div className="row reviewMatch" style={{ paddingTop: "0.1rem" }}>
                <div className=" ml-2 ">
                  <div className="input-group pt-2 border-0">
                    <input
                      className="border-0 rounded pl-3 reviewInput matchingInput"
                      name="text1"
                      value={val.text[0]}
                      style={{ pointerEvents: "none" }}
                    ></input>
                    <div className="input-group-append reviewInputNumber matchingConnecter">
                      <span className="input-group-text" id="basic-addon2">
                        {parseInt(index) + 1}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.props.dark
                      ? "col mt-0  align-self-center darkHR matchingConnecterHR"
                      : "col mt-0  align-self-center matchingConnecterHR"
                  }
                >
                  <hr></hr>
                </div>
              </div>
            ))}
          </div>

          <div className="col matchingWeb">
            <div>
              <DragDropContext
                onDragEnd={(param) => {
                  
                
                }}
              >
                <div>
                  <Droppable
                    droppableId={"droppable-" + this.props.value.questionNo}
                  >
                    {(provided, snapshot) => (
                      <div
                        style={{
                          margin: "2rem auto",
                          marginLeft: "0rem",
                          // maxWidth: "30rem",
                          paddingRight: "2rem",
                          paddingLeft: "0rem",
                          paddingBottom: "2rem",
                          borderRadius: "0.2rem",
                          // boxShadow: "0.1rem 0.1rem 0.4rem #aaaaaa",
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {this.props.realAnswer.map((item, i) => (
                          <Draggable
                            key={item.id}
                            draggableId={"draggable-" + item.id}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  color: "#444444",
                                  padding: "0.7557rem 0.3rem",
                                  borderBottom: "1px solid #dddddd",
                                  background: "white",
                                  boxShadow: snapshot.isDragging
                                    ? "0 0 .4rem #666"
                                    : "",
                                  cursor: "pointer",
                                  borderTopRightRadius: "15px",
                                  borderBottomRightRadius: "15px",
                                }}
                              >
                                <i
                                  className={
                                    this.props.dark
                                      ? "fa fa-caret-left text-light"
                                      : "fa fa-caret-left text-dark"
                                  }
                                  style={{
                                    marginLeft: "-13px",
                                    fontSize: "20px",
                                  }}
                                  aria-hidden="true"
                                ></i>
                                <span
                                  style={{
                                    paddingLeft: "1rem",
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {item.text[1]}
                                </span>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-1 matchingMobile" style={{ marginTop: "17px" }}>
            {this.props.realAnswer.map((item, i) => (
              <span
                className="badge badge-secondary"
                style={{ marginTop: "30px" }}
              >
                {i + 1}
              </span>
            ))}
          </div>

          <div className="col matchingMobile">
            <div>
              <DragDropContext
                onDragEnd={(param) => {
                 
                }}
              >
                <div>
                  <Droppable
                    droppableId={"droppable-" + this.props.value.questionNo}
                  >
                    {(provided, snapshot) => (
                      <div
                        style={{
                          margin: "2rem auto",
                          marginLeft: "0rem",
                          // maxWidth: "30rem",
                          paddingRight: "2rem",
                          paddingLeft: "0rem",
                          paddingBottom: "2rem",
                          borderRadius: "0.2rem",
                          // boxShadow: "0.1rem 0.1rem 0.4rem #aaaaaa",
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                       {this.props.realAnswer.map((item, i) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                // style={{
                                //   ...provided.draggableProps.style,
                                //   color: "#444444",
                                //   padding: "0.7557rem 0.3rem",
                                //   borderBottom: "1px solid #dddddd",
                                //   background: "white",
                                //   boxShadow: snapshot.isDragging
                                //     ? "0 0 .4rem #666"
                                //     : "",
                                //   cursor: "pointer",
                                //   borderTopRightRadius: "15px",
                                //   borderBottomRightRadius: "15px",
                                // }}
                              >
                                <i
                                  className={
                                    this.props.dark
                                      ? "fa fa-caret-left text-light"
                                      : "fa fa-caret-left text-dark"
                                  }
                                  style={{
                                    marginLeft: "-13px",
                                    fontSize: "20px",
                                  }}
                                  aria-hidden="true"
                                ></i>
                                <span
                                  style={{
                                    paddingLeft: "1rem",
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {item.value}
                                </span>
                              </div>
                            ))
                                }
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>
          </div>
        </div>
        </>}
      </div>
    );
  }
}

export default withRouter(ReviewMatching);
