import axios from "axios";
// import { constant } from "lodash";
import localStorageService from "./localStorageService";
// import { getUser } from "./apiServices";
class JwtAuthService {
  // You need to send http requst with existing token to your server to check token is valid
  // This method is being used when user logged in & app is reloaded
  // loginWithToken = () => {
  //   var user = localStorageService.getItem("auth_user");
  //   if (user) {
  //     this.setSession(user.token);
  //   }

  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       resolve(getUser());
  //     }, 100);
  //   }).then((data) => {
  //     // Token is valid
  //     this.setSession(data.data.token);
  //     this.setUser(data.data);

  //     return data.data;
  //   });
  // };

  logout = () => {
    this.setSession(null);
    this.removeUser();
    this.removeRole();
  };

  // Set token to all http request header, so you don't need to attach everytime
  setSession = (token, refresh_token) => {
    if (token) {
      localStorageService.setItem("jwt_token", token);
      localStorageService.setItem("refresh_token", refresh_token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("auth_user");
      localStorage.removeItem("role");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  // Save user to localstorage
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };
  setRole = (role) => {
    localStorageService.setItem("role", role);
  };
  // Remove user from localstorage
  removeUser = () => {
    localStorage.removeItem("auth_user");
  };
  removeRole = () => {
    localStorage.removeItem("role");
  };
}

export default new JwtAuthService();
