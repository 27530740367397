
const alertReducer = (state =[] , action)=>{
     switch(action.type){
          case 'APPEND_ALERT':
               return [...state, 
                         {
                              id:state.length,
                              text:action.text,
                              messageType:action.messageType,
                              status:true,
                         }
                    ];
          case 'TOGGLE_STATUS':
               return state.map(todo=>{
                         if(todo.id!== action.id){
                              return todo;
                         }
                         return{
                              ...todo,status:!todo.status
                         }
                    }
               );
          case 'REMOVE_ALERT':
               return state.map(todo=>{
                         if(todo.id!== action.id){
                              return todo;
                         }
                         return{
                              ...todo,status:false
                         }
                    }
               );
          default:
               return state
     }
}
export default alertReducer;

