import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./quiz.css";
import { withRouter } from "react-router-dom";
import MultipleAnswer from "./answerPart/MultipleAnswer";
import CheckAnswer from "./answerPart/CheckAnswer";
import FillBlankQuestion from "./answerPart/FillBlankQuestion";
import MatchingQuestion from "./answerPart/MatchingQuestion";
import defaultImage from "../../../images/defaultImage.jpg";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { randomArrayShuffle } from "../../../common/functions";
import { colors } from "../../../common/json";
import DragDrop from "./ReviewAnswers/MatchingReview.js/DragDrop";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

function CreateQuestionPart(props) {
  const type = [
    { id: "1", value: "radioBox", text: "Multiple Answer (Radio Box)" },
    { id: "2", value: "checkedBox", text: "Multiple Answer (Check Box)" },
    { id: "3", value: "freeText", text: "Free Text" },
    { id: "4", value: "fillBlanks", text: "Fill in the blanks" },
    { id: "5", value: "matching", text: "Matching" },
  ];
  const dark = useSelector((state) => state.isDark);
  const [answer, setAnswer] = useState(
    props.data
      ? props.data.answers
      : [
          {
            text: "",
            answerNo: 1,
            correct: true,
            type: "text",
          },
        ]
  );

  const [preview, setPreview] = useState(false);
  // eslint-disable-next-line
  const [fillBlankFinal, setFillBlankFinal] = useState();
  const [fillBlanks, setFillBlanks] = useState(
    props.data
      ? props.data.type === "fillBlanks"
        ? props.data.answer
        : props.data.answers
      : [
          {
            text: "",
            answerNo: 1,
            type: "textArea",
          },
          {
            text: "",
            answerNo: 2,
            correct: true,
            type: "text",
          },
        ]
  );

  const [matchingText, setMatchingText] = useState([{ id: 1, value: "" }]);
  const [sampleMatchText, setSampleMatchText] = useState([
    { id: 1, value: "" },
  ]);

  const addMatchingText = () => {
    let a = [
      ...matchingText,
      {
        id: matchingText[matchingText.length - 1].id + 1,
        value: "",
      },
    ];
    setMatchingText(a);
    setSampleMatchText(
      randomArrayShuffle([
        ...matchingText,
        {
          id: matchingText[matchingText.length - 1].id + 1,
          value: "",
        },
      ])
    );
  };

  const removeMatchingText = (id) => {
    let a = matchingText.filter((res) => res.id !== id);
    setMatchingText(a);
    let a1 = sampleMatchText.filter((res) => res.id !== id);
    setSampleMatchText(a1);
  };
  const updateMatchingText = (id, newValue) => {
    const index = matchingText.findIndex((x) => x.id === id);
    let g = matchingText[index];
    g["value"] = newValue;
    if (index !== -1) {
      let a = [
        ...matchingText.slice(0, index),
        g,
        ...matchingText.slice(index + 1),
      ];
      setMatchingText(a);
      setSampleMatchText(
        randomArrayShuffle([
          ...matchingText.slice(0, index),
          g,
          ...matchingText.slice(index + 1),
        ])
      );
    }
  };

  const addMulAnswer = () => {
    let a = [];
    if (answer[0].type === "text") {
      a = [
        ...answer,
        {
          text: "",
          answerNo: answer[answer.length - 1].answerNo + 1,
          correct: false,
          type: "text",
        },
      ];
    } else if (answer[0].type === "image") {
      a = [
        ...answer,
        {
          text: "",
          answerNo: answer[answer.length - 1].answerNo + 1,
          correct: false,
          type: "image",
        },
      ];
    }
    if (props.data.type === "matching") {
      addMatchingText();
    }
    setAnswer(a);
    props.updateItem(props.data.questionNo, "answers", a);
  };
  const addFillBlanksQuestion = () => {
    if (preview) {
      setPreview(false);
      setTimeout(() => {
        setPreview(true);
      }, 500);
    }
    if (fillBlanks[fillBlanks.length - 1].type !== "textArea") {
      const a = [
        ...fillBlanks,
        {
          text: "",
          answerNo: fillBlanks[fillBlanks.length - 1].answerNo + 1,
          type: "textArea",
        },
      ];
      setFillBlanks(a);
      props.updateItem(props.data.questionNo, "answer", a);
    }
  };
  const addFillBlanksAnswer = () => {
    if (preview) {
      setPreview(false);
      setTimeout(() => {
        setPreview(true);
      }, 500);
    }
    let a = [
      ...fillBlanks,
      {
        text: "",
        answerNo: fillBlanks[fillBlanks.length - 1].answerNo + 1,
        correct: true,
        type: "text",
      },
    ];
    setFillBlanks(a);
    props.updateItem(props.data.questionNo, "answer", a);
    a = a.filter((res) => res.type === "text");
    props.updateItem(props.data.questionNo, "answers", a);
    const a1 = props.data.question + "<BavarInput>";
    props.updateItem(props.data.questionNo, "question", a1);
  };
  const updateFillBlanks = (id, whichValue, newValue) => {
    if (preview) {
      setPreview(false);
      setTimeout(() => {
        setPreview(true);
      }, 500);
    }

    const index = fillBlanks.findIndex((x) => x.answerNo === id);
    let g = fillBlanks[index];
    g["text"] = newValue;
    if (index !== -1) {
      let a = [
        ...fillBlanks.slice(0, index),
        g,
        ...fillBlanks.slice(index + 1),
      ];
      setFillBlanks(a);
      let a1 = "";
      for (const obj of a) {
        if (obj.type === "text") {
          a1 += "<BavarInput>";
        } else if (obj.type === "textArea") {
          a1 += obj.text;
        }
      }
      props.updateItem(props.data.questionNo, "question", a1);
      if (whichValue === "answer") {
        a = a.filter((res) => res.type === "text");
        props.updateItem(props.data.questionNo, "answers", a);
      }
    }
  };
  const removeFillBlanks = (id) => {
    if (preview) {
      setPreview(false);
      setTimeout(() => {
        setPreview(true);
      }, 500);
    }
    if (fillBlanks.length !== 1) {
      let a = fillBlanks.filter((res) => res.answerNo !== id);
      setFillBlanks(a);
      let a1 = "";
      for (const obj of a) {
        if (obj.type === "text") {
          a1 += "<BavarInput>";
        } else if (obj.type === "textArea") {
          a1 += obj.text;
        }
      }
      props.updateItem(props.data.questionNo, "question", a1);
      a = a.filter((res) => res.type !== "textArea");
      props.updateItem(props.data.questionNo, "answers", a);
    }
  };
  const removeMulAnswer = (id) => {
    if (answer.length !== 1) {
      const a = answer.filter((res) => res.answerNo !== id);
      const a2 = answer.filter((res) => res.answerNo === id);
      if (a2[0].correct) {
        a[0].correct = true;
      }
      setAnswer(a);
      props.updateItem(props.data.questionNo, "answers", a);
    }
  };
  const removeCheckAnswer = (id) => {
    if (answer.length !== 1) {
      const a = answer.filter((res) => res.answerNo !== id);
      const a2 = answer.filter((res) => res.answerNo === id);
      if (a2[0].correct && answer.length === 2) {
        a[0].correct = true;
      }
      setAnswer(a);
      if (props.data.type === "matching") {
        removeMatchingText(id);
      }
      props.updateItem(props.data.questionNo, "answers", a);
    }
  };
  const updateMulAnswerItem = (id, whichValue, newValue) => {
    if (whichValue === "correct") {
      for (const obj of answer) {
        if (obj.answerNo !== id) {
          var index = answer.findIndex((x) => x.answerNo === obj.answerNo);
          let g1 = answer[index];
          g1[whichValue] = false;
          if (index !== -1) {
            const a = [
              ...answer.slice(0, index),
              g1,
              ...answer.slice(index + 1),
            ];
            setAnswer(a);
            props.updateItem(props.data.questionNo, "answers", a);
          }
        }
      }
    }
    index = answer.findIndex((x) => x.answerNo === id);
    let g2 = answer[index];
    g2[whichValue] = newValue;
    if (index !== -1) {
      const a = [...answer.slice(0, index), g2, ...answer.slice(index + 1)];
      setAnswer(a);
      props.updateItem(props.data.questionNo, "answers", a);
    }
  };

  const updateCheckAnswerItem = (id, whichValue, newValue) => {
    const index = answer.findIndex((x) => x.answerNo === id);
    let g3 = answer[index];
    g3[whichValue] = newValue;
    if (index !== -1) {
      const a = [...answer.slice(0, index), g3, ...answer.slice(index + 1)];
      setAnswer(a);
      props.updateItem(props.data.questionNo, "answers", a);
    }
  };
  const handleChange1 = (e) => {
    const {
      target: { value },
    } = e;
    props.updateItem(props.data.questionNo, "question", value);
  };
  async function onChangeAnswer(e) {
    const {
      target: { name, value },
    } = e;
    if (name === "type") {
      if (value === "fillBlanks") {
        setFillBlanks([
          {
            text: "",
            answerNo: 1,
            type: "textArea",
          },
          {
            text: "",
            answerNo: 2,
            correct: true,
            type: "text",
          },
        ]);
      }
      if (value === "matching") {
        setSampleMatchText([{ id: 1, value: "" }]);
        props.updateItem(props.data.questionNo, "answers", [
          {
            text: ["", ""],
            answerNo: 1,
            correct: true,
            type: "text",
          },
        ]);
        setAnswer([
          {
            text: ["", ""],
            answerNo: 1,
            correct: true,
            type: "text",
          },
        ]);
      } else {
        props.updateItem(props.data.questionNo, "answers", [
          {
            text: "",
            answerNo: 1,
            correct: true,
            type: "text",
          },
        ]);
        setAnswer([
          {
            text: "",
            answerNo: 1,
            correct: true,
            type: "text",
          },
        ]);
      }
      props.updateItem(props.data.questionNo, "question", "");
      props.updateItem(props.data.questionNo, "questionImage", "");
      props.updateItem(props.data.questionNo, "questionImageAlignment", "left");
      props.updateItem(props.data.questionNo, name, value);
    } else if (name === "freeText") {
      props.updateItem(props.data.questionNo, "answers", [
        {
          text: value,
          answerNo: 1,
          correct: true,
          type: "text",
        },
      ]);
      setAnswer([
        {
          text: value,
          answerNo: 1,
          correct: true,
          type: "text",
        },
      ]);
    } else {
      props.updateItem(props.data.questionNo, name, value);
    }
  }
  const changeAnswerType = (typeAnswer) => {
    setAnswer([
      {
        text: "",
        answerNo: 1,
        correct: true,
        type: typeAnswer,
      },
    ]);
    props.updateItem(props.data.questionNo, "answers", [
      {
        text: "",
        answerNo: 1,
        correct: true,
        type: typeAnswer,
      },
    ]);
  };
  const uploadQuestionImage = (e) => {
    if (e.target.files[0]) {
      var reader = new FileReader();
      reader.onloadend = () => {
        props.updateItem(props.data.questionNo, "questionImage", reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const refresh = React.useCallback(() => {
    if (
      preview &&
      props.data.type !== "fillBlanks" &&
      props.data.type !== "matching"
    ) {
      let content =
        "<span className='absolute' style='margin-left:-19px;margin-top:0px;'>" +
        (props.index + 1) +
        ") </span>" +
        (props.data.question === "" ? "Question ?" : props.data.question);
      var out = document.getElementById(
        "questionPreview" + props.data.questionNo
      );
      out.innerHTML = content;
    }
  }, [
    preview,
    props.data.question,
    props.index,
    props.data.type,
    props.data.questionNo,
  ]);
  useEffect(() => {
    refresh();
    if (props.data.type === "matching") {
      // alert();
      if (props.data.answers.length > 0) {
        let x = [];
        let y = [];
        for (const obj of props.data.answers) {
          x.push({
            id: obj.answerNo,
            value: obj.text[1],
          });
          y.push({
            id: obj.answerNo,
            value: obj.text[1],
          });
        }
        setMatchingText(x);
        setSampleMatchText(randomArrayShuffle(y));
      }
    }
  }, [
    props.data.question,
    preview,
    refresh,
    props.data.type,
    props.data.answers,
  ]);

  return (
    <div
      id={"q" + props.index}
      className="row p-0 m-0  questionPart mb-5"
      style={colors[props.data.questionNo % 10]}
      key={props.data.questionNo}
    >
      <div className="form-group col-12 pt-3">
        <h2>
          {" "}
          Question {props.index + 1}{" "}
          {props.length > 1 && (
            <i
              className="fa fa-window-close pull-right pointer"
              style={{ fontSize: "16px" }}
              aria-hidden="true"
              onClick={() =>
                props.removeQuestion(props.data.questionNo, props.index)
              }
            ></i>
          )}
        </h2>
        <div
          className="absolute text-right"
          style={{ right: "40px", top: "10px" }}
        >
          <span class="badge badge-transparent p-2 pr-4 pl-4 text-light">
            Score: &nbsp;
            {(props.quiz.scoreType === "parallel"
              ? props.score[0]
                ? props.score[0].score
                : ""
              : props.data.score)}
            %
          </span>
        </div>
      </div>
      <br></br>
      <div className="form-group col-md-4">
        <label
          htmlFor="type"
          className={dark ? "createContentLabelDark" : "createContentLabel"}
        >
          Type
        </label>
        <select
          className={
            dark
              ? "form-control dropDownSelectDark"
              : "form-control dropDownSelect"
          }
          id="type"
          name="type"
          onChange={onChangeAnswer}
          value={props.data.type}
        >
          <option value="" disabled="">
            Select value
          </option>
          {type.map((option, index) => (
            <option value={option.value}>{option.text}</option>
          ))}
        </select>
      </div>
      <div className="form-group col-md-4">
        <label
          htmlFor="score"
          className={dark ? "createContentLabelDark" : "createContentLabel"}
        >
          Score
        </label>
        <div class="input-group mb-3">
          <input
            type="number"
            className={
              dark ? "form-control textInputDark" : "form-control textInput"
            }
            name="score"
            placeholder="Score"
            onChange={onChangeAnswer}
            value={props.data.score}
          />
          <div class="input-group-append">
            <span class="input-group-text" >
              %
            </span>
          </div>
        </div>
        
      </div>
      {props.data.type !== "fillBlanks" &&
        props.data.type !== "matching" &&
        props.data.type !== "" && (
          <div className="form-group col-12">
            <label
              htmlFor="content"
              className={dark ? "createContentLabelDark" : "createContentLabel"}
            >
              Question
            </label>

            <div className="row pb-0 mb-0">
              <div className="col-md pb-0 mb-0">
                <input
                  placeholder="Question ?"
                  className="answer"
                  onChange={handleChange1}
                  value={props.data.question}
                ></input>
              </div>
              <div className="col-md-2 pb-0 mb-0">
                <label
                  htmlFor={"questionImage" + props.data.questionNo}
                  className="buttonBlack btn mt-2"
                >
                  {" "}
                  <i className="fa fa-picture-o" aria-hidden="true"></i>
                </label>
                <input
                  id={"questionImage" + props.data.questionNo}
                  style={{ display: "none" }}
                  type="file"
                  onChange={uploadQuestionImage}
                />
              </div>
            </div>
            {props.data.questionImage !== "" && props.data.type !== "matching" && (
              <div className="row pb-0 mb-0">
                <div className="col pb-0 mb-0">
                  <img
                    id="question_image"
                    alt="question_image"
                    height="200px"
                    src={props.data.questionImage}
                  />
                </div>
                <div className="col-2 pb-0 mb-0">
                  <div className="dropdown">
                    <button
                      className="btn buttonBlack dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i
                        className={
                          props.data.questionImageAlignment === "center"
                            ? "fa fa-align-center"
                            : props.data.questionImageAlignment === "right"
                            ? "fa fa-align-right"
                            : "fa fa-align-left"
                        }
                        aria-hidden="true"
                      ></i>
                    </button>
                    <div
                      className="dropdown-menu p-0 m-0"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <span
                        className={
                          props.data.questionImageAlignment === "right"
                            ? "dropdown-item pointer active"
                            : "dropdown-item pointer"
                        }
                        onClick={() =>
                          props.updateItem(
                            props.data.questionNo,
                            "questionImageAlignment",
                            "right"
                          )
                        }
                      >
                        <i className="fa fa-align-right" aria-hidden="true"></i>
                        &nbsp;&nbsp;&nbsp; Right
                      </span>
                      <span
                        className={
                          props.data.questionImageAlignment === "center"
                            ? "dropdown-item pointer active"
                            : "dropdown-item pointer"
                        }
                        onClick={() =>
                          props.updateItem(
                            props.data.questionNo,
                            "questionImageAlignment",
                            "center"
                          )
                        }
                      >
                        <i
                          className="fa fa-align-center"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;&nbsp; Center
                      </span>
                      <span
                        className={
                          props.data.questionImageAlignment === "left"
                            ? "dropdown-item pointer active"
                            : "dropdown-item pointer"
                        }
                        onClick={() =>
                          props.updateItem(
                            props.data.questionNo,
                            "questionImageAlignment",
                            "left"
                          )
                        }
                      >
                        <i className="fa fa-align-left" aria-hidden="true"></i>
                        &nbsp;&nbsp;&nbsp; Left
                      </span>
                      <span
                        className={
                          props.data.questionImageAlignment === "justify"
                            ? "dropdown-item pointer active"
                            : "dropdown-item pointer"
                        }
                        onClick={() =>
                          props.updateItem(
                            props.data.questionNo,
                            "questionImageAlignment",
                            "justify"
                          )
                        }
                      >
                        <i
                          className="fa fa-align-justify"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;&nbsp; Justify
                      </span>
                    </div>
                  </div>
                  <br></br>
                  <div>
                    <label
                      htmlFor={"questionImageRefresh" + props.data.questionNo}
                      className="buttonBlack btn mt-2"
                    >
                      {" "}
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    </label>
                    <input
                      id={"questionImageRefresh" + props.data.questionNo}
                      style={{ display: "none" }}
                      type="file"
                      onChange={uploadQuestionImage}
                    />
                  </div>

                  <button
                    className="btn buttonBlack "
                    onClick={() =>
                      props.updateItem(
                        props.data.questionNo,
                        "questionImage",
                        ""
                      )
                    }
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      {(props.data.type === "radioBox" || props.data.type === "checkedBox") && (
        <div className="form-group col-12">
          <label
            htmlFor="type"
            className={dark ? "createContentLabelDark" : "createContentLabel"}
          >
            Alignment
          </label>
          <div className="row">
            <div className="col-md-3">
              <label className="containerRadio2">
                <span
                  className={dark ? "radioMarkLabelDark2" : "radioMarkLabel2"}
                >
                  <i
                    className="fa fa-align-justify fa-lg"
                    aria-hidden="true"
                  ></i>
                  &nbsp;&nbsp;&nbsp;{"Single Colum"}
                </span>
                <input
                  type="radio"
                  name={"radioAlign" + props.data.questionNo}
                  checked={props.data.align === "singleColum" && "checked"}
                  onChange={() =>
                    props.updateItem(
                      props.data.questionNo,
                      "align",
                      "singleColum"
                    )
                  }
                />
                <span className="radioMark2"></span>
              </label>
            </div>
            <div className="col-md-3">
              <label className="containerRadio2">
                <span
                  className={dark ? "radioMarkLabelDark2" : "radioMarkLabel2"}
                >
                  <i className="fa fa-th-large fa-lg" aria-hidden="true"></i>
                  &nbsp;&nbsp;&nbsp;{"Double Colum"}
                </span>
                <input
                  type="radio"
                  name={"radioAlign" + props.data.questionNo}
                  checked={props.data.align === "doubleColum" && "checked"}
                  onChange={() =>
                    props.updateItem(
                      props.data.questionNo,
                      "align",
                      "doubleColum"
                    )
                  }
                />
                <span className="radioMark2"> </span>
              </label>
            </div>
            <div className="col-md-3">
              <label className="containerRadio2">
                <span
                  className={dark ? "radioMarkLabelDark2" : "radioMarkLabel2"}
                >
                  <i className="fa fa-th fa-lg" aria-hidden="true"></i>
                  &nbsp;&nbsp;&nbsp;{"Three Colum"}
                </span>
                <input
                  type="radio"
                  name={"radioAlign" + props.data.questionNo}
                  checked={props.data.align === "threeColum" && "checked"}
                  onChange={() =>
                    props.updateItem(
                      props.data.questionNo,
                      "align",
                      "threeColum"
                    )
                  }
                />

                <span className="radioMark2"> </span>
              </label>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-3">
              <label className="containerRadio2">
                <span
                  className={dark ? "radioMarkLabelDark2" : "radioMarkLabel2"}
                >
                  <i className="fa fa-braille fa-lg" aria-hidden="true"></i>
                  &nbsp;&nbsp;&nbsp;{"Four Colum"}
                </span>
                <input
                  type="radio"
                  name={"radioAlign" + props.data.questionNo}
                  checked={props.data.align === "fourColum" && "checked"}
                  onChange={() =>
                    props.updateItem(
                      props.data.questionNo,
                      "align",
                      "fourColum"
                    )
                  }
                />

                <span className="radioMark2"> </span>
              </label>
            </div>
            <div className="col-md-3">
              <label className="containerRadio2">
                <span
                  className={dark ? "radioMarkLabelDark2" : "radioMarkLabel2"}
                >
                  <i className="fa fa-tasks fa-lg" aria-hidden="true"></i>
                  &nbsp;&nbsp;&nbsp;{"Auto"}
                </span>
                <input
                  type="radio"
                  name={"radioAlign" + props.data.questionNo}
                  checked={props.data.align === "auto" && "checked"}
                  onChange={() =>
                    props.updateItem(props.data.questionNo, "align", "auto")
                  }
                />

                <span className="radioMark2"> </span>
              </label>
            </div>
          </div>
        </div>
      )}
      {props.data.type !== "" && (
        <div className="form-group col-12">
          {props.data.type === "fillBlanks" && (
            <>
              <label
                htmlFor="content"
                className={
                  dark ? "createContentLabelDark" : "createContentLabel"
                }
              >
                Question para &nbsp;&nbsp;&nbsp;&nbsp;
              </label>
              <span
                className={
                  fillBlanks[fillBlanks.length - 1].type === "textArea"
                    ? "badge badge-secondary disable p-2 pr-3 pl-3"
                    : "badge badge-light pointer p-2 pr-3 pl-3"
                }
                onClick={addFillBlanksQuestion}
              >
                <i className="fa fa-plus fa-lg " aria-hidden="true"></i>
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </>
          )}
          <label
            htmlFor="content"
            className={dark ? "createContentLabelDark" : "createContentLabel"}
          >
            Answer &nbsp;&nbsp;&nbsp;&nbsp;
          </label>
          {(props.data.type === "radioBox" ||
            props.data.type === "checkedBox" ||
            props.data.type === "matching" ||
            props.data.type === "fillBlanks") && (
            <span
              className="badge badge-light pointer p-2 pr-3 pl-3 mb-2"
              onClick={
                props.data.type === "fillBlanks"
                  ? addFillBlanksAnswer
                  : addMulAnswer
              }
            >
              <i className="fa fa-plus fa-lg " aria-hidden="true"></i>
            </span>
          )}
          &nbsp; &nbsp; &nbsp; &nbsp;
          {(props.data.type === "radioBox" ||
            props.data.type === "checkedBox") &&
            (answer[0].type === "text" ? (
              <span
                className="badge badge-light pointer p-2 pr-3 pl-3"
                onClick={() => changeAnswerType("image")}
              >
                Change as &nbsp;
                <i className="fa fa-picture-o" aria-hidden="true"></i>
              </span>
            ) : (
              <span
                className="badge badge-light pointer p-2 pr-3 pl-3"
                onClick={() => changeAnswerType("text")}
              >
                Change as &nbsp;
                <i className="fa fa-file-text-o" aria-hidden="true"></i>
              </span>
            ))}
          <div className="row pt-2">
            <div className="form-group col-12">
              {props.data.type === "radioBox" &&
                answer.map((value, index) => (
                  <MultipleAnswer
                    answersLength={answer.length}
                    data={value}
                    index={props.data.questionNo}
                    key={index}
                    removeMulAnswer={removeMulAnswer}
                    updateMulAnswerItem={updateMulAnswerItem}
                  />
                ))}
              {props.data.type === "freeText" && (
                <textarea
                  className="answer"
                  rows="5"
                  name="freeText"
                  onChange={onChangeAnswer}
                  placeholder="write here . . "
                ></textarea>
              )}
              {props.data.type === "checkedBox" &&
                answer.map((value, index) => (
                  <CheckAnswer
                    answersLength={answer.length}
                    data={value}
                    index={props.data.questionNo}
                    key={index}
                    removeCheckAnswer={removeCheckAnswer}
                    updateCheckAnswerItem={updateCheckAnswerItem}
                  />
                ))}
              {props.data.type === "fillBlanks" &&
                fillBlanks.map((value, index) => (
                  <FillBlankQuestion
                    fillBlanksLength={fillBlanks.length}
                    data={value}
                    index={props.data.questionNo}
                    key={index}
                    removeFillBlanks={removeFillBlanks}
                    updateFillBlanks={updateFillBlanks}
                  />
                ))}
              {props.data.type === "matching" &&
                answer.map((value, index) => (
                  <MatchingQuestion
                    removeMatchingText={removeMatchingText}
                    updateMatchingText={updateMatchingText}
                    addMatchingText={addMatchingText}
                    answersLength={answer.length}
                    data={value}
                    index={props.data.questionNo}
                    k={index}
                    key={index}
                    removeCheckAnswer={removeCheckAnswer}
                    updateCheckAnswerItem={updateCheckAnswerItem}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
      {props.data.type !== "" && (
        <div className="form-group col-12">
          <button
            className="buttonBlack pull-right "
            onClick={() => setPreview(!preview)}
          >
            {preview ? (
              <i className="fa fa-window-close" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-television" aria-hidden="true"></i>
            )}
            &nbsp;&nbsp; Preview
          </button>
        </div>
      )}
      {preview && (
        <div className="form-group col-12 pt-0 mt-0">
          <div
            className={
              dark
                ? "previewQuestionDark pr-4 pl-4 pt-3 pb-1 "
                : "previewQuestion pr-4 pl-4 pt-3 pb-1 "
            }
          >
            {props.data.type === "freeText" && (
              <>
                <div
                  id={"questionPreview" + props.data.questionNo}
                  contentEditable="false"
                  className="questionPreview pl-3"
                ></div>
                <div
                  className={
                    props.data.questionImageAlignment === "center"
                      ? "d-flex justify-content-center"
                      : props.data.questionImageAlignment === "right"
                      ? "d-flex justify-content-end"
                      : props.data.questionImageAlignment === "left"
                      ? "d-flex justify-content-start"
                      : ""
                  }
                >
                  {props.data.questionImage !== "" && (
                    <img
                      id="question_image_preview"
                      alt="question_image_preview"
                      width={
                        props.data.questionImageAlignment === "justify" &&
                        "100%"
                      }
                      height="300px"
                      className="pb-3"
                      src={props.data.questionImage}
                    />
                  )}
                </div>
                <textarea
                  className="answer"
                  style={{ pointerEvents: "none" }}
                  rows="5"
                  value={props.data.answers[0].text}
                ></textarea>
              </>
            )}
            {props.data.type === "radioBox" && (
              <>
                <div
                  id={"questionPreview" + props.data.questionNo}
                  contentEditable="false"
                  className="questionPreview pl-3 pb-2"
                ></div>
                <div
                  className={
                    props.data.questionImageAlignment === "center"
                      ? "d-flex justify-content-center"
                      : props.data.questionImageAlignment === "right"
                      ? "d-flex justify-content-end"
                      : props.data.questionImageAlignment === "left"
                      ? "d-flex justify-content-start"
                      : ""
                  }
                >
                  {props.data.questionImage !== "" && (
                    <img
                      id="question_image_preview"
                      alt="question_image_preview"
                      width={
                        props.data.questionImageAlignment === "justify" &&
                        "100%"
                      }
                      height="300px"
                      className="pb-3"
                      src={props.data.questionImage}
                    />
                  )}
                </div>
                <div className="row pr-5 pl-3">
                  {props.data.answers.map(
                    (value, index) =>
                      value.text !== "" && (
                        <div
                          className={
                            props.data.align === "singleColum"
                              ? "col-12"
                              : props.data.align === "doubleColum"
                              ? "col-6"
                              : props.data.align === "threeColum"
                              ? "col-4"
                              : props.data.align === "fourColum"
                              ? "col-3"
                              : "col answerAlignment"
                          }
                        >
                          <label className="containerRadio3">
                            <span
                              className={
                                dark ? "radioMarkLabelDark3" : "radioMarkLabel3"
                              }
                            >
                              {" "}
                              {value.type === "text" && value.text}
                              {value.type === "image" && (
                                <img
                                  alt="answer_image_preview"
                                  className="answer_image pb-3"
                                  height="100px"
                                  src={
                                    value.text === ""
                                      ? defaultImage
                                      : value.text
                                  }
                                />
                              )}
                            </span>
                            <input
                              type="radio"
                              name={"radioPreview" + index}
                              checked={value.correct && "checked"}
                            />
                            <span className="radioMark3"></span>
                          </label>
                        </div>
                      )
                  )}
                </div>
              </>
            )}
            {props.data.type === "checkedBox" && (
              <>
                <div
                  id={"questionPreview" + props.data.questionNo}
                  contentEditable="false"
                  className="questionPreview pl-2"
                ></div>
                <div
                  className={
                    props.data.questionImageAlignment === "center"
                      ? "d-flex justify-content-center"
                      : props.data.questionImageAlignment === "right"
                      ? "d-flex justify-content-end"
                      : props.data.questionImageAlignment === "left"
                      ? "d-flex justify-content-start"
                      : ""
                  }
                >
                  {props.data.questionImage !== "" && (
                    <img
                      id="question_image_preview"
                      alt="question_image_preview"
                      height="300px"
                      width={
                        props.data.questionImageAlignment === "justify" &&
                        "100%"
                      }
                      className="pb-3"
                      src={props.data.questionImage}
                    />
                  )}
                </div>
                <div className="row pr-5 pl-3">
                  {props.data.answers.map(
                    (value) =>
                      value.text !== "" && (
                        <div
                          className={
                            props.data.align === "singleColum"
                              ? "col-12"
                              : props.data.align === "doubleColum"
                              ? "col-6"
                              : props.data.align === "threeColum"
                              ? "col-4"
                              : props.data.align === "fourColum"
                              ? "col-3"
                              : "col answerAlignment"
                          }
                        >
                          <label className="containerCheckPre">
                            <span
                              className={
                                dark
                                  ? "checkMarkLabelPreDark"
                                  : "checkMarkPreLabel"
                              }
                            >
                              {value.type === "text" && value.text}
                              {value.type === "image" && (
                                <img
                                  alt="answer_image_preview"
                                  className="answer_image pb-3"
                                  height="100px"
                                  src={
                                    value.text === ""
                                      ? defaultImage
                                      : value.text
                                  }
                                />
                              )}
                            </span>
                            <input
                              type="checkbox"
                              checked={value.correct && "checked"}
                            />
                            <span className="checkMarkPre"></span>
                          </label>
                        </div>
                      )
                  )}
                </div>
              </>
            )}
            {props.data.type === "fillBlanks" && (
              <div className="pt-3 p-2">
                <span
                  className="absolute "
                  style={{
                    marginLeft: "-19px",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  {props.index + 1}) {"Fill in the blanks  "}
                </span>
                <br></br>
                <br></br>
                <DndProvider backend={HTML5Backend}>
                  <DragDrop
                    answer={fillBlanks.filter((res) => res.type === "text")}
                    questionCheck={fillBlanks[0].type}
                    question={props.data.question.split("<BavarInput>")}
                    setFillBlankFinal={setFillBlankFinal}
                  ></DragDrop>
                </DndProvider>
              </div>
            )}
            {props.data.type === "matching" && (
              <div className="pt-3 p-2">
                <span
                  className="absolute"
                  style={{ marginLeft: "-19px", marginTop: "8px" ,fontSize:"18px",fontWeight:"600",fontFamily:"sans-serif"}}
                >
                  {props.index + 1}){" Arrange the words "}
                </span>
                <br></br>

                <div className="row">
                  <div className="col " style={{ paddingTop: "1.5rem" }}>
                    {answer.map((value, index) => (
                      <div className="row reviewMatch" style={{ paddingTop: "0.1rem" }}>
                        <div className=" ml-2 ">
                          <div className="input-group pt-2 border-0">
                            <input
                              className="border-0 rounded pl-3 reviewInput"
                              name="text1"
                              value={value.text[0]}
                              style={{ pointerEvents: "none" }}
                            ></input>
                            <div className="input-group-append reviewInputNumber">
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                {parseInt(index) + 1}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col mt-0  align-self-center">
                          <hr></hr>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="col">
                    <div>
                      <DragDropContext
                        onDragEnd={(param) => {
                          const srcI = param.source.index;
                          const desI = param.destination?.index;
                          if (desI || desI === 0) {
                            sampleMatchText.splice(
                              desI,
                              0,
                              sampleMatchText.splice(srcI, 1)[0]
                            );
                          }
                        }}
                      >
                        <div>
                          <Droppable
                            droppableId={"droppable-" + props.data.questionNo}
                          >
                            {(provided, snapshot) => (
                              <div
                                style={{
                                  margin: "2rem auto",
                                  marginLeft: "0rem",
                                  // maxWidth: "30rem",
                                  paddingRight: "2rem",
                                  paddingLeft: "0rem",
                                  paddingBottom: "2rem",
                                  borderRadius: "0.2rem",
                                  // boxShadow: "0.1rem 0.1rem 0.4rem #aaaaaa",
                                }}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {sampleMatchText.map((item, i) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={"draggable-" + item.id}
                                    index={i}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...provided.draggableProps.style,
                                          color: "#444444",
                                          padding: "0.7557rem 0.3rem",
                                          borderBottom: "1px solid #dddddd",
                                          background: "white",
                                          boxShadow: snapshot.isDragging
                                            ? "0 0 .4rem #666"
                                            : "",
                                          cursor: "pointer",
                                          borderTopRightRadius: "15px",
                                          borderBottomRightRadius: "15px",
                                        }}
                                      >
                                        <i
                                          className="fa fa-caret-left"
                                          style={{
                                            marginLeft: "-13px",
                                            fontSize: "20px",
                                          }}
                                          aria-hidden="true"
                                        ></i>
                                        <span
                                          style={{
                                            paddingLeft: "1rem",
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          {item.value}
                                        </span>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      </DragDropContext>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(CreateQuestionPart);
