import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import "./Login.css";
import JwtAuthService from "../../services/JwtAuthService";
import CookieService from "../../services/CookieService";
import logo from "../../images/LOGOWhite.png";
// import $ from 'jquery';
// import {
//   getToken,
//   getUser,
//   getRole,
//   // signUp,
//   url,
//   signUp,
// } from "../../Services/apiServices";
import localStorageService from "../../services/localStorageService";

// import { useSelector } from "react-redux";
import {
  appendAlert,
  authorize,
  changeRole,
  changeRoleGuest,
  changeRoleLearner,
  setUser,
  unAuthorize,
} from "../../actions";
import Loader3 from "../Loader/Loader3";

function Login(props) {
  const url = "hi";
  const [state, setState] = React.useState({});
  const [sign, changeSign] = useState(true);
  const [rememberPass, changeRememberPass] = useState(false);
  const [strength, setStrength] = useState(1);
  const [loader, changeLoader] = useState(false);
  const [alertEmailLogin, changeAlertEmailLogin] = useState(false);
  const [alertPassLogin, changeAlertPassLogin] = useState(false);
  const [loginEmailNull, changeLoginEmailNull] = useState(false);
  const [loginPassNull, changeLoginPassNull] = useState(false);
  const [passLength, changePassLength] = useState(false);
  const [passUpper, changePassUpper] = useState(false);
  const [passNum, changePassNum] = useState(false);
  const [passSpecial, changePassSpecial] = useState(false);
  const dispatch = useDispatch();
  // const role = useSelector((state) => state.role);
  const AuthCheck = () => {
    var UserData = localStorageService.getItem("auth_user");
    var roleNo = localStorageService.getItem("role");
    dispatch(setUser(UserData));
    if (UserData && roleNo) {
      dispatch(authorize());
      dispatch(changeRole(roleNo[0].id));
    } else {
      dispatch(unAuthorize());
      dispatch(changeRoleGuest());
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (CookieService.checkCookie("rememberPassword")) {
      if (CookieService.getCookie("rememberPassword") === "true") {
        setState({
          ...state,
          email: CookieService.getCookie("axUser"),
          password: CookieService.getCookie("axPass"),
        });
        changeRememberPass(true);
        check();
      }
    }
    // eslint-disable-next-line
  }, []);
  const onKeyUp = (event) => {
    if (event.key === "Enter") {
      signInSubmit();
    }
  };
  const onKeyUp1 = (event) => {
    if (event.key === "Enter") {
      signInSubmit();
    }
  };
  function signIn() {
    changeSign(true);
    if (props.location.pathname === "/signup") {
      props.history.push({ pathname: "/login" });
    }
  }
  function signUpFun() {
    changeSign(false);
    if (props.location.pathname === "/login") {
      props.history.push({ pathname: "/signup" });
    }
  }
  function signUpSubmit() {
    if (strength > 4) {
      if (state.signUpPass === state.signUpConfirmPass) {
        changeLoader(true);
        // let body = {
        //   username: state.signUpEmail,
        //   password: state.signUpPass,
        //   enabled: false,
        //   accountNonExpired: true,
        //   credentialsNonExpired: true,
        //   accountNonLocked: true,
        //   firstName: "",
        //   lastName: "",
        //   middleName: "",
        //   provider: "local",
        //   mobileNumber: "",
        //   address: {
        //     address: null,
        //     city: null,
        //     state: null,
        //     zip: null,
        //     country: null,
        //     lat: 0.0,
        //     lng: 0.0,
        //   },
        // };
        // let roleEx = 0;
        // if (state.role) roleEx = 2;
        // else roleEx = 1;
        // signUp(roleEx, body)
        //   .then((response) => {

        changeLoader(false);
        // if (response.status === 200) {
        if (
          state.signUpEmail === "nkbram95@gmail.com" &&
          state.signUpPass === "!@#$QWER1234qwer"
        ) {
          props.history.push({
            pathname: "/dashboard",
          });
          dispatch(appendAlert("Account created successfully","success"));
          dispatch(appendAlert("Please verify your account through your mail","info"));
          // } else if (response.status === 400) {
          //    dispatch(appendAlert(response.data.message));
        } else {
          dispatch(appendAlert("Email address already in use..","warning"));
        }

        // })
        // .catch((error) => {
        //    dispatch(appendAlert(error.message));
        // });
      } else {
        dispatch(appendAlert("Your password doesn't match!","danger"));
        setState({ ...state, setSignUpPass: "", setSignUpConfirmPass: "" });
      }
    } else {
      if (strength === 4) {
        dispatch(
          appendAlert(
            "Your password strength is Fair,please give strong password!","warning"
          )
        );
      } else if (strength === 3) {
        dispatch(
          appendAlert(
            "Your password strength is bad ,please give strong password!","warning"
          )
        );
      } else if (strength === 2) {
        dispatch(
          appendAlert(
            "Your password strength is week ,please give strong password!","warning"
          )
        );
      } else if (strength === 1) {
        dispatch(
          appendAlert(
            "Your password strength is week ,please give strong password!","warning"
          )
        );
      }
    }
  }
  function close() {
    window.$("#loginModal").modal("hide");
  }
  function signInSubmit() {
    loginWithEmailAndPassword(state.email, state.password);
  }
  const loginWithEmailAndPassword = (email, password) => {
    changeLoader(true);
    // getToken(email, password).then((data) => {
    //   if (!data.error) {
    if (state.email === "nkbram95@gmail.com" && state.password === "www") {
      window.$("#loginModal").modal("hide");
      // JwtAuthService.setSession(data.access_token, data.refresh_token);
      JwtAuthService.setSession("token", "refresh_token");
      // getUser()
      //   .then((data) => {
      //     getRole().then((role) => {
      JwtAuthService.setRole("Learner");
      dispatch(changeRoleLearner());

      changeLoader(false);
      // var path = "";
      if (rememberPass) {
        CookieService.setCookie("rememberPassword", "true", 1000);
        CookieService.setCookie("axUser", email, 1000);
        CookieService.setCookie("axPass", password, 1000);
      } else {
        CookieService.setCookie("rememberPassword", "false", 1000);
        CookieService.setCookie("axUser", "", 1000);
        CookieService.setCookie("axPass", "", 1000);
      }
      props.history.push({ pathname: "/dashboard" });
      // if (role.data.roles[0].id === 3) {
      //   props.history.push({ pathname: "/dashboard" });
      // } else {
      //   if (props.hitPoint === "/rentalUnits") {
      //     if (role.data.roles[0].id === 2) {
      //       props.history.push({ pathname: "/property" });
      //     } else {
      //       props.history.push({ pathname: "/rentalUnits" });
      //     }
      //   } else if (
      //     props.hitPoint === "/application" &&
      //     role.data.roles[0].id === 2
      //   ) {
      //     props.history.push({ pathname: "/rentalUnits" });
      //     dispatch(appendAlert("Sorry!!! Landlord can't Apply Units"));
      //   } else if (props.hitPoint === "/application") {
      //     props.history.push({
      //       pathname:
      //         "/application" +
      //         (props.match.params.id
      //           ? "/" + props.match.params.id
      //           : ""),
      //       state: {
      //         access_token: data.access_token,
      //         user: localStorageService.getItem("auth_user"),
      //       },
      //     });
      //   } else {
      //     path = props.hitPoint;
      //     props.history.push({
      //       pathname: path,
      //       state: {
      //         access_token: data.access_token,
      //         user: localStorageService.getItem("auth_user"),
      //       },
      //     });
      //   }
      // }
      dispatch(authorize());
      dispatch(appendAlert("You're successfully logged in!","success"));
      // });
      JwtAuthService.setUser({
        username: state.email,
        password: "",
        enabled: true,
        accountNonExpired: true,
        credentialsNonExpired: true,
        accountNonLocked: true,
        firstName: "Karunaa",
        lastName: "Bavaram",
        middleName: "",
        address: {
          address: null,
          city: null,
          state: null,
          zip: null,
          country: null,
          lat: 0,
          lng: 0,
        },
        mobileNumber: "(123) 456-7890",
        stripeCustomerId: "token",
        stripeAccountId: null,
        provider: "local",
        coverImage: "ubunthu7.jpg-4e4fae48-db0f-4d18-a05b-f25380440a9b",
      });

      AuthCheck();
      //   return data.data;
      // })
      // .catch();
    } else {
      // if (data.error === "unauthorized") {

      // dispatch(appendAlert("You have the account!!"));
      // dispatch(appendAlert("Please verify your account through your mail !!"));
      setTimeout(function () {
        changeLoader(false);

        // } else {
        if (state.email === "" || !state.email) {
          changeLoginEmailNull(true);
        }
        if (state.password === "" || !state.password) {
          changeLoginPassNull(true);
        }

        changeAlertEmailLogin(true);
        changeAlertPassLogin(true);

        dispatch(
          appendAlert(
            "The password that you've entered is incorrect. Please try again!","danger"
          )
        );
        //   changeLoader(false);
        // }
      }, 5000);
    }
    // });
  };

  const handleChangePassword = (event) => {
    // $("dropdownMenuButton").dropdown('toggle');
    changePassUpper(false);
    changePassNum(false);
    changePassLength(false);
    changePassSpecial(false);
    let pass = event.target.value;
    const {
      target: { name, value },
    } = event;
    setState({ ...state, [name]: value });
    let x = 1;
    if (checkPasswordLength(pass)) {
      x = x + 1;
    }
    if (checkSpecialCharacters(pass)) {
      x = x + 1;
    }
    if (checkUppercase(pass)) {
      x = x + 1;
    }
    if (checkNumber(pass)) {
      x = x + 1;
    }
    if (pass === "") {
      x = 0;
    }
    setStrength(x);
  };
  const checkPasswordLength = (password) => {
    if (password.length >= 8) {
      changePassLength(true);
      return true;
    }
    return false;
  };
  const check = () => {
    var checkbox = document.getElementById("unitCheck");
    var checkbox1 = document.getElementById("unitCheck1");
    if (rememberPass === false) {
      checkbox.checked = true;
      checkbox1.checked = true;
      changeRememberPass(true);
    } else {
      checkbox.checked = false;
      checkbox1.checked = false;
      changeRememberPass(false);
    }
  };
  // Check for special characters
  const checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g; //eslint-disable-line
    if (pattern.test(password)) {
      changePassSpecial(true);
      return true;
    }
    return false;
  };

  // Check for an uppercase character
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      changePassUpper(true);
      return true;
    }
    return false;
  };

  // Check for a number
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      changePassNum(true);
      return true;
    }
    return false;
  };
  async function onchange(e) {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
    if (name === "email") {
      changeLoginEmailNull(false);
      changeAlertEmailLogin(false);
      changeAlertPassLogin(false);
    }
    if (name === "password") {
      changeLoginPassNull(false);
      changeAlertPassLogin(false);
      changeAlertEmailLogin(false);
    }
  }
  async function onchangeCheck(e) {
    const {
      target: { name, checked },
    } = e;
    await setState({ ...state, [name]: checked });
  }
  function handleContainer() {
    let classes = "";
    classes +=
      props.location.pathname === "/login"
        ? "container"
        : props.location.pathname === "/signup"
        ? "container right-panel-active"
        : sign
        ? "container"
        : !sign
        ? "container right-panel-active"
        : "container";
    return classes;
  }
  return (
    <>
      <div className="loginModal">
        <div className={handleContainer()} id="container">
          <div className="form-container sign-up-container">
            <div
              action="/login"
              className="dropup formlogin"
              style={{ display: "block" }}
            >
              <h1 className="loginHead">Create Account</h1>
              <div className="social-container ">
                <a
                  href={url + "oauth2/authorization/facebook"}
                  className="social"
                  style={{ textDecoration: "none" }}
                >
                  <i
                    className="fa fa-facebook text-dark"
                    aria-hidden="true"
                  ></i>{" "}
                </a>
                <a
                  href={url + "oauth2/authorization/google"}
                  className="social"
                  style={{ textDecoration: "none" }}
                >
                  <i className="fa fa-google text-dark" aria-hidden="true"></i>{" "}
                </a>
                {/* <a href="/login" className="social" style={{ textDecoration:"none" }} ><i className="fa fa-linkedin" aria-hidden="true"></i> </a> */}
              </div>
              <span>or use your email for registration</span>

              <input
                className="tag"
                type="email"
                placeholder="Email"
                name="signUpEmail"
                value={state.signUpEmail}
                onChange={onchange}
                onKeyPress={onKeyUp1}
              />
              {/* <span className="spanvalidate textPri">Email is Required</span> */}
              <input
                className="tag "
                type="password"
                placeholder="Password"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                name="signUpPass"
                value={state.signUpPass}
                onChange={handleChangePassword}
                onKeyPress={onKeyUp1}
                // aria-expanded="false"
              />

              {/* <span className="spanvalidate textPri">Email is Required</span> */}
              <div
                className="dropdown-menu p-0 "
                aria-labelledby="dropdownMenuButton"
              >
                <div className=" passDropHead p-1 ">
                  {" "}
                  <span className="pl-2 ">
                    <b>Rules</b>
                  </span>
                </div>
                <div className=" passDropContent p-2 pr-3">
                  <span>Your password should contains,</span>
                  <ul style={{ listStyleType: "none", marginLeft: "-20px" }}>
                    <li>
                      {passLength ? (
                        <i
                          className="fa fa-check-circle-o text-success"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-times-circle-o text-danger"
                          aria-hidden="true"
                        ></i>
                      )}
                      &nbsp;&nbsp;<span>At least 8 characters</span>
                    </li>
                    <li>
                      {passUpper ? (
                        <i
                          className="fa fa-check-circle-o text-success"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-times-circle-o text-danger"
                          aria-hidden="true"
                        ></i>
                      )}
                      &nbsp;&nbsp;<span>At least 1 upper case letter</span>
                    </li>
                    <li>
                      {passNum ? (
                        <i
                          className="fa fa-check-circle-o text-success"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-times-circle-o text-danger"
                          aria-hidden="true"
                        ></i>
                      )}
                      &nbsp;&nbsp;<span>At least 1 number</span>
                    </li>
                    <li>
                      {passSpecial ? (
                        <i
                          className="fa fa-check-circle-o text-success"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-times-circle-o text-danger"
                          aria-hidden="true"
                        ></i>
                      )}
                      &nbsp;&nbsp; <span>At least 1 special character</span>
                    </li>
                  </ul>
                </div>
                <span className="pl-3">
                  <b>strength:</b>&nbsp;&nbsp;
                  {strength === 1
                    ? "Very Bad"
                    : strength === 2
                    ? "Bad"
                    : strength === 3
                    ? "OK"
                    : strength === 4
                    ? "Good"
                    : strength === 5
                    ? "Strong"
                    : ""}
                </span>
              </div>
              {strength === 1 ? (
                <div className="row pr-3 pl-3 pb-2">
                  <div className="col text-center p-0">
                    <div className="progress " style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{ width: "20%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              ) : strength === 2 ? (
                <div className="row pr-3 pl-3 pb-2">
                  <div className="col text-center p-0">
                    <div className="progress " style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: "40%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              ) : strength === 3 ? (
                <div className="row pr-3 pl-3 pb-2">
                  <div className="col text-center p-0">
                    <div className="progress " style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-info"
                        role="progressbar"
                        style={{ width: "60%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              ) : strength === 4 ? (
                <div className="row pr-3 pl-3 pb-2">
                  <div className="col text-center p-0">
                    <div className="progress " style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-warning"
                        role="progressbar"
                        style={{ width: "80%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              ) : strength === 5 ? (
                <div className="row pr-3 pl-3 pb-2">
                  <div className="col text-center p-0">
                    <div className="progress " style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row pr-3 pl-3 pb-2">
                  <div className="col text-center p-0">
                    <div className="progress " style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-light"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              )}
              <input
                className="tag"
                type="password"
                placeholder="Confirm Password"
                name="signUpConfirmPass"
                value={state.signUpConfirmPass}
                onChange={onchange}
                onKeyPress={onKeyUp1}
              />
              {/* <span className="spanvalidate">Email is Required</span> */}
              <div className="container-fluid pt-2 ticTerm">
                <div className="row">
                  <div className="col-7 d-flex justify-items-start">
                    <input
                      className="toggle mr-2 mt-2"
                      type="checkbox"
                      id="exampleCheck1"
                      name="agree"
                      checked={state.agree ? "checked" : ""}
                      onChange={onchangeCheck}
                    />
                    <span className="" htmlFor="exampleCheck1">
                      I agree to the{" "}
                      <span className="term">terms of service</span>
                    </span>
                  </div>
                  <div className="col-5 text-right ">
                    <label className="switch ">
                      <input
                        type="checkbox"
                        id="togBtn "
                        name="role"
                        checked={state.role ? "checked" : ""}
                        onChange={onchangeCheck}
                      />
                      <div className="slider round">
                        <span className="on1">
                          <b>LandLoad</b>
                        </span>
                        <span className="off1">
                          <b>Tenant</b>
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="container-fluid pt-2 ticTerm1">
                <div className="row">
                  <div className="col d-flex justify-items-start">
                    <input
                      className="toggle mr-2 mt-2"
                      type="checkbox"
                      id="exampleCheck1"
                      name="agree"
                      checked={state.agree ? "checked" : ""}
                      onChange={onchangeCheck}
                    />
                    <span className="pt-2" htmlFor="exampleCheck1">
                      I agree to the{" "}
                      <span className="term">terms of service</span>
                    </span>
                  </div>
                </div>

                <div className="row text-center pt-2">
                  <div className="col  ">
                    <label className="switch ">
                      <input
                        type="checkbox"
                        id="togBtn "
                        name="role"
                        checked={state.role ? "checked" : ""}
                        onChange={onchangeCheck}
                      />
                      <div className="slider round">
                        <span className="on">
                          <b>LandLoad</b>
                        </span>
                        <span className="off">
                          <b>Tenant</b>
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <button
                className={!loader ? "buttonPri" : "buttonPri buttonDisabled"}
                disabled={loader && "disabled"}
                onClick={() => signUpSubmit()}
              >
                Sign Up
              </button>
              {loader && (
                <div style={{}}>
                  <Loader3 />
                </div>
              )}
            </div>
          </div>
          <div className="form-container sign-in-container">
            <div action="/login" className="formlogin">
              <h1 className="loginHead">Sign in</h1>
              <div className="social-container">
                <a
                  href={url + "oauth2/authorization/facebook"}
                  className="social"
                  style={{ textDecoration: "none" }}
                >
                  <i
                    className="fa fa-facebook text-dark"
                    aria-hidden="true"
                  ></i>{" "}
                </a>
                <a
                  href={url + "oauth2/authorization/google"}
                  className="social"
                  style={{ textDecoration: "none" }}
                >
                  <i className="fa fa-google text-dark" aria-hidden="true"></i>{" "}
                </a>
                {/* <a href="/login" className="social" style={{ textDecoration:"none" }}><i className="fa fa-linkedin" aria-hidden="true"></i> </a> */}
              </div>
              <span>or use your account</span>
              <input
                className={alertEmailLogin ? "tag alert" : "tag"}
                type="email"
                name="email"
                placeholder="Email"
                value={state.email}
                onChange={onchange}
                onKeyPress={onKeyUp}
              />
              {loginEmailNull ? (
                <span className="spanvalidate text-danger">
                  {"Email is Required"}
                </span>
              ) : (
                <span className="spanvalidate text-danger">
                  {alertEmailLogin && "Email is not valid"}
                </span>
              )}
              <input
                className={alertPassLogin ? "tag alert" : "tag"}
                type="password"
                name="password"
                placeholder="Password"
                value={state.password}
                onChange={onchange}
                onKeyPress={onKeyUp}
              />
              {loginPassNull ? (
                <span className="spanvalidate text-danger">
                  {"Password is required"}
                </span>
              ) : (
                <span className="spanvalidate text-danger">
                  {alertPassLogin && "Password is not valid"}
                </span>
              )}
              <div
                className="custom-control custom-checkbox item-left"
                onClick={() => check()}
              >
                <input
                  type="checkbox"
                  className="custom-control-input listCheck"
                  id="unitCheck"
                  style={{ background: "#000" }}
                />
                <label
                  className="custom-control-label span2 pt-1"
                  htmlFor="customCheck1"
                >
                  Remember Password
                </label>
              </div>
              <span
                style={{ textDecoration: "none" }}
                className="text-dark pt-2 pb-3 pointer"
                onClick={() => {
                  state.email
                    ? dispatch(appendAlert("New password is sent to your mail","info"))
                    : dispatch(
                        appendAlert(
                          "Enter your username to reset your password.","warning"
                        )
                      );
                }}
              >
                Forgot your password?
              </span>
              <button
                className={!loader ? "buttonPri" : "buttonPri buttonDisabled"}
                disabled={loader && "disabled"}
                onClick={() => signInSubmit()}
              >
                Sign In
              </button>
              {loader && (
                <div style={{}}>
                  <Loader3 />
                </div>
              )}
            </div>
          </div>
          <div className="overlay-container">
            <div className="overlay bgPriRad">
              <div className="overlay-panel overlay-left">
                <img className="logo logoSignIn" src={logo} alt="" />
                <br></br>

                <h1 className="loginHead">Welcome Back!</h1>
                <p>
                  To keep connected with us please login with your personal info
                </p>
                <button className="ghost" id="signIn" onClick={() => signIn()}>
                  Sign In
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <i
                  className="fa fa-times loginModalClose  pointer"
                  style={{
                    float: "right",
                    justifyContent: "right",
                    alignItems: "right",
                  }}
                  onClick={() => close()}
                ></i>
                <img className="logo logoSignIn" src={logo} alt="" />
                <br></br>
                <h1 className="loginHead">Hello, Friend!</h1>
                <p>Enter your personal details and start journey with us</p>
                <button
                  className="ghost"
                  id="signUp"
                  onClick={() => signUpFun()}
                  // onClick={() =>
                  //    dispatch(appendAlert(
                  //     "Currently you can't register the new user."
                  //   ))
                  // }
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="loginModal-mobile ">
        <i
          className="fa fa-times loginModalClose pointer"
          style={{
            float: "right",
            justifyContent: "right",
            alignItems: "right",
          }}
          onClick={() => close()}
        ></i>
        <div className="container-mobile " id="container">
          {sign ? (
            <div className="form-container sign-in-container-mobile ">
              <div
                action="/login"
                className="dropup formlogin"
                style={{ display: "block" }}
              >
                <h1 className="loginHead">Sign in</h1>
                <div className="social-container">
                  <a
                    href={url + "oauth2/authorization/facebook"}
                    className="social"
                    style={{ textDecoration: "none" }}
                  >
                    <i
                      className="fa fa-facebook text-dark"
                      aria-hidden="true"
                    ></i>{" "}
                  </a>
                  <a
                    href={url + "oauth2/authorization/google"}
                    className="social"
                    style={{ textDecoration: "none" }}
                  >
                    <i
                      className="fa fa-google text-dark"
                      aria-hidden="true"
                    ></i>{" "}
                  </a>
                </div>
                <span className="span2">or use your account</span>
                <input
                  className={alertEmailLogin ? "tag alert" : "tag"}
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={state.email}
                  onChange={onchange}
                  onKeyPress={onKeyUp}
                />

                {loginEmailNull ? (
                  <span className="spanvalidate text-danger">
                    {"Email is Required"}
                  </span>
                ) : (
                  <span className="spanvalidate text-danger">
                    {alertEmailLogin && "Email is not valid"}
                  </span>
                )}
                <input
                  className={alertPassLogin ? "tag alert" : "tag"}
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={onchange}
                  onKeyPress={onKeyUp}
                  value={state.password}
                />
                {loginPassNull ? (
                  <span className="spanvalidate text-danger">
                    {"Password is required"}
                  </span>
                ) : (
                  <span className="spanvalidate text-danger">
                    {alertPassLogin && "Password is not valid"}
                  </span>
                )}
                <br></br>
                <div
                  className="custom-control custom-checkbox item-left"
                  onClick={() => check()}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input listCheck"
                    id="unitCheck1"
                    style={{ background: "#000" }}
                  />
                  <label
                    className="custom-control-label span3 pt-1"
                    htmlFor="customCheck1"
                  >
                    Remember Password
                  </label>
                </div>
                <br></br>

                <span
                  style={{ textDecoration: "none" }}
                  className="text-dark mt-5 pb-3 pointer span2"
                  onClick={() => {
                    state.email
                      ? dispatch(
                          appendAlert("New password is sent to your mail")
                        )
                      : dispatch(
                          appendAlert(
                            "Enter your username to reset your password.","info"
                          )
                        );
                  }}
                >
                  Forgot your password?
                </span>
                <br></br>
                <br></br>

                <button
                  className={!loader ? "buttonPri" : "buttonPri buttonDisabled"}
                  disabled={loader && "disabled"}
                  onClick={() => signInSubmit()}
                >
                  Sign In
                </button>
                {loader && (
                  <div style={{}}>
                    <Loader3 />
                  </div>
                )}
                <br></br>
                <br></br>
                <span
                  style={{ textDecoration: "none" }}
                  className="text-dark pt-2 pb-3 pointer span2"
                >
                  You don't have account?
                </span>
                <br></br>
                <br></br>
                <button
                  className="buttonPri"
                  id="signUp"
                  onClick={() => signUpFun()}
                  // onClick={() =>
                  //    dispatch(appendAlert(
                  //     "Currently you can't register the new user."
                  //   ))
                  // }
                >
                  Sign Up
                </button>
              </div>
            </div>
          ) : (
            <div className="form-container sign-up-container-mobile pt-3">
              <div
                action="/login"
                className="dropup formlogin"
                style={{ display: "block" }}
              >
                <h1 className="loginHead">Create Account</h1>
                <div className="social-container ">
                  <a
                    href={url + "oauth2/authorization/facebook"}
                    className="social"
                    style={{ textDecoration: "none" }}
                  >
                    <i
                      className="fa fa-facebook text-dark"
                      aria-hidden="true"
                    ></i>{" "}
                  </a>
                  <a
                    href={url + "oauth2/authorization/google"}
                    className="social"
                    style={{ textDecoration: "none" }}
                  >
                    <i
                      className="fa fa-google text-dark"
                      aria-hidden="true"
                    ></i>{" "}
                  </a>
                  {/* <a href="/login" className="social" style={{ textDecoration:"none" }} ><i className="fa fa-linkedin" aria-hidden="true"></i> </a> */}
                </div>
                <span className="span3">
                  or use your email for registration
                </span>

                <input
                  className=" tag"
                  type="email"
                  placeholder="Email"
                  name="signUpEmail"
                  value={state.signUpEmail}
                  onChange={onchange}
                  onKeyPress={onKeyUp1}
                />
                {loginEmailNull ? (
                  <span className="spanvalidate text-danger">
                    {"Email is Required"}
                  </span>
                ) : (
                  <span className="spanvalidate text-danger">
                    {alertEmailLogin && "Email is not valid"}
                  </span>
                )}
                <input
                  className=" tag"
                  type="password"
                  placeholder="Password"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  name="signUpPass"
                  value={state.signUpPass}
                  onChange={handleChangePassword}
                  onKeyPress={onKeyUp1}
                  // aria-expanded="false"
                />
                {loginPassNull ? (
                  <span className="spanvalidate text-danger">
                    {"Password is required"}
                  </span>
                ) : (
                  <span className="spanvalidate text-danger">
                    {alertPassLogin && "Password is not valid"}
                  </span>
                )}
                <div
                  className="dropdown-menu p-0 "
                  aria-labelledby="dropdownMenuButton"
                >
                  <div className=" passDropHead p-1 ">
                    {" "}
                    <span className="pl-2 ">
                      <b>Rules</b>
                    </span>
                  </div>
                  <div className=" passDropContent p-2 pr-3">
                    <span className="span3">
                      Your password should contains,
                    </span>
                    <ul style={{ listStyleType: "none", marginLeft: "-20px" }}>
                      <li>
                        {passLength ? (
                          <i
                            className="fa fa-check-circle-o text-success"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle-o text-danger"
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;&nbsp;<span>At least 8 characters</span>
                      </li>
                      <li>
                        {passUpper ? (
                          <i
                            className="fa fa-check-circle-o text-success"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle-o text-danger"
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;&nbsp;<span>At least 1 upper case letter</span>
                      </li>
                      <li>
                        {passNum ? (
                          <i
                            className="fa fa-check-circle-o text-success"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle-o text-danger"
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;&nbsp;<span>At least 1 number</span>
                      </li>
                      <li>
                        {passSpecial ? (
                          <i
                            className="fa fa-check-circle-o text-success"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle-o text-danger"
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;&nbsp;<span>At least 1 special character</span>
                      </li>
                    </ul>
                  </div>
                  <span className="pl-3">
                    <b>strength:</b>&nbsp;&nbsp;
                    {strength === 1
                      ? "Very Bad"
                      : strength === 2
                      ? "Bad"
                      : strength === 3
                      ? "OK"
                      : strength === 4
                      ? "Good"
                      : strength === 5
                      ? "Strong"
                      : ""}
                  </span>
                </div>
                {strength === 1 ? (
                  <div className="row pr-3 pl-3 pb-2">
                    <div className="col text-center p-0">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "20%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : strength === 2 ? (
                  <div className="row pr-3 pl-3 pb-2">
                    <div className="col text-center p-0">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "40%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : strength === 3 ? (
                  <div className="row pr-3 pl-3 pb-2">
                    <div className="col text-center p-0">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{ width: "60%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : strength === 4 ? (
                  <div className="row pr-3 pl-3 pb-2">
                    <div className="col text-center p-0">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{ width: "80%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : strength === 5 ? (
                  <div className="row pr-3 pl-3 pb-2">
                    <div className="col text-center p-0">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "100%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row pr-3 pl-3 pb-2">
                    <div className="col text-center p-0">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-light"
                          role="progressbar"
                          style={{ width: "100%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
                <input
                  className="tag"
                  type="password"
                  placeholder="Confirm Password"
                  name="signUpConfirmPass"
                  value={state.signUpConfirmPass}
                  onChange={onchange}
                  onKeyPress={onKeyUp1}
                />
                {loginPassNull ? (
                  <span className="spanvalidate text-danger">
                    {"Password is required"}
                  </span>
                ) : (
                  <span className="spanvalidate text-danger">
                    {alertPassLogin && "Password is not valid"}
                  </span>
                )}
                <div className="container-fluid pt-2 ticTerm">
                  <div className="row">
                    <div className="col-7 d-flex justify-items-start">
                      <input
                        className="toggle mr-2 mt-2"
                        type="checkbox"
                        id="exampleCheck1"
                        name="agree"
                        checked={state.agree ? "checked" : ""}
                        onChange={onchangeCheck}
                      />
                      <span className="" htmlFor="exampleCheck1">
                        I agree to the{" "}
                        <span className="term">terms of service</span>
                      </span>
                    </div>
                    <div className="col-5 text-right ">
                      <label className="switch ">
                        <input
                          type="checkbox"
                          id="togBtn "
                          name="role"
                          checked={state.role ? "checked" : ""}
                          onChange={onchangeCheck}
                        />
                        <div className="slider round">
                          <span className="on">
                            <b>LandLoad</b>
                          </span>
                          <span className="off">
                            <b>Tenant</b>
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="container-fluid pt-2 ticTerm1">
                  <div className="row">
                    <div className="col d-flex justify-items-start">
                      <input
                        className="toggle mr-2 mt-2"
                        type="checkbox"
                        id="exampleCheck1"
                        name="agree"
                        checked={state.agree ? "checked" : ""}
                        onChange={onchangeCheck}
                      />
                      <span className="pt-2" htmlFor="exampleCheck1">
                        I agree to the{" "}
                        <span className="term">terms of service</span>
                      </span>
                    </div>
                  </div>

                  <div className="row text-center pt-2">
                    <div className="col  ">
                      <label className="switch ">
                        <input
                          type="checkbox"
                          id="togBtn "
                          name="role"
                          checked={state.role ? "checked" : ""}
                          onChange={onchangeCheck}
                        />
                        <div className="slider round">
                          <span className="on">
                            <b>LandLoad</b>
                          </span>
                          <span className="off">
                            <b>Tenant</b>
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <br></br>
                <br></br>

                <button
                  className={!loader ? "buttonPri" : "buttonPri buttonDisabled"}
                  disabled={loader && "disabled"}
                  onClick={() => signUpSubmit()}
                >
                  Sign Up
                </button>
                {loader && (
                  <div style={{}}>
                    <Loader3 />
                  </div>
                )}
                <br></br>
                <br></br>
                <span
                  style={{ textDecoration: "none" }}
                  className="text-dark pt-2 pb-3 pointer span3"
                >
                  Do you already have account?
                </span>
                <br></br>
                <br></br>
                <button
                  className="buttonPri mt-3 mb-5"
                  id="signIn"
                  onClick={() => signIn()}
                >
                  Sign In
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="container-fluid">{/* <Footer /> */}</div>
    </>
  );
}

export default withRouter(Login);
