class localStorageService {
  ls = window.localStorage;

  setItem(key, value) {
    value = JSON.stringify(value);
    this.ls.setItem(key, value);
    return true;
  }

  getItem(key) {
    // alert("first");
    let value = this.ls.getItem(key);
    // alert(value)
    // return JSON.parse(value)
    try {
      return JSON.parse(value);
    } catch (e) {
      return e;
    }
  }
}

export default new localStorageService();
