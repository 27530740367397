import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import "./noticeBoard.css";

function NoticeBoard() {
  // const dark = useSelector((state) => state.isDark);
  const [id, setId] = useState(1);
  const [close, setClose] = useState(true);
  var random_margin = ["-5px", "1px", "5px", "10px", "7px"];
  var random_colors = [
    "#c2ff3d",
    "#ff3de8",
    "#3dc2ff",
    "#04e022",
    "#bc83e6",
    "#ebb328",
  ];
  var random_degree = [
    "rotate(3deg)",
    "rotate(1deg)",
    "rotate(-1deg)",
    "rotate(-3deg)",
    "rotate(-5deg)",
    "rotate(-8deg)",
  ];
  var index = 0;
  useEffect(() => {
    document.querySelector("#add_note").addEventListener("click", () => {
      document.querySelector("#modal").style.display = "block";
    });

    document.querySelector("#hide").addEventListener("click", () => {
      document.querySelector("#modal").style.display = "none";
    });

    // document.querySelector("#user_input").addEventListener("keydown", (event) => {
    //   if (event.key === "Enter") {
    //     const text = document.querySelector("#user_input");
    //     createStickyNote(text.value);
    //   }
    // });
    // eslint-disable-next-line
  }, []);
  const done = () => {
    const text = document.querySelector("#user_input");
    createStickyNote(text.value);
  };
  const createStickyNote = (text) => {
    let note = document.createElement("div");
    let details = document.createElement("div");
    let noteText = document.createElement("p");
    let noteClose = document.createElement("i");
    noteClose.className = "fa fa-times noteClose ";
    noteClose.id = "noteClose" + id;
    note.className = "note";
    note.id = "note" + id;
    noteClose.onclick = function () {
      document.getElementById("note" + id).remove();
    };
    setId(id + 1);
    details.className = "details";
    noteText.textContent = text;

    details.appendChild(noteText);
    note.appendChild(noteClose);
    note.appendChild(details);

    if (index > random_colors.length - 1) index = 0;

    note.setAttribute(
      "style",
      `margin:${
        random_margin[Math.floor(Math.random() * random_margin.length)]
      }; background-color:${random_colors[id % 6]}; transform:${
        random_degree[Math.floor(Math.random() * random_degree.length)]
      }`
    );

    note.addEventListener("dblclick", () => {
      note.remove();
    });

    document.querySelector("#all_notes").appendChild(note);
  };
  const closeNotice = () => {
    setClose(!close);
    if (close) {
      document.getElementById("all_notes").style.display = "none";
      var element = document.getElementById("noticeBoard");
      var element3 = document.getElementById("container-calender0");
      element.classList.add("noticeClose");
      element3.classList.add("calClose");
      document.getElementById("noticeBoard").style.height = "70px";
      document.getElementById("container-calender0").style.height = "70px";
      document.getElementById("add_note").style.display = "none";
      document.getElementById("container-calender").style.display = "none";
    } else {
      var element1 = document.getElementById("noticeBoard");
      var element4 = document.getElementById("container-calender0");
      element1.classList.remove("noticeClose");
      element4.classList.remove("calClose");
      element1.classList.add("noticeOpen1");
      element4.classList.add("calOpen");
      document.getElementById("noticeBoard").style.height = "490px";
      document.getElementById("container-calender0").style.height = "auto";
      setTimeout(function () {
        document.getElementById("all_notes").style.display = "flex";
        document.getElementById("add_note").style.display = "block";
        document.getElementById("container-calender").style.display = "block";
      }, 2000);
    }
  };
  return (
    <div id="noticeBoard">
      <div id="modal">
        <div id="inner_modal">
          <textarea
            placeholder="Note..."
            id="user_input"
            maxLength="160"
          ></textarea>
          <i className="fa fa-check-circle icon" onClick={() => done()}></i>
          <i className="fa fa-times-circle " id="hide"></i>
        </div>
      </div>

      <main>
        <header>
          <div className="containerNotice">
            <div id="header">
              <h1 id="headerNotice">
                <i className="fa fa-sticky-note"></i>&nbsp;
                <div className="headerNotice"> Sticky Board</div>
              </h1>
              <div
                className={close ? "outer pt-2" : "outer cl mt-2 "}
                onClick={closeNotice}
              >
                <div className={close ? "inner pt-1" : "inner cl pt-1"}>
                  <label className="noticeOpen">Open</label>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section>
          <div className="containerNotice">
            <div id="all_notes"></div>
          </div>
        </section>
      </main>
      <button id="add_note" className="">
        <i className="fa fa-plus" aria-hidden="true"></i> Note
      </button>
    </div>
  );
}

export default NoticeBoard;
