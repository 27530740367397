import React from "react";
import "./navbar.css";
import { useDispatch, useSelector } from "react-redux";
import Login from "../login/Login";
import profileImage from "../../images/profile.jpeg";
import JwtAuthService from "../../services/JwtAuthService";
import localStorageService from "../../services/localStorageService";
import logo from "../../images/LOGO.png";
import logoWhite from "../../images/LOGOWhite.png";
import {
  authorize,
  changeRole,
  changeRoleAdministrator,
  changeRoleGuest,
  changeRoleInstructor,
  changeRoleLearner,
  setUser,
  unAuthorize,
} from "../../actions";
import { cutText } from "../../common/functions";
import { withRouter } from "react-router-dom";

const Header = (props) => {
  const dark = useSelector((state) => state.isDark);
  const auth = useSelector((state) => state.isAuth);
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);
  const dispatch = useDispatch();
  const logout = () => {
    JwtAuthService.logout();
    AuthCheck();
  };
  const AuthCheck = () => {
    var UserData = localStorageService.getItem("auth_user");
    var roleNo = localStorageService.getItem("role");
    dispatch(setUser(UserData));
    if (UserData && roleNo) {
      dispatch(authorize());
      dispatch(changeRole(roleNo));
    } else {
      dispatch(unAuthorize());
      dispatch(changeRoleGuest());
    }
  };
  return (
    <nav className="navbar-inner navHeader">
      <div className="">
        <ul className="nav pull-left logoTitle">
          <li
            onClick={() => {
              props.history.push("");
            }}
          >
            <img
              className="logo"
              src={dark ? logoWhite : logo}
              alt=""
              style={{ zIndex: "1000" }}
            />
          </li>
        </ul>
        <ul className="nav pull-center"></ul>
        <ul className="nav pull-right pr-0 loginLay">
          <li className="titleTag typewriter">
            <h1 className={dark ? "titleTextDark" : "titleText"}>
              THE WORLD'S LARGEST LEARNING SITE
            </h1>
          </li>
          <li className=" pt-3 pr-3">
            {auth ? (
              <>
                <div
                  className={dark ? "pt-1 pointer text-white" : "pt-1 pointer"}
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                >
                  <span className="pr-3 headUserName">
                    {user.username && user.username}
                  </span>
                  <span
                    className={
                      role === "Learner"
                        ? "badge absolute d-flex justify-content-start budgeRole budgeRoleYellow"
                        : role === "Instructor"
                        ? "badge absolute d-flex justify-content-start budgeRole budgeRoleGreen"
                        : role === "Administrator"
                        ? "badge absolute d-flex justify-content-start budgeRole budgeRoleGray"
                        : " "
                    }
                  >
                    {role === "Learner" ? (
                      <>
                        {" "}
                        Learner &nbsp;&nbsp;{" "}
                        <i className="fa fa-leanpub" aria-hidden="true"></i>
                      </>
                    ) : role === "Instructor" ? (
                      <>
                        Instructor &nbsp;&nbsp;{" "}
                        <i
                          className="fa fa-graduation-cap"
                          aria-hidden="true"
                        ></i>
                      </>
                    ) : role === "Administrator" ? (
                      <>
                        {" "}
                        Administrator &nbsp;&nbsp;{" "}
                        <i className="fa fa-user-secret" aria-hidden="true"></i>
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                  <img
                    src={profileImage}
                    className="avatar"
                    alt="profile"
                  ></img>
                  <i
                    className="fa fa-caret-down pl-2 pt-1"
                    // aria-hidden="true"
                  ></i>
                </div>
                <div
                  className="dropdown-menu p-1 "
                  aria-labelledby="dropdownMenu2"
                  style={{
                    marginTop: "20px",
                    marginRight: "20px",
                    width: "250px",
                    right: "0px",
                    float: "left",
                  }}
                >
                  <span
                    onClick={() => props.history.push({ pathname: "/profile" })}
                    className="dropdown-item  authMenu"
                  >
                    <i className="fa fa-user-circle" aria-hidden="true"></i>{" "}
                    &nbsp;Profile
                  </span>
                  <span className="dropdown-item authMenu " onClick={logout}>
                    <i className="fa fa-sign-out" aria-hidden="true"></i>{" "}
                    &nbsp;Sign out
                  </span>
                  <span
                    onClick={() => props.history.push({ pathname: "/profile" })}
                    className="dropdown-header "
                  >
                    {cutText(
                      user.firstName
                        ? user.firstName + " " + user.lastName
                        : user.username,
                      30
                    )}
                  </span>
                  <span className=" p-0 m-0   ">
                    <div className="form-group col-12 p-0 m-0">
                      <label htmlFor="title" className="pl-4 pt-1 pb-0">
                        Role
                      </label>
                      <div
                        className="input-group authMenu  pl-4 pt-1 pb-0"
                        onClick={() => {
                          dispatch(changeRoleLearner());
                          window.$("#dropdownMenu2").dropdown("toggle");
                        }}
                      >
                        <div className="radio-item">
                          <input
                            type="radio"
                            className="away"
                            id="learnerRadio"
                            name="learnerRadio"
                            checked={role === "Learner" && "checked"}
                          />
                          <label htmlFor="learnerRadio" className="pointer">&nbsp; Learner</label>
                        </div>
                      </div>
                      <div
                        className="input-group authMenu  pl-4 pt-1 pb-0"
                        onClick={() => {
                          dispatch(changeRoleInstructor());
                          window.$("#dropdownMenu2").dropdown("toggle");
                        }}
                      >
                        <div className="radio-item">
                          <input
                            type="radio"
                            className="active"
                            id="instructorRadio"
                            name="learnerRadio"
                            checked={role === "Instructor" && "checked"}
                          />
                          <label htmlFor="instructorRadio" className="pointer">
                            &nbsp; Instructor
                          </label>
                        </div>
                      </div>
                      <div
                        className="input-group authMenu  pl-4 pt-1 pb-0"
                        onClick={() => {
                          dispatch(changeRoleAdministrator());
                          window.$("#dropdownMenu2").dropdown("toggle");
                        }}
                      >
                        <div className="radio-item">
                          <input
                            type="radio"
                            className="inactive"
                            id="administratorRadio"
                            name="learnerRadio"
                            checked={role === "Administrator" && "checked"}
                          />
                          <label htmlFor="administratorRadio" className="pointer">
                            &nbsp; Administrator
                          </label>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </>
            ) : (
              (props.location.pathname !== "/signup" && 
              props.location.pathname !== "/login"&&
              props.location.pathname !== "/dashboard" &&
              props.location.pathname !== "/createContent" &&
              props.location.pathname !== "/addQuiz" &&
              props.location.pathname !== "/addQuestion"
              )&&
              <button
                className=" buttonPri loginBtn"
                type="button"
                data-toggle="modal"
                data-target="#loginModal"
              >
                Login
              </button>
            )}
          </li>
        </ul>
      </div>
      <div
        className="modal fade"
        id="loginModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg mt-0 pt-0 " role="document">
          <div className="modal-content bg-transparent p-0 m-0 border-0">
            <Login />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Header);
