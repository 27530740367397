import React from "react";
import "./loader3.css";

const Loader3 = (props) => {
  const styleLoader={
    minWidth:props.width,

  }
  return (
    <div className={props.theme==="white"?"loader3WhiteHead":"loader3White"} style={ styleLoader }>
      <div className={props.theme==="white"?"loader3White":"loader3"}>Loading...</div>
    </div>
  );
};
export default Loader3;
