import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import defaultImage from "../../../images/defaultImage.jpg";

import "./quiz.css";
import { withRouter } from "react-router-dom";
import ReviewMatching from "./ReviewAnswers/ReviewMatching";
import ReviewCheckBox from "./ReviewAnswers/ReviewCheckBox";
import ReviewRadioBox from "./ReviewAnswers/ReviewRadioBox";
import { DndProvider } from "react-dnd";
import DragDrop from "./ReviewAnswers/MatchingReview.js/DragDrop";
import { HTML5Backend } from "react-dnd-html5-backend";
import { sampleQuestion1 } from "../../../BavarTest";

function MarkingQuestion(props) {
  const dark = useSelector((state) => state.isDark);
 // eslint-disable-next-line
  const [question,setQuestion] = useState(props.location.state.question);
  // eslint-disable-next-line
  const [markingQuestion, setMarkingQuestion] = useState(sampleQuestion1);
  // eslint-disable-next-line
  const quiz = props.location.state.quiz;

  const updateItem = (id, whichValue, newValue) => {
    // var index = question.findIndex((x) => x.questionNo === id);
    // let g = question[index];
    // g[whichValue] = newValue;
    // if (index !== -1) {
    //   setQuestion([
    //     ...question.slice(0, index),
    //     g,
    //     ...question.slice(index + 1),
    //   ]);
    // }
  };
  const checkAnswer=(answer1,answer2,type)=>{
    var x=true;
    if(type==="fillBlanks"){
      for (let index = 0; index < answer1.length; index++) {
        if(answer1[index].text!==answer2[index].text){
          x=false;
          break;
        }
      }
    }else if(type==="matching"){
      for (let index = 0; index < answer1.length; index++) {
        if(answer1[index].value!==answer2[index].text[1]){
          x=false;
          break;
        }
      }
    }else{
      for (let index = 0; index < answer1.length; index++) {
        if(answer1[index].correct!==answer2[index].correct){
          x=false;
          break;
        }
      }
    }
    return x;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="row p-0 m-0 mt-5  mb-5">
      {question.map((value, index) => (
        <div className="form-group col-12 pt-0 mt-3 ">
          <div
            className={
              dark
                ? ( value.type !== "freeText") ?(checkAnswer((value.type === "fillBlanks"||value.type === "matching")?value.finalAnswer:value.answers,markingQuestion[index].answers,value.type)?"previewQuestionDark2Correct pr-4 pl-4 pt-0 pb-1 mt-0":"previewQuestionDark2Wrong pr-4 pl-4 pt-0 pb-1 mt-0"):"previewQuestionDark2 pr-4 pl-4 pt-0 pb-1 mt-0"
                : ( value.type !== "freeText") ?(checkAnswer((value.type === "fillBlanks"||value.type === "matching")?value.finalAnswer:value.answers,markingQuestion[index].answers,value.type)?"previewQuestion2Correct pr-4 pl-4 pt-0 pb-1 mt-0":"previewQuestion2Wrong pr-4 pl-4 pt-0 pb-1 mt-0"):"previewQuestion2 pr-4 pl-4 pt-0 pb-1 mt-0"
            }
          >
            {value.type !== "matching" && value.type !== "fillBlanks" && (
              <>
                <div
                  id={"questionPreview" + value.questionNo}
                  contentEditable="false"
                  className="questionPreview pb-2 pl-0 ml-0 pt-3"
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  {index + 1}) {value.question}
                </div>
                <div
                  className={
                    value.questionImageAlignment === "center"
                      ? "d-flex justify-content-center"
                      : value.questionImageAlignment === "right"
                      ? "d-flex justify-content-end"
                      : value.questionImageAlignment === "left"
                      ? "d-flex justify-content-start"
                      : ""
                  }
                >
                  {value.questionImage !== "" && (
                    <img
                      id="question_image_preview"
                      alt="question_image_preview"
                      width={
                        value.questionImageAlignment === "justify" && "100%"
                      }
                      height="300px"
                      className="pb-3"
                      src={value.questionImage}
                    />
                  )}
                </div>
              </>
            )}
            {value.type === "freeText" && (
              <>
                <textarea
                  className="answer"
                  style={{ pointerEvents: "none" }}
                  rows="5"
                  name={"freeText" + value.questionNo}
                  // value={state["freeText" + value.questionNo]}
                  onChange={onchange}
                ></textarea>
              </>
            )}
            {value.type === "radioBox" && (
              <ReviewRadioBox value={value} updateItem={updateItem} handle={"on"} realAnswer={markingQuestion[index].answers}
              correct={!checkAnswer(value.answers,markingQuestion[index].answers,value.type)&&"correct"} />
            )}
            {value.type === "checkedBox" && (
              <ReviewCheckBox value={value} updateItem={updateItem} handle={"on"} realAnswer={markingQuestion[index].answers}
              correct={!checkAnswer(value.answers,markingQuestion[index].answers,value.type)&&"correct"} />
            )}
            {value.type === "fillBlanks" && (
              <div className="pt-3 p-2">
                <span
                  className="absolute "
                  style={{
                    marginLeft: "-10px",
                    marginTop: "8px",
                    marginBottom: "5px",
                    fontWeight: "600",
                  }}
                >
                  {index + 1}) {"Fill in the blanks  "}
                </span>
                <br className=""></br>
                <br className=""></br>

                <DndProvider backend={HTML5Backend}>
                  <DragDrop
                    answer={value.answers}
                    questionCheck={
                      value.question.split("<BavarInput>")[0] === ""
                        ? "text"
                        : "textArea"
                    }
                    question={value.question.split("<BavarInput>")}
                    questionNo={value.questionNo}
                    setFillBlankFinal={updateItem}
                    finalAnswer={value.finalAnswer}
                    realAnswer={markingQuestion[index].answers}
                    correct={!checkAnswer(value.finalAnswer,markingQuestion[index].answers,value.type)&&"correct"}
                    handle={"on"}
                  ></DragDrop>
                </DndProvider>
                <br></br>
              </div>
            )}
            {value.type === "matching" && (
              <ReviewMatching
                index={index}
                value={value}
                dark={dark}
                questionNo={value.questionNo}
                setFillBlankFinal={updateItem}
                answers={value.answers}
                finalAnswer={value.finalAnswer}
                realAnswer={markingQuestion[index].answers}
                handle={"on"}
                correct={!checkAnswer(value.finalAnswer,markingQuestion[index].answers,value.type)&&"correct"}
              />
            )}
          </div>
        </div>
      ))}
      <div className="col">
        <button
          className="buttonPri pull-right mt-4 "
          onClick={() =>
            props.history.push({
              pathname: "/markingQuestion",
              state: {
                question: question,
                quiz: quiz,
              },
            })
          }
        >
          Submit
        </button>

      </div>
    </div>
  );
}

export default withRouter(MarkingQuestion);
