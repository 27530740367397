const userReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_USER":
      return action.user;
    case "GET_USER":
      return state;
    case "REMOVE_USER":
      return {};
    default:
      return state;
  }
};
export default userReducer;
