import React from "react";
import "./fill.css";

function AfterDrag({ id,answerNo, board, remove,icon }) {
  const removeChange = () => {
    remove(id,answerNo, board);
  };
  return (
    board.text !== "" && (
      <div class={"badge fillBar pr-1 pl-3 "}>
        <span>
          <label className="pt-1">{board.text} </label>
          {icon!=="remove"&&<button className="fillClose pull-right ml-1 pt-1 " onClick={removeChange}>
            <i class="fa fa-window-close staticFaIcon" aria-hidden="true"></i>
          </button>}
        </span>
      </div>
    )
  );
}

export default AfterDrag;
