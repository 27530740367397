import React, { Component } from "react";
import { randomArrayShuffle } from "../../../../../common/functions";
import Drag from "./Drag";
import Drop from "./Drop";

class DragDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PictureList: randomArrayShuffle(
        this.props.answer.map((value, index) => ({ ...value, id: index + 1 }))
      ),
      board: this.props.answer.map((value, index) => ({
        ...value,
        text: "",
        id: index + 1,
      })),
    };
    this.addImageToBoard = this.addImageToBoard.bind(this);
    this.remove = this.remove.bind(this);
  }
  componentDidMount() {}
  addImageToBoard(id, boardId) {
    const text = this.state.PictureList.filter((res) => res.id === id);
    const text1 = this.state.board.filter((res) => res.id === boardId);

    if (text1[0].text === "") {
      const a1 = this.state.PictureList.map((value) => ({
        ...value,
        text: value.id === id ? "" : value.text,
      }));
      const a2 = this.state.board.map((value) => ({
        ...value,
        text: value.id === boardId ? text[0].text : value.text,
        answerNo: value.id === boardId ? text[0].answerNo : value.answerNo,
      }));
      this.setState({ board: a2, PictureList: a1 });
      this.props.setFillBlankFinal(this.props.questionNo,"finalAnswer",a2);

    } else {
    }
  }
  remove(id,answerNo, board) {
    const array1 = this.state.PictureList.map((value, index) => ({
      ...value,
      text: value.answerNo === answerNo ? board.text : value.text,
    }));
    const array2 = [...this.state.board];
    array2[id - 1].text = "";
    this.setState({ board: array2, PictureList: array1 });
    this.props.setFillBlankFinal(this.props.questionNo,"finalAnswer",array2);
  }

  render() {
    return (
      <div>
        {this.props.handle==="on"?<><div class="row ">
          {this.props.finalAnswer.map((part, index) => {
            return (
              <>
                {index === 0 && (
                  <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                )}
                {this.props.questionCheck === "textArea" &&
                  this.props.question[index].split(" ").map((word) => {
                    return (
                      <span className="m-2 " style={{ lineHeight: "30px" }}>
                        {word}
                      </span>
                    );
                  })}

                {this.props.questionCheck === "text" && (
                  <span className="m-2 " style={{ lineHeight: "30px" }}>
                    {this.props.question[index]}
                  </span>
                )}

                <Drop
                  board={part}
                  addImageToBoard={this.addImageToBoard}
                  remove={this.remove}
                  icon="remove"
                ></Drop>
                {this.state.board.length - 1 === index &&
                  this.props.question[index + 1].split(" ").map((word) => {
                    return (
                      <span className="m-2 " style={{ lineHeight: "30px" }}>
                        {word}
                      </span>
                    );
                  })}
              </>
            );
          })}
        </div>
        { this.props.correct==="correct"&&<>
        <h2>Answer</h2>
          <br/>
 
        <div class="row ">
          
          {this.props.realAnswer.map((part, index) => {
            return (
              <>
                {index === 0 && (
                  <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                )}
                {this.props.questionCheck === "textArea" &&
                  this.props.question[index].split(" ").map((word) => {
                    return (
                      <span className="m-2 " style={{ lineHeight: "30px" }}>
                        {word}
                      </span>
                    );
                  })}

                {this.props.questionCheck === "text" && (
                  <span className="m-2 " style={{ lineHeight: "30px" }}>
                    {this.props.question[index]}
                  </span>
                )}

                <Drop
                  board={part}
                  addImageToBoard={this.addImageToBoard}
                  remove={this.remove}
                  icon="remove"
                ></Drop>
                {this.state.board.length - 1 === index &&
                  this.props.question[index + 1].split(" ").map((word) => {
                    return (
                      <span className="m-2 " style={{ lineHeight: "30px" }}>
                        {word}
                      </span>
                    );
                  })}
              </>
            );
          })}
        </div>
        </>}
        </>:
        <>
        <div class="row ">
          {this.state.board.map((part, index) => {
            return (
              <>
                {index === 0 && (
                  <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                )}
                {this.props.questionCheck === "textArea" &&
                  this.props.question[index].split(" ").map((word) => {
                    return (
                      <span className="m-2 " style={{ lineHeight: "30px" }}>
                        {word}
                      </span>
                    );
                  })}

                {this.props.questionCheck === "text" && (
                  <span className="m-2 " style={{ lineHeight: "30px" }}>
                    {this.props.question[index]}
                  </span>
                )}

                <Drop
                  board={part}
                  addImageToBoard={this.addImageToBoard}
                  remove={this.remove}
                ></Drop>
                {this.state.board.length - 1 === index &&
                  this.props.question[index + 1].split(" ").map((word) => {
                    return (
                      <span className="m-2 " style={{ lineHeight: "30px" }}>
                        {word}
                      </span>
                    );
                  })}
              </>
            );
          })}
        </div>
        <br></br>
        <div className="Pictures">
          {this.state.PictureList.map((picture) => {
            return (
              <Drag
                url={picture.url}
                id={picture.id}
                text={picture.text}
              ></Drag>
            );
          })}
        </div></>
        }
      </div>
    );
  }
}

export default DragDrop;
