import React from "react";
import "../quiz.css";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import defaultImage from "../../../../images/defaultImage.jpg";

function ReviewRadioBox(props) {
  const dark = useSelector((state) => state.isDark);
  async function onChangeCheck(e) {
    const {
      target: { id },
    } = e;
    updateMulAnswerItem(id, "correct", true);
  }
  const updateMulAnswerItem = (id, whichValue, newValue) => {
    if (whichValue === "correct") {
      for (const obj of props.value.answers) {
        if (parseInt(obj.answerNo) !== parseInt(id)) {
          var index = props.value.answers.findIndex(
            (x) => x.answerNo === obj.answerNo
          );
          let g1 = props.value.answers[index];
          g1[whichValue] = false;
          if (index !== -1) {
            const a = [
              ...props.value.answers.slice(0, index),
              g1,
              ...props.value.answers.slice(index + 1),
            ];
            props.updateItem(props.value.questionNo, "answers", a);
          }
        }
      }
    }
    index = props.value.answers.findIndex((x) => x.answerNo === parseInt(id));
    let g2 = props.value.answers[index];
    g2[whichValue] = newValue;
    if (index !== -1) {
      const a = [
        ...props.value.answers.slice(0, index),
        g2,
        ...props.value.answers.slice(index + 1),
      ];
      props.updateItem(props.value.questionNo, "answers", a);
    }
  };
  return (
    <div className="row pr-5 pl-3">
      {props.value.answers.map(
        (val, index) =>
          val.text !== "" && (
            <div
              className={
                props.value.align === "singleColum"
                  ? "col-md-12"
                  : props.value.align === "doubleColum"
                  ? "col-md-6"
                  : props.value.align === "threeColum"
                  ? "col-md-4"
                  : props.value.align === "fourColum"
                  ? "col-md-3"
                  : "col-md answerAlignment"
              }
            >
              <label className={props.handle==="on"?props.realAnswer[index].correct?"containerRadio3":"containerRadio4":"containerRadio3"}>
                <span
                  className={dark ? "radioMarkLabelDark3" : "radioMarkLabel3"}
                >
                  {" "}
                  {val.type === "text" && val.text}
                  {val.type === "image" && (
                    <img
                      alt="answer_image_preview"
                      className="answer_image pb-3"
                      height="100px"
                      src={val.text === "" ? defaultImage : val.text}
                    />
                  )}
                </span>
                <input
                  type="radio"
                  id={val.answerNo}
                  name={
                    "question" + props.value.question + "answer" + val.answerNo
                  }
                  checked={(val.correct || (props.handle==="on"&&props.realAnswer[index].correct))&& "checked"}
                  onChange={onChangeCheck}
                />
                <span className="radioMark3"></span>
              </label>
            </div>
          )
      )}
    </div>
  );
}

export default withRouter(ReviewRadioBox);
