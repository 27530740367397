import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { eventSlotSample, monthDefault } from "../../../../common/json";
import Calender from "../../../body/Components/Calender/Calender";

function CreateEvent(props) {
  const dark = useSelector((state) => state.isDark);

  const [state, setState] = useState({
    year: parseInt(new Date().getFullYear()),
    month: parseInt(new Date().getMonth()),
    now: new Date(),
    date: parseInt(new Date().getDate()),
    time: "",
    name: "",
    description: "",
    eventType: "all",
    folder:"",
  });
  const [year, setYear] = useState([]);

  const [eventSlot, setEventSlot] = useState([
    {
      slotId: 1,
      start: "8:00 am",
      end: "9:00 am",
      slot: "morning",
      select: false,
    },
    {
      slotId: 2,
      start: "9:00 am",
      end: "10:00 am",
      slot: "morning",
      select: false,
    },
    {
      slotId: 3,
      start: "10:00 am",
      end: "11:00 am",
      slot: "morning",
      select: false,
    },
    {
      slotId: 4,
      start: "11:00 am",
      end: "12:00 pm",
      slot: "morning",
      select: false,
    },
    {
      slotId: 5,
      start: "1:00 pm",
      end: "2:00 pm",
      slot: "evening",
      select: false,
    },
    {
      slotId: 6,
      start: "2:00 pm",
      end: "3:00 pm",
      slot: "evening",
      select: false,
    },
    {
      slotId: 7,
      start: "3:00 pm",
      end: "4:00 pm",
      slot: "evening",
      select: false,
    },
    {
      slotId: 8,
      start: "4:00 pm",
      end: "5:00 pm",
      slot: "evening",
      select: false,
    },
    {
      slotId: 9,
      start: "5:00 pm",
      end: "6:00 pm",
      slot: "evening",
      select: false,
    },
    {
      slotId: 10,
      start: "6:00 pm",
      end: "7:00 pm",
      slot: "night",
      select: false,
    },
    {
      slotId: 11,
      start: "7:00 pm",
      end: "8:00 pm",
      slot: "night",
      select: false,
    },
    {
      slotId: 12,
      start: "8:00 pm",
      end: "9:00 pm",
      slot: "night",
      select: false,
    },
    {
      slotId: 13,
      start: "9:00 pm",
      end: "10:00 pm",
      slot: "night",
      select: false,
    },
  ]);
  
  async function onChange(e) {
    const {
      target: { name, value },
    } = e;
    const month="month";
    if (name === "year") {
      if (parseInt(value) === new Date().getFullYear()) {
        setState({ ...state, [month]: new Date().getMonth(), [name]: value });
        document.getElementById("month").value = new Date().getMonth();
      } else {
        setState({ ...state, [month]: 0, [name]: value });
        document.getElementById("month").value = 0;
      }
    } else {
      setState({ ...state, [name]: value });
    }
  }
  async function changeSlot(id, whichValue, newValue) {
    var index = eventSlot.findIndex((x) => x.slotId === id);

    let g = eventSlot[index];
    g[whichValue] = newValue;
    if (index === -1) {
      console.log("no match");
    } else
      setEventSlot([
        ...eventSlot.slice(0, index),
        g,
        ...eventSlot.slice(index + 1),
      ]);
  }
  useEffect(() => {
    var y = parseInt(new Date().getFullYear());
    let year1 = [];
    for (let index = 0; index < 10; index++) {
      year1.push(y + index);
    }
    setYear(year1);
    var ev=[];
    if (state.eventType === "all") {
       ev = props.event.filter(
        (message) =>
          parseInt(new Date(message.start).getDate()) === parseInt(state.date)
      );
    } else {
       ev = props.event.filter(
        (message) =>
          parseInt(new Date(message.start).getDate()) ===
            parseInt(state.date) && message.type === state.eventType
      );
    }

    var slot1 = eventSlotSample;
    eventSlotSample.forEach((slot) => {
      ev.forEach((element) => {
        if (
          formatAMPM(new Date(element.start)) === slot.start &&
          formatAMPM(new Date(element.end)) === slot.end
        ) {
          slot1 = slot1.filter((res) => res.slotId !== slot.slotId);
        }
      });
    });
    setEventSlot(slot1);
  }, [props.event, state.year, state.date, state.eventType, state.month]);

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  const getDayCounts = () => {
    var month = parseInt(state.month) + 1;
    var year1 = parseInt(state.year);
    var daysInMonth = new Date(year1, month, 0).getDate();
    var x = [];
    console.log("dddd"+daysInMonth);
    for (
      let index = parseInt(new Date().getDate());
      index <= daysInMonth;
      index++
    ) {
      x.push(index);
    }
    console.log("ddddddddd"+x);
    return x;
  };
  const getDayCounts1 = () => {
    var month = parseInt(state.month) + 1;
    var year1 = parseInt(state.year);
    var daysInMonth = new Date(year1, month, 0).getDate();
    var x = [];
    console.log("mont"+month);
    console.log("year"+year);
    console.log("cccc"+daysInMonth);
    for (let index = 1; index <= daysInMonth; index++) {
      x.push(index);
    }
    console.log("ccccccc"+x);
    return x;
  };
  return (
    <div>
      {props.name !== "createEventMobile" && (
        <button
          className="buttonPri pull-right mb-3 mt-3"
          data-toggle="modal"
          data-target={"#" + props.name}
        >
          Add Event
        </button>
      )}
      <div
        className="modal fade "
        id={props.name}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "10000" }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div
            className={
              !dark
                ? "modal-content classModal"
                : " modal-content classModalDark"
            }
            style={{ borderRadius: "15px" }}
          >
            <div className="row  p-0 m-0 " style={{ borderRadius: "15px" }}>
              <div
                className="col bg-throout p-0 m-0 pt-2 pb-2"
                style={{
                  borderTopLeftRadius: "13px",
                  borderTopRightRadius: "13px",
                }}
              >
                <h2 className="">Add events</h2>
                <i
                  class="fa fa-window-close  absolute pointer"
                  style={{ right: "15px", top: "15px" }}
                  data-dismiss="modal"
                  aria-label="Close"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            <div className="row  p-0 m-0 ">
              <div className="col-lg-5 pt-3 pb-3">
                <Calender
                  name={props.name}
                  event={props.event}
                  eventType={state.eventType}
                  month={
                    state.month === "" ? new Date().getMonth() : state.month
                  }
                  year={
                    state.year === "" ? new Date().getFullYear() : state.year
                  }
                />
              </div>
              <div className="col-lg-7 pt-3">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label
                      htmlFor="year"
                      className={
                        dark ? "createContentLabelDark" : "createContentLabel"
                      }
                    >
                      Year
                    </label>
                    <select
                      className={
                        dark
                          ? "form-control dropDownSelectDark"
                          : "form-control dropDownSelect"
                      }
                      id="year"
                      name="year"
                      onChange={onChange}
                    >
                      {year.map((option, index) => (
                        <option value={option}>{option}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label
                      htmlFor="month"
                      className={
                        dark ? "createContentLabelDark" : "createContentLabel"
                      }
                    >
                      Month
                    </label>
                    <select
                      className={
                        dark
                          ? "form-control dropDownSelectDark"
                          : "form-control dropDownSelect"
                      }
                      id="month"
                      name="month"
                      onChange={onChange}
                    >
                      {monthDefault.map((option, index) =>
                        parseInt(new Date().getFullYear()) ===
                        parseInt(
                          state.year === ""
                            ? new Date().getFullYear()
                            : state.year
                        ) ? (
                          parseInt(new Date().getMonth()) + index < 12 && (
                            <option
                              value={parseInt(new Date().getMonth()) + index}
                            >
                              {
                                monthDefault[
                                  (parseInt(new Date().getMonth()) + index) % 12
                                ]
                              }
                            </option>
                          )
                        ) : (
                          <option value={index}>{monthDefault[index]}</option>
                        )
                      )}
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label
                      htmlFor="month"
                      className={
                        dark ? "createContentLabelDark" : "createContentLabel"
                      }
                    >
                      Event Type
                    </label>
                    <select
                      className={
                        dark
                          ? "form-control dropDownSelectDark"
                          : "form-control dropDownSelect"
                      }
                      id="eventType"
                      name="eventType"
                      onChange={onChange}
                    >
                      <option value={"all"} disabled="">
                        Select event
                      </option>
                      <option value={"zoom"}>Zoom Class</option>
                      <option value={"exam"}>Exam</option>
                      <option value={"submission"}>Submission</option>
                    </select>
                  </div>
                  {(state.eventType === "zoom" ||
                    state.eventType === "submission" ||
                    state.eventType === "exam") && (
                    <div className="form-group col-md-6">
                      <label
                        htmlFor="month"
                        className={
                          dark ? "createContentLabelDark" : "createContentLabel"
                        }
                      >
                        Select Date
                      </label>
                      <select
                        className={
                          dark
                            ? "form-control dropDownSelectDark"
                            : "form-control dropDownSelect"
                        }
                        id="date"
                        name="date"
                        onChange={onChange}
                      >
                        {parseInt(new Date().getFullYear()) ===
                          parseInt(
                            state.year === ""
                              ? new Date().getFullYear()
                              : state.year
                          ) &&
                        parseInt(new Date().getMonth()) ===
                          parseInt(
                            state.month === ""
                              ? new Date().getMonth()
                              : state.month
                          )
                          ? getDayCounts().map((option, index) => (
                              <option value={option}>{option}</option>
                            ))
                          : getDayCounts1().map((option, index) => (
                              <option value={option}>{option}</option>
                            ))}
                      </select>
                    </div>
                  )}
                  {(state.eventType === "zoom" ||
                    state.eventType === "exam") && (
                    <div className="form-group col-md-12">
                      <label
                        htmlFor="month"
                        className={
                          dark ? "createContentLabelDark" : "createContentLabel"
                        }
                      >
                        Available Time slots
                      </label>
                      <div className="row p-0 m-0">
                        <div className="col-4 p-0 m-0">
                          <div className="bg-morning  pb-2 pl-2 ">
                            <span className="pl-3">Morning</span>
                            <br />
                            {eventSlot.map(
                              (option, index) =>
                                option.slot === "morning" && (
                                  <span
                                    class="badge badge-dark mr-2 pointer"
                                    onClick={() =>
                                      changeSlot(
                                        option.slotId,
                                        "select",
                                        !option.select
                                      )
                                    }
                                  >
                                    <span
                                      class={
                                        option.select
                                          ? "badge badge-pill bg-success1"
                                          : "badge badge-pill badge-light"
                                      }
                                    >
                                      {option.start} - {option.end}
                                    </span>
                                    <i
                                      class={
                                        option.select
                                          ? "fa fa-check-circle pl-2 pr-2 text-success1"
                                          : "fa fa-circle pl-2 pr-2"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                        <div className="col-4 p-0 m-0">
                          <div className="bg-evening pb-2 pl-2 ">
                            <span className="pl-3">Evening</span>
                            <br />
                            {eventSlot.map(
                              (option, index) =>
                                option.slot === "evening" && (
                                  <span
                                    class="badge badge-dark mr-2 pointer"
                                    onClick={() =>
                                      changeSlot(
                                        option.slotId,
                                        "select",
                                        !option.select
                                      )
                                    }
                                  >
                                    <span
                                      class={
                                        option.select
                                          ? "badge badge-pill bg-success1"
                                          : "badge badge-pill badge-light"
                                      }
                                    >
                                      {option.start} - {option.end}
                                    </span>
                                    <i
                                      class={
                                        option.select
                                          ? "fa fa-check-circle pl-2 pr-2 text-success1"
                                          : "fa fa-circle pl-2 pr-2"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                        <div className="col-4 p-0 m-0">
                          <div className="bg-night pb-2 pl-2 ">
                            <span className="pl-3">Night</span>
                            <br />
                            {eventSlot.map(
                              (option, index) =>
                                option.slot === "night" && (
                                  <span
                                    class="badge badge-dark mr-2 pointer"
                                    onClick={() =>
                                      changeSlot(
                                        option.slotId,
                                        "select",
                                        !option.select
                                      )
                                    }
                                  >
                                    <span
                                      class={
                                        option.select
                                          ? "badge badge-pill bg-success1"
                                          : "badge badge-pill badge-light"
                                      }
                                    >
                                      {option.start} - {option.end}
                                    </span>
                                    <i
                                      class={
                                        option.select
                                          ? "fa fa-check-circle pl-2 pr-2 text-success1"
                                          : "fa fa-circle pl-2 pr-2"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {state.eventType === "submission" && (
                    <><div className="form-group col-md-6">
                      <label
                        htmlFor="time"
                        className={
                          dark ? "createContentLabelDark" : "createContentLabel"
                        }
                      >
                        Select Time
                      </label>
                      <input
                        type="time"
                        id="time"
                        name="time"
                        className={
                          dark
                            ? "form-control dropDownSelectDark"
                            : "form-control dropDownSelect"
                        }
                        onChange={onChange}
                      />
                    </div>
                    <div className="form-group col-md-6">
                    <label
                      htmlFor="folder"
                      className={
                        dark ? "createContentLabelDark" : "createContentLabel"
                      }
                    >
                      Folder Name
                    </label>
                    <input
                      type="text"
                      id="folder"
                      name="folder"
                      className={
                        dark
                          ? "form-control dropDownSelectDark"
                          : "form-control dropDownSelect"
                      }
                      onChange={onChange}
                    />
                  </div></>
                  )}
                </div>
              </div>
            </div>
            {state.eventType !== "all" && (
              <div class="row">
                <div className="form-group col-md-5 pl-4">
                  <label
                    htmlFor="name"
                    className={
                      dark ? "createContentLabelDark" : "createContentLabel"
                    }
                  >
                    Event Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className={
                      dark
                        ? "form-control dropDownSelectDark"
                        : "form-control dropDownSelect"
                    }
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col-md-7 pr-4">
                  <label
                    htmlFor="description"
                    className={
                      dark ? "createContentLabelDark" : "createContentLabel"
                    }
                  >
                    Event Description
                  </label>
                  <textarea
                    type="text"
                    id="description"
                    name="description"
                    className={
                      dark
                        ? "form-control dropDownSelectDark"
                        : "form-control dropDownSelect"
                    }
                    onChange={onChange}
                  />
                </div>
              </div>
            )}
            <div className=" col-md-12 pr-4">
              <button className="buttonPri pull-right mb-3 mt-3">
                Add Event
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CreateEvent);
