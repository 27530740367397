const DarkReducer = (state = false, action) => {
  switch (action.type) {
    case "DARK":
      if (state) {
        document.body.style.background = "#fff";
      } else {
        document.body.style.background = "#212534";
      }
      return !state;
    default:
      return state;
  }
};
export default DarkReducer;
