import React from "react";
import "../quiz.css";
import { withRouter } from "react-router-dom";

function MatchingQuestion(props) {
  async function onChange(e) {
    let {
      target: { name, value },
    } = e;
    if (name === "text1") {
      value = [value, props.data.text[1]];
    } else if (name === "text2") {
      props.updateMatchingText(props.data.answerNo, value);
      value = [props.data.text[0], value];
    }
    props.updateCheckAnswerItem(props.data.answerNo, "text", value);
  }
  return (
    <div className="row" key={"q" + props.index + "a" + props.key}>
      <div className=" p-3 " style={{ width: "10px" }}>
        {props.answersLength > 1 && (
          <i
            className="fa fa-times pointer"
            aria-hidden="true"
            onClick={() => props.removeCheckAnswer(props.data.answerNo)}
          ></i>
        )}
      </div>
      <div className="col">
        <div className="row reviewMatch">
          <div className=" ml-2 ">
            <div className="input-group pt-2 border-0">
              <input
                className="border-0 rounded pl-3 reviewInput"
                onChange={onChange}
                name="text1"
                value={props.data.text[0]}
              ></input>
              <div className="input-group-append reviewInputNumber">
                <span className="input-group-text" id="basic-addon2">
                  {parseInt(props.k) + 1}
                </span>
              </div>
            </div>
          </div>
          <div className="col mt-0  align-self-center">
            <hr></hr>
          </div>
          <div className=" mr-5  ">
            <div className="input-group pt-2 border-0">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon2">
                  {parseInt(props.k) + 1}
                </span>
              </div>
              <input
                className="border-0 rounded pl-3"
                onChange={onChange}
                name="text2"
                value={props.data.text[1]}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(MatchingQuestion);
