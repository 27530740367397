import React from "react";
import "../quiz.css";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import defaultImage from "../../../../images/defaultImage.jpg";

function CheckAnswer(props) {
  const dark = useSelector((state) => state.isDark);
  async function onChange(e) {
    const {
      target: { name, value },
    } = e;
    props.updateCheckAnswerItem(props.data.answerNo, name, value);
  }
  async function onChangeCheck(e) {
    props.updateCheckAnswerItem(
      props.data.answerNo,
      "correct",
      !props.data.correct
    );
  }
  const uploadAnswerImage = (e) => {
    if (e.target.files[0]) {
      var read = new FileReader();
      read.onloadend = () => {
        props.updateCheckAnswerItem(props.data.answerNo, "text", read.result);
      };
      read.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <div className="row" key={"q" + props.index + "a" + props.key}>
      <div className=" p-3 " style={{ width: "10px" }}>
        {props.answersLength > 1 && (
          <i
            className="fa fa-times pointer"
            aria-hidden="true"
            onClick={() => props.removeCheckAnswer(props.data.answerNo)}
          ></i>
        )}
      </div>
      <div className="col">
        {props.data.type === "text" && (
          <input
            className="answer"
            onChange={onChange}
            name="text"
            value={props.data.text}
          ></input>
        )}
        {props.data.type === "image" && (
          <div>
            <img
              alt="answer_image"
              className="answer_image pb-3"
              height="100px"
              src={props.data.text === "" ? defaultImage : props.data.text}
            />
            &nbsp; &nbsp; &nbsp; &nbsp;
            <label
              for={
                "questionImage" + props.index + "answer" + props.data.answerNo
              }
              className="buttonBlack btn mt-2"
            >
              {" "}
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </label>
            <input
              id={
                "questionImage" + props.index + "answer" + props.data.answerNo
              }
              style={{ visibility: "hidden" }}
              type="file"
              onChange={uploadAnswerImage}
            />
          </div>
        )}
      </div>
      <div className="col-2">
        <label className="containerCheck">
          <input
            type="checkbox"
            checked={props.data.correct && "checked"}
            onChange={onChangeCheck}
          />
          <span className="checkMark">
            {" "}
            <span className={dark ? "checkMarkLabelDark" : "checkMarkLabel"}>
              {props.data.correct && "Correct"}
            </span>
          </span>
          <span className="checkMark"></span>
        </label>
      </div>
    </div>
  );
}

export default withRouter(CheckAnswer);
