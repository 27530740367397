import React from "react";
import { useDrop } from "react-dnd";
import AfterDrag from "./AfterDrag";
import "./fill.css";

function Drop({ board, addImageToBoard, remove,icon }) {
  // eslint-disable-next-line
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "text",
    drop: (item) => addImageToBoard(item.id, board.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  return (
    <div
      ref={drop}
      className="fillBoard  d-flex justify-content-center align-content-center pt-1"
    >
      <AfterDrag id={board.id} answerNo={board.answerNo} board={board} remove={remove} icon={icon}></AfterDrag>
    </div>
  );
}

export default Drop;
