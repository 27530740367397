import "./App.css";
import "./sunEditor.css";
import NavBar from "./components/navbar/navbar";
import Header from "./components/navbar/header";
import Body from "./components/body/body";
import Footer from "./components/footer/footer";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  appendNavCollection,
  authorize,
  changeRole,
  changeRoleGuest,
  setUser,
  unAuthorize,
} from "./actions";
import { BrowserRouter } from "react-router-dom";
import localStorageService from "./services/localStorageService";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    const AuthCheck = () => {
      var UserData = localStorageService.getItem("auth_user");
      var roleNo = localStorageService.getItem("role");
      dispatch(setUser(UserData));
      if (UserData && roleNo) {
        dispatch(authorize());
        dispatch(changeRole(roleNo));
      } else {
        dispatch(unAuthorize());
        dispatch(changeRoleGuest());
      }
    };
    AuthCheck();
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorageService.getItem("jwt_token");
    const navItems = [
      {
        id: 1,
        value: "Tutorials",
        list: [
          {
            id: 1,
            value: "HTML",
            list: [
              { id: 1, value: "Learn Html" },
              { id: 2, value: "Learn CSS" },
              { id: 3, value: "Learn Bootstrap" },
              { id: 4, value: "Learn W3.CSS" },
              { id: 5, value: "Learn Color" },
              { id: 6, value: "Learn Icon" },
              { id: 7, value: "Learn Gradivhics" },
              { id: 8, value: "Learn SVG" },
              { id: 9, value: "Learn Canvas" },
              { id: 10, value: "Learn How to" },
            ],
          },
          {
            id: 2,
            value: "Server Side",
            list: [
              { id: 1, value: "Learn SQL" },
              { id: 2, value: "Learn PHP" },
              { id: 3, value: "Learn ASP" },
              { id: 4, value: "Learn Node.js" },
              { id: 5, value: "Learn Respberry Pi" },
            ],
          },
          {
            id: 3,
            value: "JavaScript",
            list: [
              { id: 1, value: "Learn JavaScript" },
              { id: 2, value: "Learn jQuery" },
              { id: 3, value: "Learn React" },
              { id: 4, value: "Learn Angulur JS" },
              { id: 5, value: "Learn JSON" },
              { id: 6, value: "Learn Icon" },
              { id: 7, value: "Learn AJAX" },
              { id: 8, value: "Learn AppML" },
              { id: 9, value: "Learn W3.JS" },
            ],
          },
          {
            id: 4,
            value: "XML Tutorials",
            list: [
              { id: 1, value: "Learn XML" },
              { id: 2, value: "Learn XML AJAX" },
              { id: 3, value: "Learn XML DOM" },
              { id: 4, value: "Learn XML DTD" },
              { id: 5, value: "Learn XML Schema" },
              { id: 6, value: "Learn XSLT" },
              { id: 7, value: "Learn XPath" },
              { id: 8, value: "Learn XQuery" },
            ],
          },
        ],
      },
      {
        id: 2,
        value: "Quizzes",
        list: [
          {
            id: 1,
            value: "HTML and Sand",
            list: [
              { id: 1, value: "Learn Html" },
              { id: 2, value: "Learn CSS" },
              { id: 3, value: "Learn Bootstrap" },
              { id: 4, value: "Learn W3.CSS" },
              { id: 5, value: "Learn Color" },
              { id: 6, value: "Learn Icon" },
              { id: 7, value: "Learn Gradivhics" },
              { id: 8, value: "Learn SVG" },
              { id: 9, value: "Learn Canvas" },
              { id: 10, value: "Learn How to" },
            ],
          },
          {
            id: 2,
            value: "JavaScript",
            list: [
              { id: 1, value: "Learn JavaScript" },
              { id: 2, value: "Learn jQuery" },
              { id: 3, value: "Learn React" },
              { id: 4, value: "Learn Angulur JS" },
              { id: 5, value: "Learn JSON" },
              { id: 6, value: "Learn Icon" },
              { id: 7, value: "Learn AJAX" },
              { id: 8, value: "Learn AppML" },
              { id: 9, value: "Learn W3.JS" },
            ],
          },
          {
            id: 3,
            value: "Server Side",
            list: [
              { id: 1, value: "Learn SQL" },
              { id: 2, value: "Learn PHP" },
              { id: 3, value: "Learn ASP" },
              { id: 4, value: "Learn Node.js" },
              { id: 5, value: "Learn Respberry Pi" },
            ],
          },
          {
            id: 4,
            value: "XML Tutorials",
            list: [
              { id: 1, value: "Learn XML" },
              { id: 2, value: "Learn XML AJAX" },
              { id: 3, value: "Learn XML DOM" },
              { id: 4, value: "Learn XML DTD" },
              { id: 5, value: "Learn XML Schema" },
              { id: 6, value: "Learn XSLT" },
              { id: 7, value: "Learn XPath" },
              { id: 8, value: "Learn XQuery" },
            ],
          },
        ],
      },
      {
        id: 3,
        value: "Apps",
        list: [
          {
            id: 1,
            value: "JavaScript",
            list: [
              { id: 1, value: "Learn JavaScript" },
              { id: 2, value: "Learn jQuery" },
              { id: 3, value: "Learn React" },
              { id: 4, value: "Learn Angulur JS" },
              { id: 5, value: "Learn JSON" },
              { id: 6, value: "Learn Icon" },
              { id: 7, value: "Learn AJAX" },
              { id: 8, value: "Learn AppML" },
              { id: 9, value: "Learn W3.JS" },
            ],
          },
          {
            id: 2,
            value: "HTML and Sand",
            list: [
              { id: 1, value: "Learn Html" },
              { id: 2, value: "Learn CSS" },
              { id: 3, value: "Learn Bootstrap" },
              { id: 4, value: "Learn W3.CSS" },
              { id: 5, value: "Learn Color" },
              { id: 6, value: "Learn Icon" },
              { id: 7, value: "Learn Gradivhics" },
              { id: 8, value: "Learn SVG" },
              { id: 9, value: "Learn Canvas" },
              { id: 10, value: "Learn How to" },
            ],
          },
          {
            id: 3,
            value: "XML Tutorials",
            list: [
              { id: 1, value: "Learn XML" },
              { id: 2, value: "Learn XML AJAX" },
              { id: 3, value: "Learn XML DOM" },
              { id: 4, value: "Learn XML DTD" },
              { id: 5, value: "Learn XML Schema" },
              { id: 6, value: "Learn XSLT" },
              { id: 7, value: "Learn XPath" },
              { id: 8, value: "Learn XQuery" },
            ],
          },
          {
            id: 4,
            value: "Server Side",
            list: [
              { id: 1, value: "Learn SQL" },
              { id: 2, value: "Learn PHP" },
              { id: 3, value: "Learn ASP" },
              { id: 4, value: "Learn Node.js" },
              { id: 5, value: "Learn Respberry Pi" },
            ],
          },
        ],
      },
      {
        id: 4,
        value: "Contacts",
        list: [
          {
            id: 1,
            value: "HTML and Sand",
            list: [
              { id: 1, value: "Learn Html" },
              { id: 2, value: "Learn CSS" },
              { id: 3, value: "Learn Bootstrap" },
              { id: 4, value: "Learn W3.CSS" },
              { id: 5, value: "Learn Color" },
              { id: 6, value: "Learn Icon" },
              { id: 7, value: "Learn Gradivhics" },
              { id: 8, value: "Learn SVG" },
              { id: 9, value: "Learn Canvas" },
              { id: 10, value: "Learn How to" },
            ],
          },
          {
            id: 2,
            value: "JavaScript",
            list: [
              { id: 1, value: "Learn JavaScript" },
              { id: 2, value: "Learn jQuery" },
              { id: 3, value: "Learn React" },
              { id: 4, value: "Learn Angulur JS" },
              { id: 5, value: "Learn JSON" },
              { id: 6, value: "Learn Icon" },
              { id: 7, value: "Learn AJAX" },
              { id: 8, value: "Learn AppML" },
              { id: 9, value: "Learn W3.JS" },
            ],
          },
          {
            id: 3,
            value: "Server Side",
            list: [
              { id: 1, value: "Learn SQL" },
              { id: 2, value: "Learn PHP" },
              { id: 3, value: "Learn ASP" },
              { id: 4, value: "Learn Node.js" },
              { id: 5, value: "Learn Respberry Pi" },
            ],
          },
          {
            id: 4,
            value: "XML Tutorials",
            list: [
              { id: 1, value: "Learn XML" },
              { id: 2, value: "Learn XML AJAX" },
              { id: 3, value: "Learn XML DOM" },
              { id: 4, value: "Learn XML DTD" },
              { id: 5, value: "Learn XML Schema" },
              { id: 6, value: "Learn XSLT" },
              { id: 7, value: "Learn XPath" },
              { id: 8, value: "Learn XQuery" },
            ],
          },
        ],
      },
    ];
    dispatch(appendNavCollection(navItems));
  }, [dispatch]);
  const handleScroll = (event) => {
    var navbar = document.getElementById("navbar");
    var sideBar = document.getElementById("sideBar");
    var layerBody = document.getElementById("layerBody");
    var sticky = navbar.offsetTop;
    if (window.pageYOffset > sticky) {
      navbar.classList.add("navBarTop");
      sideBar.classList.add("top130");
      layerBody.classList.add("top130");
    } else {
      navbar.classList.remove("navBarTop");
      sideBar.classList.remove("top130");
      layerBody.classList.remove("top130");
    }
  };
  return (
    <React.Fragment>
      <BrowserRouter>
        <Header />
        <NavBar />
        <Body />
        <Footer />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
