
const navCollectionReducer = (state =[] , action)=>{
     switch(action.type){
          case 'APPEND_NAV_COLLECTION':
               return action.array;
          case 'CLOSE_NAV_COLLECTION':
               return [];
          case 'REMOVE_NAV_COLLECTION':
               return state.map(todo=>{
                         if(todo.value!== action.name){
                              return todo;
                         }
                         return{}
                    }
               );
          default:
               return state
     }
}
export default navCollectionReducer;

