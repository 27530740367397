import React from "react";
import { useDrag } from "react-dnd";
import "./fill.css";

function Drag({ id, text, remove }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "text",
    item: { id: id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <span
      ref={drag}
      className={
        isDragging
          ? "badge fillBarDrag m-3 p-2 pr-5 pl-5"
          : "badge fillBar m-3 p-2 pr-5 pl-5"
      }
    >
      {text}
    </span>
  );
}

export default Drag;
