const LoaderReducer = (state = false, action) => {
     switch (action.type) {
       case "LOADER":
         if (state) {
          document.getElementById("layerBody").style.display = "none";
         } else {
          document.getElementById("layerBody").style.display = "block";
         }
         return !state;
       default:
         return state;
     }
   };
   export default LoaderReducer;
   