import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import CreateContent from "../dashboard/content/CreateContent";
import LearnerDashboard from "../dashboard/learner/LearnerDashboard";
import CreateQuestion from "../dashboard/quiz/CreateQuestion";
import CreateQuiz from "../dashboard/quiz/CreateQuiz";
import ReviewQuestion from "../dashboard/quiz/ReviewQuestion";
import Loader from "../Loader/Loader";
import UserContent from "../publicElements/UserContent";
import InformationPage from "../showingPages/InformationPage";
import "./body.css";
import Contact from "./ContactUs/contact";
import Content from "./Contents/Content";
import Login from "../login/Login";
import Alert from "../Alert/Alert";
import MarkingQuestion from "../dashboard/quiz/MarkingQuestion";
import AnsweringQuestion from "../dashboard/quiz/AnsweringQuestion";

function Body(props) {
  const dark = useSelector((state) => state.isDark);
  const auth = useSelector((state) => state.isAuth);
  const alert = useSelector((state) => state.alert);
  const [menu, setMenu] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setMenu(false);
    }, 3000);
  }, []);

  function bodyContentStyle() {
    let classes = "";
    classes += !dark
      ? " bodyContent container-fluid"
      : "bodyContentDark container-fluid";
    return classes;
  }
  function myFunction() {
    setMenu(!menu);
  }
  function menuStyle() {
    let classes = "";
    classes += !menu ? " containerMenu " : "containerMenu change";
    return classes;
  }
  function menuChange() {
    let classes = "";
    var navbar = document.getElementById("navbar");
    var sticky = "";
    if (navbar) sticky = navbar.offsetTop;
    else sticky = 0;
    classes += !menu
      ? dark
        ? window.pageYOffset > sticky
          ? " RentalUnitsMapOff bgDarkThroout top130"
          : " RentalUnitsMapOff bgDarkThroout "
        : window.pageYOffset > sticky
        ? "RentalUnitsMapOff bg-white box top130"
        : "RentalUnitsMapOff bg-white box "
      : dark
      ? window.pageYOffset > sticky
        ? " RentalUnitsMapOn bgDarkThroout top130"
        : " RentalUnitsMapOn bgDarkThroout "
      : window.pageYOffset > sticky
      ? "RentalUnitsMapOn bg-white box top130"
      : "RentalUnitsMapOn bg-white box";
    return classes;
  }
  return (
    <>
      <div id="layerBody" className="layerBody">
        <Loader />
      </div>
      <div className="alertContain ">
        {alert.map((todo) => {
          return (
            todo.status && (
              <Alert
                content={todo.text}
                messageType={todo.messageType}
                index={todo.id}
              />
            )
          );
        })}
       
      </div>
      <div className={bodyContentStyle()}>
        <div className="row ">
          <div className={menuChange()} id="sideBar">
            <div className=" pt-2 pb-2 ">
              <div className="menuIndex pull-right ">
                <div className={menuStyle()} onClick={() => myFunction()}>
                  <div className="bar1"></div>
                  <div className="bar2"></div>
                  <div className="bar3"></div>
                </div>
                <div></div>
              </div>
              <div className="row pt-3 pb-2 p-2 ">
                <h1
                  className={
                    dark
                      ? "priText leftHeadTopDark pb-3 col-12"
                      : "priText leftHeadTop pb-3 col-12"
                  }
                >
                  Options
                </h1>
                <div
                  className={
                    dark
                      ? "leftHeadDark col-12 pointer"
                      : "leftHead col-12 pointer"
                  }
                  onClick={() => props.history.push("createContent")}
                >
                  <p className="pull-left m-0">Write Article </p>{" "}
                  <i
                    className="pull-right fa fa-angle-double-right leftHeadIcon"
                    aria-hidden="true"
                  ></i>
                </div>
                <div
                  className={
                    dark
                      ? "leftHeadDark col-12 pointer"
                      : "leftHead col-12 pointer"
                  }
                  onClick={() => props.history.push("addQuiz")}
                >
                  <p className="pull-left m-0">Create Quiz </p>{" "}
                  <i
                    className="pull-right fa fa-angle-double-right leftHeadIcon"
                    aria-hidden="true"
                  ></i>
                </div>
                <div
                  className={
                    dark
                      ? "leftHeadDark col-12 pointer"
                      : "leftHead col-12 pointer"
                  }
                >
                  <p className="pull-left m-0">Use Apps </p>{" "}
                  <i
                    className="pull-right fa fa-angle-double-right leftHeadIcon"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col bodyContain">
            <Switch>
              <Route exact path="/">
                <Content />
                <Contact />
              </Route>
              <Route exact path="/dashboard">
                {auth ? <LearnerDashboard /> : <Login hitPoint="/dashboard" />}
              </Route>
              <Route exact path="/createContent">
                {auth ? <CreateContent /> : <Login hitPoint="/dashboard" />}
              </Route>
              <Route exact path="/content">
                <UserContent />
              </Route>
              <Route exact path="/addQuiz">
                {auth ? <CreateQuiz /> : <Login hitPoint="/dashboard" />}
              </Route>
              <Route exact path="/addQuestion">
                {auth ? <CreateQuestion /> : <Login hitPoint="/dashboard" />}
              </Route>
              <Route exact path="/questionReview">
                <ReviewQuestion />
              </Route>
              <Route exact path="/answeringQuestion">
                <AnsweringQuestion />
              </Route>
              <Route exact path="/markingQuestion">
                <MarkingQuestion />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/signup">
                <Login />
              </Route>
              <Route component={InformationPage} />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Body);
