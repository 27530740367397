import React from "react";
// import { useSelector } from "react-redux";
import "./chat.css";

function Chat(props) {
  // const dark = useSelector((state) => state.isDark);
  const [state, setState] = React.useState({});
  const formatTime = (time) => {
    var a = new Date(time);
    var today = new Date();
    var diffTime = Math.abs(today - a);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    const diffMin = Math.ceil(diffTime / (1000 * 60));
    const diffSec = Math.ceil(diffTime / 1000);
    if (diffDays > 1) {
      return diffDays - 1 + " d";
    } else if (diffHours > 1) {
      return diffHours - 1 + " h";
    } else if (diffSec > 59) {
      return diffMin - 1 + " m";
    } else if (diffSec > 10) {
      return diffSec + " s";
    } else {
      return "now";
    }
  };
  async function onchange(e) {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
  }
  return (
    <div className="chatContainer">
      <ul className="nav nav-tabs " id="myTab" role="tablist">
        <li className="nav-item ">
          <a
            className="nav-link  mb-0 chat"
            id="contact-tab"
            data-toggle="tab"
            href="#contact"
            role="tab"
            aria-controls="contact"
            aria-selected="true"
          >
            <i className="fa fa-address-card pr-3 pl-3" aria-hidden="true"></i>
          </a>
        </li>
        <li className="nav-item">
          {props.newChat.length > 0 ? (
            <a
              className="nav-link active mb-0 chat"
              id="chat-tab"
              data-toggle="tab"
              href="#chat"
              role="tab"
              aria-controls="chat"
              aria-selected="false"
            >
              <i className="fa fa-comments pr-3 pl-3" aria-hidden="true">
                <span
                  style={{
                    color: "red",
                    fontSize: "60px",
                    position: "absolute",
                    marginTop: "-10px",
                  }}
                >
                  &middot;
                </span>
              </i>
            </a>
          ) : (
            <a
              className="nav-link active mb-0 chat"
              id="chat-tab"
              data-toggle="tab"
              href="#chat"
              role="tab"
              aria-controls="chat"
              aria-selected="false"
            >
              <i className="fa fa-comments pr-3 pl-3" aria-hidden="true"></i>
            </a>
          )}
        </li>

        <li className="nav-item ">
          <a
            className="nav-link mb-0 chat"
            id="profile1-tab"
            data-toggle="tab"
            href="#profile1"
            role="tab"
            aria-controls="profile1"
            aria-selected="false"
          >
            <i className="fa fa-user pr-3 pl-3" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade  bg-white"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <div className="topmenu">
            <div id="friends">
              <div className="friend">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/1_copy.jpg"
                  alt="profileImage"
                />
                <p>
                  <strong>Miro Badev</strong>
                  <br></br>
                  <span>mirobadev@gmail.com</span>
                </p>
                <div className="status available"></div>
              </div>
              <div className="friend">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/1_copy.jpg"
                  alt="profileImage"
                />
                <p>
                  <strong>Miro Badev</strong>
                  <br></br>
                  <span>mirobadev@gmail.com</span>
                </p>
                <div className="status available"></div>
              </div>
              <div className="friend">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/1_copy.jpg"
                  alt="profileImage"
                />
                <p>
                  <strong>Miro Badev</strong>
                  <br></br>
                  <span>mirobadev@gmail.com</span>
                </p>
                <div className="status available"></div>
              </div>

              <div className="friend">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/2_copy.jpg"
                  alt="profileImage"
                />
                <p>
                  <strong>Martin Joseph</strong>
                  <br></br>
                  <span>marjoseph@gmail.com</span>
                </p>
                <div className="status away"></div>
              </div>

              <div className="friend">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/3_copy.jpg"
                  alt="profileImage"
                />
                <p>
                  <strong>Tomas Kennedy</strong>
                  <br></br>
                  <span>tomaskennedy@gmail.com</span>
                </p>
                <div className="status inactive"></div>
              </div>

              <div className="friend">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/4_copy.jpg"
                  alt="profileImage"
                />
                <p>
                  <strong>Enrique Sutton</strong>
                  <br></br>
                  <span>enriquesutton@gmail.com</span>
                </p>
                <div className="status inactive"></div>
              </div>

              <div className="friend">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/5_copy.jpg"
                  alt="profileImage"
                />
                <p>
                  <strong> Darnell Strickland</strong>
                  <br></br>
                  <span>darnellstrickland@gmail.com</span>
                </p>
                <div className="status inactive"></div>
              </div>
            </div>
            <div id="search">
              <input type="text" id="searchfield" value="Search contacts..." />
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade show active"
          id="chat"
          role="tabpanel"
          aria-labelledby="chat-tab"
        >
          <div id="chatview" className="p1">
            <div id="profile">
              <img
                className="chatProfile"
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/1_copy.jpg"
                alt="profileImage"
              />
              <p>Miro Badev</p>
              <span>miro@badev@gmail.com</span>
            </div>
            <div id="chat-messages" className="chat-messages">
              <label>Thursday 02</label>
              {props.chat.map((data) =>
                data.sender === "Miro" ? (
                  <div className="message ">
                    <img
                      src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/1_copy.jpg"
                      alt="profileImage"
                    />
                    <div className="bubble">
                      {data.message}
                      <br></br>
                      <span>{formatTime(data.published)}</span>
                    </div>
                  </div>
                ) : (
                  <div className="message right">
                    <img
                      src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/2_copy.jpg"
                      alt="profileImage"
                    />
                    <div className="bubble">
                      {data.message}
                      <br></br>
                      <span>{formatTime(data.published)}</span>
                    </div>
                  </div>
                )
              )}

              <div className="message">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/1_copy.jpg"
                  alt="proImage"
                />
                <div className="bubble">
                  <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                  &nbsp; &nbsp;
                  <span>typing ...</span>
                </div>
              </div>
            </div>

            <div id="sendmessage">
              <input
                type="text"
                placeholder="Send message..."
                name="message"
                value={state.message}
                onChange={onchange}
              />
              <button
                id="send"
                className=" pull-right"
                onClick={() =>
                  props.pushMessage(state.message, "Martin", "Miro")
                }
              >
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="profile1"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <div id="chatview" className="p1 chatSetting">
            <div id="profile">
              <img
                className="chatProfile"
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/2_copy.jpg"
                alt="profileImage"
              />
              <p>Martin Joseph</p>
              <span>marjoseph@gmail.com</span>
            </div>
            <div className="form-group col-12 pt-2">
              <label htmlFor="title">Change Display Name</label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button className="btn btn-outline-primary" type="button">
                    Change
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group col-12 pt-2 pl-4">
              <label htmlFor="title">Status</label>
              <div className="input-group ">
                <div className="radio-item">
                  <input
                    type="radio"
                    className="active"
                    id="ritema"
                    name="ritem"
                    value="ropt1"
                  />
                  <label htmlFor="ritema">&nbsp; Active</label>
                </div>
              </div>
              <div className="input-group">
                <div className="radio-item">
                  <input
                    type="radio"
                    className="away"
                    id="ritema"
                    name="ritem"
                    value="ropt1"
                  />
                  <label htmlFor="ritema">&nbsp; Away</label>
                </div>
              </div>
              <div className="input-group">
                <div className="radio-item">
                  <input
                    type="radio"
                    className="inactive"
                    id="ritema"
                    name="ritem"
                    value="ropt1"
                  />
                  <label htmlFor="ritema">&nbsp; Inactive</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
