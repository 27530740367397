const SearchReducer = (state =false, action) => {
     switch (action.type) {
       case 'OPEN_SEARCH':
         return true;
       case 'CLOSE_SEARCH':
         return false;
       case 'TOGGLE_SEARCH':
         return !state;
       default:
         return state;
     }
   };
export default SearchReducer;
   