import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./userContent.css";
import { withRouter } from "react-router-dom";
import {
  template1,
  template2,
  template3,
  template4,
} from "../dashboard/content/templates";

function UserContent(props) {
  const dark = useSelector((state) => state.isDark);
  const [content, setContent] = useState(1);
  function bodyContentStyle() {
    let classes = "";
    classes += !dark ? " contentStyle " : " contentStyleDark ";
    return classes;
  }

  useEffect(() => {
    var out = document.getElementById("output");
    out.innerHTML = template4;
    window.scrollTo(0, 0);
  }, []);
  const next = () => {
    var out = document.getElementById("output");
    if (content === 1) {
      setContent(2);
      out.innerHTML = template2;
    } else if (content === 2) {
      setContent(3);
      out.innerHTML = template3;
    } else if (content === 3) {
      setContent(4);
      out.innerHTML = template4;
    }
  };
  const back = () => {
    var out = document.getElementById("output");
    if (content === 2) {
      setContent(1);
      out.innerHTML = template1;
    } else if (content === 3) {
      setContent(2);
      out.innerHTML = template2;
    } else if (content === 4) {
      setContent(3);
      out.innerHTML = template3;
    }
  };
  return (
    <div className="row p-0 m-0">
      <div className={bodyContentStyle()}>
        <div className="row m-0">
          <div className="col text-center">
            <h1 className="userContentHead">Artificial Intelligence</h1>
          </div>
        </div>
        <div className="row m-0 p-0">
          <div className="col text-left pl-0 ml-0">
            {content !== 1 && (
              <button className="buttonPrevious" onClick={back}>
                <i className="fa fa-angle-double-left " aria-hidden="true"></i>
              </button>
            )}
          </div>
          <div className="col text-right pr-0 mr-0">
            {content !== 4 && (
              <button className="buttonNext" onClick={next}>
                {" "}
                <i className="fa fa-angle-double-right " aria-hidden="true"></i>
              </button>
            )}
          </div>
        </div>

        <div className="sun-editor">
          <div className="se-container">
            <div className="se-wrapper">
              <div
                className="se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable"
                contentEditable="true"
                scrolling="auto"
              >
                <div
                  className="noselect"
                  id="output"
                  contentEditable="false"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-4">
          <div className="col text-left pl-0 ml-0">
            <button className="buttonPrevious">
              <i className="fa fa-angle-double-left " aria-hidden="true"></i>
            </button>
          </div>
          <div className="col text-right pr-0 mr-0">
            <button className="buttonNext">
              {" "}
              <i className="fa fa-angle-double-right " aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(UserContent);
