import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./quiz.css";
import { withRouter } from "react-router-dom";
import { category } from "../../../common/json";

function CreateQuiz(props) {
  const type = [
    { id: "1", value: "Auto marking" },
    { id: "2", value: "Manual marking" },
  ];

  const dark = useSelector((state) => state.isDark);
  const [state, setState] = useState({
    type: "",
    category: "",
    title: "",
    subTitle: "",
    visible: false,
    scoreType: "",
    score: "100",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function bodyContentStyle() {
    let classes = "";
    classes += !dark ? "createContentStyle" : "createContentStyleDark";
    return classes;
  }
  async function onChange(e) {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
  }
  function create() {
    props.history.push({
      pathname:
        "addQuestion" ,
      state: {quiz:state},
    });
  }

  async function onchangeCheck(e) {
    const {
      target: { name, checked },
    } = e;
    await setState({ ...state, [name]: checked });
  }

  return (
    <div className="row p-0 m-0 ">
      <div className={bodyContentStyle()}>
        <h1 className="pb-4 col-12 title">Create Quiz</h1>
        <div className="row p-0 m-0">
          <div className="form-group col-md-4">
            <label
              htmlFor="category"
              className={dark ? "createContentLabelDark" : "createContentLabel"}
            >
              Category
            </label>
            <select
              className={
                dark
                  ? "form-control dropDownSelectDark"
                  : "form-control dropDownSelect"
              }
              id="category"
              name="category"
              onChange={onChange}
              value={state.category}
            >
              <option value="" disabled="">
                Select value
              </option>
              {category.map((option, index) => (
                <option>{option.value}</option>
              ))}
            </select>
          </div>
          <div className="form-group col-md-4">
            <label
              htmlFor="type"
              className={dark ? "createContentLabelDark" : "createContentLabel"}
            >
              Type
            </label>
            <select
              className={
                dark
                  ? "form-control dropDownSelectDark"
                  : "form-control dropDownSelect"
              }
              id="type"
              name="type"
              onChange={onChange}
              value={state.type}
            >
              <option value="" disabled="">
                Select value
              </option>
              {type.map((option, index) => (
                <option>{option.value}</option>
              ))}
            </select>
          </div>
          <div className="col-4  ">
            <label
              htmlFor="visible"
              className={
                dark ? "createContentLabelDark pb-2" : "createContentLabel pb-2"
              }
            >
              Visible
            </label>
            <br></br>
            <label className="switch ">
              <input
                type="checkbox"
                id="visible "
                name="visible"
                checked={state.visible ? "checked" : ""}
                onChange={onchangeCheck}
              />
              <div className="slider round">
                <span className="on1">
                  <b>Public</b>
                </span>
                <span className="off1">
                  <b>Private</b>
                </span>
              </div>
            </label>
          </div>

          <div className="form-group col-4">
            <label
              htmlFor="title"
              className={dark ? "createContentLabelDark" : "createContentLabel"}
            >
              Title
            </label>
            <input
              type="text"
              className={
                dark ? "form-control textInputDark" : "form-control textInput"
              }
              id="title"
              name="title"
              placeholder="Question Title"
              onChange={onChange}
              value={state.title}
            />
          </div>
          <div className="form-group col-md-4">
            <label
              htmlFor="type"
              className={dark ? "createContentLabelDark" : "createContentLabel"}
            >
              Score Type
            </label>
            <select
              className={
                dark
                  ? "form-control dropDownSelectDark"
                  : "form-control dropDownSelect"
              }
              id="scoreType"
              name="scoreType"
              onChange={onChange}
              value={state.scoreType}
            >
              <option value="" disabled="">
                Select value
              </option>

              <option value="unique">
                Unique (Each question have different score)
              </option>
              <option value="parallel">
                Parallel (Each question have same score)
              </option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label
              htmlFor="type"
              className={dark ? "createContentLabelDark" : "createContentLabel"}
            >
              Score
            </label>
            <select
              className={
                dark
                  ? "form-control dropDownSelectDark"
                  : "form-control dropDownSelect"
              }
              id="score"
              name="score"
              onChange={onChange}
              value={state.score}
            >
              <option value="100">100%</option>
              <option value="200">200%</option>
              <option value="400">400%</option>
              <option value="500">500%</option>
              <option value="800">800%</option>
              <option value="1000">1000%</option>
            </select>
          </div>
          <div className="form-group col-12">
            <button
              className="buttonPri pull-right mt-5"
              onClick={() => create()}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CreateQuiz);
