import { combineReducers } from "redux";
import AlertReducer from "./alert";
import DarkReducer from "./isDark";
import AuthReducer from "./isAuth";
import RoleReducer from "./role";
import UserReducer from "./user";
import navCollectionReducer from "./navCollection";
import SearchReducer from "./search";
import activeNavReducer from "./activeNav";
import LoaderReducer from "./isLoader";

const allReducers = combineReducers({
  isDark: DarkReducer,
  isLoader: LoaderReducer,
  alert: AlertReducer,
  isAuth: AuthReducer,
  role: RoleReducer,
  user: UserReducer,
  navCollection:navCollectionReducer,
  search:SearchReducer,
  activeNav:activeNavReducer,
});

export default allReducers;
