import React from "react";
import { useSelector } from "react-redux";
import "../../UIService/style.css";
import "./contact.css";

function Contact() {
  const dark = useSelector((state) => state.isDark);
  function bodyContentStyle() {
    let classes = "";
    classes += !dark ? "p-4  contactStyle" : "p-4  contactStyleDark";
    return classes;
  }

  return (
    <div className="row  ">
      <div className={bodyContentStyle()}>
        <h1 className=" contactTitle">Get in Touch</h1>
        <br></br>
        <div className="row ">
          <div className="col-md-8 ">
            <input
              className="ApplicationInput contactInput"
              placeholder="Name"
              type="text"
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <input
              className="ApplicationInput contactInput"
              placeholder="Email"
              type="email"
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <input
              className="ApplicationInput contactInput"
              placeholder="Subject"
              type="text"
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <textarea
              className="ApplicationInput contactInput"
              placeholder="Message"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col ">
            <button className="buttonPri pull-left" placeholder="Message">
              Send Message
            </button>
          </div>
        </div>
      </div>
      <div className=" p-0 mapContact">
        <iframe
          className="p-0 pull-right mapContactIn"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31463.717824489362!2d79.996256831713!3d9.684051510107713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afe543b00585a09%3A0x23d8090989865331!2sKalviyankadu!5e0!3m2!1sen!2slk!4v1629136936487!5m2!1sen!2slk"
          title="laxy"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}

export default Contact;
