import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./quiz.css";
import { withRouter } from "react-router-dom";
import CreateQuestionPart from "./CreateQuestionPart";
import { appendAlert, changeLoader } from "../../../actions";

function CreateQuestion(props) {
  const dispatch = useDispatch();
  const dark = useSelector((state) => state.isDark);
  const [question, setQuestion] = useState(
    props.location.state.question !== undefined
      ? props.location.state.question
      : [
          {
            type: "",
            questionNo: 1,
            question: "",
            questionImage: "",
            questionImageAlignment: "left",
            answers: [],
            align: "singleColum",
            score: "",
          },
        ]
  );
  const [scoreSum, setScoreSum] = useState(0);
  const [score, setScore] = useState([
    {
      questionNo: 1,
      score:
        props.location.state.quiz !== undefined &&
        (props.location.state.quiz.score !== undefined
          ? props.location.state.quiz.score
          : ""),
    },
  ]);

  const addQuestion = async () => {
    await setQuestion([
      ...question,
      {
        type: "",
        questionNo: question[question.length - 1].questionNo + 1,
        question: "",
        questionImage: "",
        questionImageAlignment: "left",
        answers: [],
        align: "singleColum",
        score: "",
      },
    ]);
  };
  useEffect(() => {
    if (props.location.state.quiz) {
      if (props.location.state.quiz.scoreType === "parallel") {
        var x = [];
        var q = question;

        Array.prototype.forEach.call(q, (element) => {
          var y = {
            id: element.questionNo,
            score: props.location.state.quiz.score / question.length,
          };
          x.push(y);
        });
        setScore(x);
      }else{
        var sum=0;
        question.forEach((element) => {
            sum += parseInt(element.score);
        });
          setScoreSum(sum);
      }
    }
  }, [question, props.location.state.quiz]);
  const removeQuestion = async (id, index) => {
    if (question.length > 1) {
      const x = document.getElementById("q" + index).offsetTop;
      setQuestion(question.filter((res) => res.questionNo !== id));
      dispatch(changeLoader());
      setTimeout(function () {
        window.scrollTo(0, x);
      }, 1000);
      setTimeout(function () {
        dispatch(changeLoader());
      }, 2000);
    }
  };
  const done = () => {
    alert();
  };
  function bodyContentStyle() {
    let classes = "";
    classes += !dark ? "createContentStyle" : "createContentStyleDark";
    return classes;
  }

  const updateItem = (id, whichValue, newValue) => {
    var index = question.findIndex((x) => x.questionNo === id);
    let g = question[index];

    if (whichValue === "score") {
      if (index !== -1) {
        var sum = 0;
        question.forEach((element) => {
          if (id !== element.questionNo) {
            sum += parseInt(element.score);
          }
        });

        if (
          parseInt(sum) + parseInt(newValue) <=
            parseInt(props.location.state.quiz.score) ||
          newValue === ""
        ) {
          setScoreSum(sum + parseInt(newValue));
          g[whichValue] = newValue;
          setQuestion([
            ...question.slice(0, index),
            g,
            ...question.slice(index + 1),
          ]);
        } else {
          setQuestion([
            ...question.slice(0, index),
            g,
            ...question.slice(index + 1),
          ]);
          setScoreSum(sum);
          dispatch(
            appendAlert(
              "Available maximum Score :" +
                (isNaN(
                  parseInt(props.location.state.quiz.score) - parseInt(sum)
                )
                  ? 0
                  : parseInt(props.location.state.quiz.score) - parseInt(sum)),
              "danger"
            )
          );
        }
      }
    } else {
      g[whichValue] = newValue;
      if (index !== -1) {
        setQuestion([
          ...question.slice(0, index),
          g,
          ...question.slice(index + 1),
        ]);
      }
    }
  };
  return (
    <div className="row p-0 m-0 ">
      <div className={bodyContentStyle()}>
        <h1 className="pb-4 col-12 title">Create Question</h1>
        <div
          className="absolute text-right"
          style={{ right: "50px", top: "30px" }}
        >
          <span class="badge badge-transparent p-2 pr-4 pl-4 text-light ">
            Taken Score: &nbsp;{scoreSum} /{" "}
            {props.location.state.quiz && props.location.state.quiz.score}
          </span>
        </div>
        {question.map((value, index) => (
          <CreateQuestionPart
            length={question.length}
            data={value}
            updateItem={updateItem}
            removeQuestion={removeQuestion}
            index={index}
            key={"q" + index}
            score={score.filter((res) => res.id === value.questionNo)}
            quiz={props.location.state.quiz}
          />
        ))}

        <div className="row">
          <div className="form-group col-12 text-center">
            <button className="pri  mt-3" onClick={() => addQuestion()}>
              <i className="fa fa-plus pr-3" aria-hidden="true"></i>
              Question
            </button>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12">
            <button
              className="buttonPri pull-right mt-4"
              onClick={() => done()}
            >
              Finished
            </button>
            <button
              className="buttonPri pull-right mt-4 mr-3"
              onClick={() =>
                props.history.push({
                  pathname: "/questionReview",
                  state: {
                    question: question,
                    quiz: props.location.state.quiz,
                  },
                })
              }
            >
              <i className="fa fa-television" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CreateQuestion);
