import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { errorMessages } from "../../common/json";
import "./style.css";

function InformationPage(props) {
     const [data,setData]=useState({});
     useEffect(() => {
        if(props.status){
          const messages=errorMessages.filter(message => message.status===props.status);
          setData(messages[0]);
        }else{
          const messages=errorMessages.filter(message => message.status==="404");
          setData(messages[0]);
        }
     },[props.status]);
  return (
    <div className={"row backImageContent "+data.image}>
      <div className="col text-left notFoundAlign">
        <h1 className="headAlign">{data.status}</h1>
        <h1 className="headAlign">{data.head}</h1>
        <p className="contentAlign  pr-5">
        {data.content}
        </p>
        <button
          className="buttonPri"
          id="signUp"
          onClick={() => {
            props.history.push(data.buttonRoute);
          }}
        >
          {data.buttonName}
        </button>
      </div>
      <div className="col-2"></div>
    </div>
  );
}
export default withRouter(InformationPage);
