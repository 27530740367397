import React, {  useState } from "react";
import "./footer.css";

const Footer = ({ footer }) => {
  const [state, setState] = useState({
    email: ""});
   function onChange(e) {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
  }
  return (
    <div>
      <div className="footerContent pt-5 container-fluid">
        <div className="row">
          <div className="col text-center">
            <h1 className="text-white">THROUGHT</h1>
          </div>
        </div>
        <div className="row p-4 d-flex justify-content-center">
          <div className="col-md-4 text-center footer-content pt-5">
            <h2 className="text-white">About Us</h2>
            <div className="row">
              <div className="col text-left p-4 pt-4">
                <span className="span3 text-white text-left">
                  This is a learning site ,You can share your knowledge and get
                  from us also. We mainly give teachers management platform. you
                  can control you class and rooms in online. Please join us and
                  share your knowledge
                </span>
              </div>
            </div>
            <div className="row ">
              <div className="col  text-right pr-4 pl-4">
                <input className="tag " type="email" placeholder="Email" value={state.email}  onChange={onChange}/>
                <button className="buttonLightTran mt-3 ">
                  send &nbsp;
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4 text-center pt-5 footer-content ">
            <h2 className="text-white">Services</h2>
            <div className="row">
              <div className="col text-left p-4 pt-4 text-white">
                <ul>
                  <li>Create yor own content site</li>
                  <li>Published as public or private</li>
                  <li>Get knowledge </li>
                  <li>Share knowledge </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-4 text-center footer-content pt-5">
            <h2 className="text-white">Contact Us</h2>
            <div className="row pt-4">
              <div className="col-3">
                <div className="social-container pt-0 mt-0 ">
                  <a
                    href={"https://goo.gl/maps/VNNyh27eH4wXU7H47"}
                    className="social"
                    style={{ textDecoration: "none" }}
                  >
                    <i
                      className="fa fa-address-book-o text-white"
                      aria-hidden="true"
                    ></i>{" "}
                  </a>
                </div>
              </div>
              <div className="col-9 text-left pt-1">
                <span className=" span3 text-white">
                  New Chemmany Road, Nallur North, Jaffna
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="social-container pt-0 mt-0">
                  <a
                    href={"https://goo.gl/maps/VNNyh27eH4wXU7H47"}
                    className="social"
                    style={{ textDecoration: "none" }}
                  >
                    <i
                      className="fa fa-phone text-white"
                      aria-hidden="true"
                    ></i>{" "}
                  </a>
                </div>
              </div>
              <div className="col-9 text-left pt-1">
                <span className="text-white">+94 (076) 7000 249</span>
              </div>
            </div>
            <div className="row  ">
              <div className="col-3">
                <div className="social-container pt-0 mt-0">
                  <a
                    href={"https://mail.google.com/"}
                    className="social"
                    style={{ textDecoration: "none" }}
                  >
                    <i
                      className="fa fa-envelope text-white"
                      aria-hidden="true"
                    ></i>{" "}
                  </a>
                </div>
              </div>
              <div className="col-9 text-left pt-1">
                <span className="text-white">throughoutwithyou@gmail.com</span>
              </div>
            </div>
          </div>
          {/* <div className="col-md-3">
                    
              </div> */}
        </div>
        <div className="row text-center mt-3 ">
          <div className="col">
            <div className="social-container pt-0 mt-0">
              <a
                href={"https://goo.gl/maps/VNNyh27eH4wXU7H47"}
                className="social"
                style={{ textDecoration: "none" }}
              >
                <i className="fa fa-google text-white" aria-hidden="true"></i>{" "}
              </a>
              <a
                href={"https://goo.gl/maps/VNNyh27eH4wXU7H47"}
                className="social"
                style={{ textDecoration: "none" }}
              >
                <i className="fa fa-facebook text-white" aria-hidden="true"></i>{" "}
              </a>
              <a
                href={"https://goo.gl/maps/VNNyh27eH4wXU7H47"}
                className="social"
                style={{ textDecoration: "none" }}
              >
                <i className="fa fa-linkedin text-white" aria-hidden="true"></i>{" "}
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col text-center pt-2 pb-2">
            <span className="text-white">
              Made with <i className="fa fa-heart" aria-hidden="true"></i> by
              Copyright <i className="fa fa-copyright" aria-hidden="true"></i>{" "}
              Karunaaharan Bavaram in 2021. All Rights Reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
