import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./createContent.css";
import { withRouter } from "react-router-dom";
import { category } from "../../../common/json";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import katex from "katex";
import "katex/dist/katex.min.css";
import { template1, template2, template3, template4 } from "./templates";

function CreateContent(props) {
  /**
   * @type {React.MutableRefObject<SunEditor>} get type definitions for editor
   */
  const editor = useRef();
  const dark = useSelector((state) => state.isDark);
  const [editHtml, changeEditHtml] = useState("");
  const [state, setState] = useState({
    category: "",
    title: "",
    subTitle: "",
    visible: false,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function bodyContentStyle() {
    let classes = "";
    classes += !dark ? "createContentStyle" : "createContentStyleDark ";
    return classes;
  }
  async function onChange(e) {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
  }

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };
  const save = () => {
    // console.log(editor);
    // console.log(editor.current);
  };
  function handleChange(content) {
    changeEditHtml(content);
    var out = document.getElementById("out");
    out.innerHTML = content;
    console.log(editHtml);
  }
  async function onchangeCheck(e) {
    const {
      target: { name, checked },
    } = e;
    await setState({ ...state, [name]: checked });
  }

  return (
    <div className="row p-0 m-0">
      <div className={bodyContentStyle()}>
        <h1 className="pb-4 col-12 title">Create Content</h1>
        <div className="row p-0 m-0">
          <div className="form-group col-md-4">
            <label
              htmlFor="category"
              className={dark ? "createContentLabelDark" : "createContentLabel"}
            >
              Category
            </label>
            <select
              className={
                dark
                  ? "form-control dropDownSelectDark"
                  : "form-control dropDownSelect"
              }
              id="category"
              name="category"
              onChange={onChange}
              value={state.category}
            >
              <option value="" disabled="">
                Select value
              </option>
              {category.map((option, index) => (
                <option>{option.value}</option>
              ))}
            </select>
          </div>
          <div className="col-4  ">
            <label
              htmlFor="visible"
              className={
                dark ? "createContentLabelDark pb-2" : "createContentLabel pb-2"
              }
            >
              Visible
            </label>
            <br></br>
            <label className="switch ">
              <input
                type="checkbox"
                id="visible "
                name="visible"
                checked={state.visible ? "checked" : ""}
                onChange={onchangeCheck}
              />
              <div className="slider round">
                <span className="on1">
                  <b>Public</b>
                </span>
                <span className="off1">
                  <b>Private</b>
                </span>
              </div>
            </label>
          </div>

          <div className="form-group col-12">
            <label
              htmlFor="title"
              className={dark ? "createContentLabelDark" : "createContentLabel"}
            >
              Title
            </label>
            <input
              type="text"
              className={
                dark ? "form-control textInputDark" : "form-control textInput"
              }
              id="title"
              name="title"
              placeholder="Content Title"
              onChange={onChange}
              value={state.title}
            />
          </div>
          <div className="form-group col-12">
            <label
              htmlFor="subTitle"
              className={dark ? "createContentLabelDark" : "createContentLabel"}
            >
              Content
            </label>
            <input
              type="text"
              className={
                dark ? "form-control textInputDark" : "form-control textInput"
              }
              id="subTitle"
              name="subTitle"
              placeholder="Content Sub Title"
              onChange={onChange}
              value={state.subTitle}
            />
          </div>
          <div className="form-group col-12">
            <label
              htmlFor="content"
              className={dark ? "createContentLabelDark" : "createContentLabel"}
            >
              Sub Title
            </label>
            <SunEditor
              onChange={handleChange}
              getSunEditorInstance={getSunEditorInstance}
              height="100%"
              placeholder="Please type here..."
              // autoFocus={true}
              setDefaultStyle="font-family: Arila; font-size: 17px;"
              name="my-editor"
              save={() => save()}
              //  disable={true}
              //  hideToolbar={true}
              //    disableToolbar={true}
              // setContents="<h1>My contents</h1><p>hi</p><table><tbody><tr><td><div><br></div></td><td><div><br></div></td></tr><tr><td><div><br></div></td><td><div><br></div></td></tr></tbody></table><p>​</p>"
              setContents={""}
              setOptions={{
                height: 200,
                buttonList: [
                  [
                    "fontColor",
                    "hiliteColor",
                    "textStyle",
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "align",
                    "list",
                    "lineHeight",
                  ],
                  [
                    "subscript",
                    "superscript",
                    "font",
                    "fontSize",
                    "formatBlock",
                    "paragraphStyle",
                    "blockquote",
                    "horizontalRule",
                    "outdent",
                    "indent",
                  ],
                  [
                    "table",
                    "link",
                    "image",
                    "imageGallery",
                    "video",
                    "audio",
                    "math",
                  ],
                  ["removeFormat"],
                  ["undo", "redo"],
                  [
                    "fullScreen",
                    "showBlocks",
                    "codeView",
                    "preview",
                    "print",
                    "save",
                    "template",
                  ],
                ],
                resizingBar: true,
                charCounter: true,
                callBackSave: () => save(),
                paragraphStyles: [
                  {
                    name: "Spaced",
                    class: "__se__p-spaced",
                    _class: "",
                  },
                  {
                    name: "Bordered",
                    class: "__se__p-bordered",
                  },
                  {
                    name: "Neon",
                    class: "__se__p-neon",
                  },
                  {
                    name: "My",
                    style: "opacity: 0.5;",
                  },
                ],
                textStyles: [
                  {
                    name: "Code",
                    class: "__se__t-code",
                    tag: "code",
                  },
                  {
                    name: "Translucent",
                    style: "opacity: 0.5;",
                    tag: "span",
                    _class: "",
                  },
                  {
                    name: "Shadow",
                    class: "textShadow1",
                    tag: "span",
                  },
                  {
                    name: "Shadow2",
                    class: "textShadow2",
                    tag: "span",
                  },
                  {
                    name: "Shadow3",
                    class: "textShadow3",
                    tag: "span",
                  },
                  {
                    name: "Emphasis",
                    style: "-webkit-text-emphasis: filled;",
                    tag: "span",
                  },
                  {
                    name: "Cursive",
                    class: "cursive",
                    tag: "p",
                  },
                ],
                charCounterType: "char",
                imageUrlInput: true,
                imageWidth: "25%",
                // imageGalleryUrl:
                //   "https://jsonblob.com/api/jsonBlob/881461639997726720",
                imageGalleryUrl:
                  "https://etyswjpn79.execute-api.ap-northeast-1.amazonaws.com/suneditor-demo",
                templates: [
                  {
                    name: "Template-1",
                    html: template1,
                  },
                  {
                    name: "Template-2",
                    html: template2,
                  },
                  {
                    name: "Template-3",
                    html: template3,
                  },
                  {
                    name: "Template-4",
                    html: template4,
                  },
                ],
                icons: {
                  // bold: '<span className="se-icon-text">B</span>',
                  // table: '<i className="xx xxx></i>',
                  // insert_row_above: '<svg></svg>'
                },
                // imageGalleryUrl:"https://drive.google.com/drive/folders/14Rtqe-nNz9rin3mqEeyG9T5oADf67FL5?usp=sharing",
                katex: katex,
                mathFontSize: [
                  { text: "1", value: "1em", default: true },
                  { text: "1.5", value: "1.5em" },
                  { text: "2", value: "2em" },
                  { text: "2.5", value: "2.5em" },
                ],
                colorList: [
                  [
                    "#ff0000",
                    "#ff5e00",
                    "#ffe400",
                    "#abf200",
                    "#00d8ff",
                    "#0055ff",
                    "#6600ff",
                    "#ff00dd",
                    "#000000",
                    "#ffd8d8",
                    "#fae0d4",
                    "#faf4c0",
                    "#e4f7ba",
                    "#d4f4fa",
                    "#d9e5ff",
                    "#e8d9ff",
                    "#ffd9fa",
                    "#f1f1f1",
                    "#ffa7a7",
                    "#ffc19e",
                    "#faed7d",
                    "#cef279",
                    "#b2ebf4",
                    "#b2ccff",
                    "#d1b2ff",
                    "#ffb2f5",
                    "#bdbdbd",
                    "#f15f5f",
                    "#f29661",
                    "#e5d85c",
                    "#bce55c",
                    "#5cd1e5",
                    "#6699ff",
                    "#a366ff",
                    "#f261df",
                    "#8c8c8c",
                    "#980000",
                    "#993800",
                    "#998a00",
                    "#6b9900",
                    "#008299",
                    "#003399",
                    "#3d0099",
                    "#990085",
                    "#353535",
                    "#670000",
                    "#662500",
                    "#665c00",
                    "#476600",
                    "#005766",
                    "#002266",
                    "#290066",
                    "#660058",
                    "#222222",
                  ],
                ],
                font: [
                  "monospace",
                  "Arila",
                  "Comic Sans MS",
                  "Courier New",
                  "Impact",
                  "Georgia",
                  "tahoma",
                  "Trebuchet MS",
                  "Verdana",
                  "Bamini",
                  "SU Sewandi",
                  "DL-Sarala",
                  "Kusum",
                  "Sans Serif",
                ],
                lineHeights: [
                  { text: "Single", value: 1 },
                  { text: "Single", value: 1 },
                  { text: "Double", value: 2 },
                  { text: "0.5", value: 0.5 },
                  { text: "1.5", value: 1.5 },
                ],
                mode: ["classic"],
              }}
            />

            <button
              className="buttonPri pull-right mt-5"
              onClick={() => save()}
            >
              Create
            </button>
          </div>
          <div className="sun-editor">
            <div className="se-container">
              <div className="se-wrapper">
                <div
                  className="se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable"
                  contentEditable="true"
                  scrolling="auto"
                >
                  <div id="out"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CreateContent);
