import React from "react";
import "../quiz.css";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import defaultImage from "../../../../images/defaultImage.jpg";

function ReviewCheckBox(props) {
  const dark = useSelector((state) => state.isDark);
  async function onChangeCheck(e) {
    const {
      target: { name, checked },
    } = e;
    updateCheckAnswerItem(name, "correct", checked);
  }
  const updateCheckAnswerItem = (id, whichValue, newValue) => {
    const index = props.value.answers.findIndex(
      (x) => x.answerNo === parseInt(id)
    );
    let g3 = props.value.answers[index];
    g3[whichValue] = newValue;
    if (index !== -1) {
      const a = [
        ...props.value.answers.slice(0, index),
        g3,
        ...props.value.answers.slice(index + 1),
      ];
      props.updateItem(props.value.questionNo, "answers", a);
    }
  };
  return (
    
      <div className="row pr-5 pl-3">
        {props.value.answers.map(
          (val,index) =>
            val.text !== "" && (
              <div
                className={
                  props.value.align === "singleColum"
                    ? "col-md-12"
                    : props.value.align === "doubleColum"
                    ? "col-md-6"
                    : props.value.align === "threeColum"
                    ? "col-md-4"
                    : props.value.align === "fourColum"
                    ? "col-md-3"
                    : "col-md answerAlignment"
                }
              >
                <label className={props.handle==="on"?props.realAnswer[index].correct?"containerCheckPre":"containerCheckPre2":"containerCheckPre"}>
                  <span
                    className={
                      dark ? "checkMarkLabelPreDark" : "checkMarkPreLabel"
                    }
                  >
                    {val.type === "text" && val.text}
                    {val.type === "image" && (
                      <img
                        alt="answer_image_preview"
                        className="answer_image pb-3"
                        height="100px"
                        src={val.text === "" ? defaultImage : val.text}
                      />
                    )}
                  </span>
                  <input
                    type="checkbox"
                    name={val.answerNo}
                    checked={(val.correct|| (props.handle==="on"&&props.realAnswer[index].correct))&&"checked"}
                    onChange={onChangeCheck}
                  />
                  <span className="checkMarkPre"></span>
                </label>
              </div>
            )
        )}
      </div>
  );
}

export default withRouter(ReviewCheckBox);
