const RoleReducer = (state = "Guest", action) => {
  switch (action.type) {
    case "CHANGE_ROLE":
      if (action.role === "Learner") return "Learner";
      else if (action.role === "Instructor") return "Instructor";
      else if (action.role === "Administrator") return "Administrator";
      else if (action.role === "Guest") return state;
      else return state;
    case "CHANGE_ROLE_GUEST":
      return "Guest";
    case "CHANGE_ROLE_LEARNER":
      return "Learner";
    case "CHANGE_ROLE_INSTRUCTOR":
      return "Instructor";
    case "CHANGE_ROLE_ADMINISTRATOR":
      return "Administrator";
    case "GET_ROLE":
      return state;
    default:
      return state;
  }
};
export default RoleReducer;
