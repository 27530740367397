import React from "react";
import { useSelector } from "react-redux";
import "./content.css";
import { withRouter } from "react-router-dom";

function Content(props) {
  const dark = useSelector((state) => state.isDark);

  return (
    <div className="">
      <div className={dark ? "row content0Dark" : "row content0"}>
        <div className="col "></div>
        <div className=" text-left content0Align alignContent0">
          <span className="headAlign">LMS</span>
          <h1 className="headAlign">Build Your Own Platform</h1>
          <p className="contentAlign  pr-5">
            Build a smarter organization with the training platform designed to
            help great teams grow
          </p>
          <button
            className="buttonPri"
            id="signUp"
            onClick={() => {
              props.history.push("login");
            }}
          >
            Get Started
          </button>
        </div>
      </div>

      <div className="content1">
        <div className="row row1-container">
          <div className="boxCard boxCard-down cyan"  onClick={() => props.history.push("content")}>
            <h2 className={!dark ? "cardSquareHead1" : "cardSquareHead1Dark"}>
              Contents
            </h2>
            <p>Monitors activity to identify project roadblocks</p>
            <img
              src="https://assets.codepen.io/2301174/icon-supervisor.svg"
              alt=""
              className="cardImage"
            />
          </div>

          <div
            className="boxCard red"
            onClick={() => props.history.push("dashboard")}
          >
            <h2 className={!dark ? "cardSquareHead1" : "cardSquareHead1Dark"}>
              Create Class
            </h2>
            <p>
              Share learner modules and instruction ,Create Private Class room
            </p>
            <img
              src="https://assets.codepen.io/2301174/icon-team-builder.svg"
              alt=""
              className="cardImage"
            />
          </div>

          <div
            className="boxCard boxCard-down blue"
            onClick={() => props.history.push("createContent")}
          >
            <h2 className={!dark ? "cardSquareHead1" : "cardSquareHead1Dark"}>
              Create Content
            </h2>
            <p>Share your own content or modules to public or private</p>
            <img
              src="https://assets.codepen.io/2301174/icon-calculator.svg"
              alt=""
              className="cardImage"
            />
          </div>
        </div>
        <div className="row row2-container">
          <div
            className="boxCard orange"
            onClick={() => props.history.push("addQuiz")}
          >
            <h2 className={!dark ? "cardSquareHead1" : "cardSquareHead1Dark"}>
              Create Quiz
            </h2>
            <p>
              Create Questionnaire and evaluate your learners to open or private{" "}
            </p>
            <img
              src="https://assets.codepen.io/2301174/icon-karma.svg"
              alt=""
              className="cardImage"
            />
          </div>
        </div>
      </div>
      <div className={dark ? "row contentAbout0Dark" : "row contentAbout0"}>
        <div className="col-lg-5 contentAbout">
          <div className="row p-0 m-0">
            <div className="col  contentAboutProfile"></div>
          </div>
          <div className="row contentAbout2 p-0 m-0">
            <div className="col-5 pb-3">
              <h1 className="text-white ">
                <span className="BFont">B</span>avaram
              </h1>
              <span className="text-white ">
                I believe technology is improved anything.
              </span>
            </div>
          </div>
          <div className=""></div>
        </div>
        <div className="col-lg contentAbout1 ">
          <div className=" contentAbout3 ">
            <h1 className="aboutTitle mb-5 mt-5">About Us</h1>
            <div className="aboutContentContainer">
              <p className="aboutContent ">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We are information technology
                professionals. We always believe technology is improved
                anything. We developed this platform for learning and teaching
                in online .We are graduate student of University of Jaffna, now
                working to create this platform for improve Sri Lankan Online
                Education. we have owns this page and idea but this is now in
                under developing stage. Somehow I gave my design and system by
                technicaly . You are join with us and create your own platform
                for you own language.
              </p>
            </div>
          </div>
        </div>

        <div className="col-12">
          <h1 className="text-center pt-5 aboutTitle mb-5">Services</h1>
        </div>
        <div className="col-12 m-0 p-0">
          <div className="containerService ">
            <div className="cardService">
              <h3 className="titleService">Create Room</h3>
              <div className="barService">
                <div className="emptybarService"></div>
                <div className="filledbarService"></div>
              </div>
              <div className="circleService text-white">
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Private
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Public
                </span>
              </div>
            </div>
            <div className="cardService">
              <h3 className="titleService">Create Content</h3>
              <div className="barService">
                <div className="emptybarService"></div>
                <div className="filledbarService"></div>
              </div>
              <div className="circleService text-white">
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Private
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Public
                </span>
              </div>
            </div>
            <div className="cardService">
              <h3 className="titleService">Create Course</h3>
              <div className="barService">
                <div className="emptybarService"></div>
                <div className="filledbarService"></div>
              </div>
              <div className="circleService text-white">
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Free
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Payment
                </span>
              </div>
            </div>

            <div className="cardService">
              <h3 className="titleService">Sharing &nbsp;Files</h3>
              <div className="barService">
                <div className="emptybarService"></div>
                <div className="filledbarService"></div>
              </div>
              <div className="circleService text-white">
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> In Room
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Public
                </span>
              </div>
            </div>
            <div className="cardService">
              <h3 className="titleService">Control Users</h3>
              <div className="barService">
                <div className="emptybarService"></div>
                <div className="filledbarService"></div>
              </div>
              <div className="circleService text-white">
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Payments
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i>{" "}
                  Attendance
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i>{" "}
                  Submissions
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Quiz
                </span>
              </div>
            </div>
            <div className="cardService">
              <h3 className="titleService">Create Quiz </h3>
              <div className="barService">
                <div className="emptybarService"></div>
                <div className="filledbarService"></div>
              </div>
              <div className="circleService text-white">
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Private
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i>{" "}
                  Auto-Score
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i>{" "}
                  Real-Time
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Public
                </span>
              </div>
            </div>
          </div>
          <div className="row containerService1">
            <div className="col cardService1">
              <h3 className="titleService">Create Room</h3>
              <div className="barService">
                <div className="emptybarService"></div>
                <div className="filledbarService"></div>
              </div>
              <div className="circleService text-white">
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Private
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Public
                </span>
              </div>
            </div>
            <div className="col cardService1">
              <h3 className="titleService">Create Content</h3>
              <div className="barService">
                <div className="emptybarService"></div>
                <div className="filledbarService"></div>
              </div>
              <div className="circleService text-white">
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Private
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Public
                </span>
              </div>
            </div>
            <div className="col cardService1">
              <h3 className="titleService">Create Course</h3>
              <div className="barService">
                <div className="emptybarService"></div>
                <div className="filledbarService"></div>
              </div>
              <div className="circleService text-white">
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Free
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Payment
                </span>
              </div>
            </div>

            <div className="col  cardService1">
              <h3 className="titleService">Sharing &nbsp;Files</h3>
              <div className="barService">
                <div className="emptybarService"></div>
                <div className="filledbarService"></div>
              </div>
              <div className="circleService text-white">
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> In Room
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Public
                </span>
              </div>
            </div>
            <div className="col cardService1">
              <h3 className="titleService">Control Users</h3>
              <div className="barService">
                <div className="emptybarService"></div>
                <div className="filledbarService"></div>
              </div>
              <div className="circleService text-white">
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Payments
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i>{" "}
                  Attendance
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i>{" "}
                  Submissions
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Quiz
                </span>
              </div>
            </div>
            <div className="col p-0 cardService1">
              <h3 className="titleService">Create Quiz </h3>
              <div className="barService">
                <div className="emptybarService"></div>
                <div className="filledbarService"></div>
              </div>
              <div className="circleService text-white">
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Private
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i>{" "}
                  Auto-Score
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i>{" "}
                  Real-Time
                </span>
                <br></br>
                <span>
                  <i className="fa fa-bullseye" aria-hidden="true"></i> Public
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Content);
