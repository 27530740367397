export const sampleQuestion = [
  {
    type: "radioBox",
    questionNo: 1,
    question: "bvgfyg ehf ehfjbfsdf",
    questionImage: "",
    correct:true,
    questionImageAlignment: "left",
    answers: [
      { text: "dfsdfsdf", answerNo: 1, type: "text" ,marking:false},
      { text: "dffgdfgdfg", answerNo: 2, type: "text" ,marking:true},
      { text: "fsdfsdfsdf", answerNo: 3, type: "text" ,marking:true},
    ],
    align: "fourColum",
    score: "20",
  },
  {
    type: "checkedBox",
    questionNo: 2,
    question: "tydgd dfgdfgdfg",
    questionImage: "",
    correct:true,
    questionImageAlignment: "left",
    answers: [
      { text: "dffgdfgdfg", answerNo: 1, type: "text" ,marking:false},
      { text: "fsdfsdfsdf", answerNo: 2, type: "text" ,marking:false},
      { text: "fdfgdfg", answerNo: 3, type: "text" ,marking:false},
      { text: "cccc", answerNo: 4, type: "text" ,marking:false},
    ],
    align: "threeColum",
    score: "30",
  },
  {
    type: "freeText",
    questionNo: 3,
    question: "ty rtyrtyrtyrt rtyrtyrty rtyrty",
    questionImage: "",
    questionImageAlignment: "left",
    correct:true,
    answers: [
      { text: "erterte rtyert", answerNo: 1, correct: true, type: "text" ,marking:false},
    ],
    align: "singleColum",
    score: "",
  },
  {
    type: "fillBlanks",
    questionNo: 4,
    question:
      "yrtyrt rtyertwert ewrtewrtwerw werwer ewr werwerwe ewr wer<BavarInput>erterte ertertert ertertertert.<BavarInput><BavarInput>ewr wer wuer wuerwerwer wierhwiueriijjkdhfkjds dfsdfsdfhi dfsdfhsfeiurs iweuhriwueriwher weirhwierhwheriuwher wieruhwiehr wer weruweiurwer eerjt<BavarInput>",
    questionImage: "",
    questionImageAlignment: "left",
    correct:true,
    answers: [
      { text: "rtrtertert", answerNo: 2, type: "text" ,marking:false},
      { text: "werwerwerw werwer", answerNo: 4, type: "text" ,marking:false},
      { text: "ertewrtwetwet", answerNo: 5, type: "text",marking:false },
      { text: "erterttertertertert", answerNo: 7, type: "text",marking:false },
    ],
    align: "singleColum",
    score: "",
  },
  {
    type: "matching",
    questionNo: 5,
    question: "",
    questionImage: "",
    questionImageAlignment: "left",
    answers: [
      { text: ["asdasd", "fghfgh"], answerNo: 1, type: "text" ,marking:false},
      { text: ["asdasd", "fghfgh"], answerNo: 2, type: "text" ,marking:false},
      {
        text: ["dhfgshdfsh", "fghfgh"],
        answerNo: 3,
        type: "text",marking:false
        
      },
      {
        text: ["ghjhghj", "rrrrrrr"],
        answerNo: 4,
        type: "text",marking:false
      },
    ],
    align: "singleColum",
    score: "",
  },
];


export const sampleQuestion1 = [
  {
    type: "radioBox",
    questionNo: 1,
    question: "bvgfyg ehf ehfjbfsdf",
    questionImage: "",
    questionImageAlignment: "left",
    answers: [
      { text: "dfsdfsdf", answerNo: 1, correct: false, type: "text" },
      { text: "dffgdfgdfg", answerNo: 2, correct: true, type: "text" },
      { text: "fsdfsdfsdf", answerNo: 3, correct: false, type: "text" },
    ],
    align: "fourColum",
    score: "20",
  },
  {
    type: "checkedBox",
    questionNo: 2,
    question: "tydgd dfgdfgdfg",
    questionImage: "",
    questionImageAlignment: "left",
    answers: [
      { text: "dffgdfgdfg", answerNo: 1, correct: false, type: "text" },
      { text: "fsdfsdfsdf", answerNo: 2, correct: false, type: "text" },
      { text: "fdfgdfg", answerNo: 3, correct: true, type: "text" },
      { text: "cccc", answerNo: 4, correct: false, type: "text" },
    ],
    align: "threeColum",
    score: "30",
  },
  {
    type: "freeText",
    questionNo: 3,
    question: "ty rtyrtyrtyrt rtyrtyrty rtyrty",
    questionImage: "",
    questionImageAlignment: "left",
    answers: [
      { text: "erterte rtyert", answerNo: 1, correct: true, type: "text" },
    ],
    align: "singleColum",
    score: "",
  },
  {
    type: "fillBlanks",
    questionNo: 4,
    question:
      "yrtyrt rtyertwert ewrtewrtwerw werwer ewr werwerwe ewr wer<BavarInput>erterte ertertert ertertertert.<BavarInput><BavarInput>ewr wer wuer wuerwerwer wierhwiueriijjkdhfkjds dfsdfsdfhi dfsdfhsfeiurs iweuhriwueriwher weirhwierhwheriuwher wieruhwiehr wer weruweiurwer eerjt<BavarInput>",
    questionImage: "",
    questionImageAlignment: "left",
    answers: [
      { text: "rtrtertert", answerNo: 2, correct: true, type: "text" },
      { text: "werwerwerw werwer", answerNo: 4, correct: true, type: "text" },
      { text: "ertewrtwetwet", answerNo: 5, correct: true, type: "text" },
      { text: "erterttertertertert", answerNo: 7, correct: true, type: "text" },
    ],
    align: "singleColum",
    score: "",
    answer: [
      {
        text: "yrtyrt rtyertwert ewrtewrtwerw werwer ewr werwerwe ewr wer",
        answerNo: 1,
        type: "textArea",
      },
      null,
      {
        text: "erterte ertertert ertertertert.",
        answerNo: 3,
        type: "textArea",
      },
      null,
      null,
      {
        text: "ewr wer wuer wuerwerwer wierhwiueriijjkdhfkjds dfsdfsdfhi dfsdfhsfeiurs iweuhriwueriwher weirhwierhwheriuwher wieruhwiehr wer weruweiurwer eerjt",
        answerNo: 6,
        type: "textArea",
      },
      null,
    ],
  },
  {
    type: "matching",
    questionNo: 5,
    question: "",
    questionImage: "",
    questionImageAlignment: "left",
    answers: [
      { text: ["asdasd", "fghfgh"], answerNo: 1, correct: true, type: "text" },
      { text: ["asdasd", "fghfgh"], answerNo: 2, correct: false, type: "text" },
      {
        text: ["dhfgshdfsh", "fghfgh"],
        answerNo: 3,
        correct: false,
        type: "text",
      },
      {
        text: ["ghjhghj", "rrrrrrr"],
        answerNo: 4,
        correct: false,
        type: "text",
      },
    ],
    align: "singleColum",
    score: "",
  },
];
