const AuthReducer = (state =false, action) => {
  switch (action.type) {
    case 'AUTHORIZE':
      return true;
    case 'UNAUTHORIZE':
      return false;
    case 'TOGGLE_AUTHORIZE':
      return !state;
    default:
      return state;
  }
};
export default AuthReducer;
